export const colors = {
  'black-pavel-1': '#333745',
  'blue-pavel-1': '#336DDE',
  'blue-pavel-2': '#409EFF',
  'green-pavel-1': '#6EC5BF',
  'green-pavel-2': '#B6E4CB',
  'purple-pavel-1': '#A96DA3',
  'red-pavel-1': '#F56C6C',
  'orange-pavel-1': '#F99E4A',
  'orange-pavel-2': '#EDAE49',
  'pink-pavel-1': '#FEBB9B',
  'yellow-pavel-1': '#FED792',
  'white-pavel-1': '#FAF3DD',
  'brown-pavel-1': '#D8BFAA'
}

export const colorsByPavel = Object.entries(colors).filter(([k]) => k.includes('pavel')).map(([_, v]) => v)

export const colorsForChart = colorsByPavel.filter(x => ![colors['black-pavel-1'], colors['blue-pavel-1'], colors['green-pavel-2']].includes(x))
