<template>
    <el-tag v-if="status.value"
            :type="status.mood"
            class="_mr-0.25 fs-0.7"
            size="mini">
        {{ status.value }}
    </el-tag> 
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: null
    }
  },
  computed: {
    status() {
      const { translated_result } = this.task || {}

      let { value, mood } = translated_result || {}

      mood === 'sad' && (mood = 'info')

      return { value, mood }
    }
  }
}
</script>
