import { Style, Circle, Stroke, Fill, Icon, Text, RegularShape } from 'ol/style'

import { getWorkPolygonFeatureTypes } from '@/models/shapes'

import { FEATURE_TYPES as floorFeatures } from '@/utils/plan'
import { FEATURE_TYPES as facadeFeatures } from '@/utils/facade'

import copterIcon from '@/assets/copter.svg'
import borderedCopterIcon from '@/assets/copter_bordered.svg'
import sightIcon from '@/assets/sight.svg'

const colors = {
  accent: '#5A9DF8',
  light: 'white',
  black: 'black',
  grey: '#909399',
  red: '#E47470',
  transparent: 'transparent'
}

const values = {
  selectionWidth: 2
}

const hexToRgbA = (hex, alpha = 0.7) => {
  if (!hex) return
  const [r, g, b] = hex.match(/\w\w/g)
    .map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`
}

const self = {
  floor: {
    [floorFeatures.CAMERA360]: ({ feature, selected, rotated, rotation, origin }) => [
      new Style({
        image: new Circle({
          radius: selected ? 16 : 18,
          stroke: new Stroke({
            color: selected ? colors.black : colors.transparent,
            width: values.selectionWidth
          }),
          fill: new Fill({ 
            color: feature.get('task_defects_active_count') ? colors.red : colors.grey
          })
        }),
        fill: new Fill({color: 'rgba(0, 0, 255, 0.1)'}),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2
        }),
        text: self['label']({ feature }),
        zIndex: 4
      }),
      self['sight']({ feature, selected, rotated, rotation, origin })
    ],

    [floorFeatures.QUADCOPTER]: ({ feature, selected, rotated, rotation, origin }) => [
      new Style({
        image: new Icon({
          color: '#909399',
          crossOrigin: 'anonymous',
          imgSize: [120, 120],
          scale: selected ? 0.3 : 0.28,
          src: selected ? borderedCopterIcon : copterIcon
        }),
        fill: new Fill({color: 'rgba(0, 0, 255, 0.1)'}),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2
        }),
        text: self['label']({ feature }),
        zIndex: 4
      }),
      self['sight']({ feature, selected, rotated, rotation, origin })
    ],

    [floorFeatures.FACADE]: ({ feature, selected, rotated, rotation, origin }) => [
      new Style({
        image: new Icon({
          color: colors.red,
          crossOrigin: 'anonymous',
          imgSize: [120, 120],
          scale: 0.28,
          src: selected ? borderedCopterIcon : copterIcon
        }),
        text: self['label']({ feature }),
        zIndex: 4
      }),
      self['sight']({ feature, selected, rotated, rotation, origin })
    ],

    [floorFeatures.DEFECT]: ({ feature, selected, rotated, rotation, origin }) => [
      new Style({
        image: new RegularShape({
          fill: new Fill({ color: colors.red }),
          stroke: new Stroke({ 
            color: selected ? colors.black : colors.transparent,
            width: values.selectionWidth
          }),
          points: 4,
          radius: 18,
          angle: Math.PI / 4
        }),
        text: self['label']({ feature }),
        zIndex: 4
      }),
      self['sight']({ feature, selected, rotated, rotation, origin })
    ],

    [floorFeatures.PROTOCOL]: () => new Style({
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        width: 3
      }),
      fill: new Fill({color: 'rgba(0, 0, 0, 0.5)'}),
      text: new Text({
        font: '16px sans-serif',
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          width: 3
        }),
        fill: new Fill({color: '#fefff9'})
      })
    }),

    [floorFeatures.ROOM]: () => new Style({
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        width: 3
      }),
      fill: new Fill({color: 'rgba(0, 0, 0, 0.5)'}),
      text: new Text({
        font: '16px sans-serif',
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          width: 3
        }),
        fill: new Fill({color: '#fefff9'})
      })
    }),

    [floorFeatures.RULER_CALIBRATION]: () => new Style({
      fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),
      stroke: new Stroke({
        color: '#f56c6c',
        width: 3,
        lineDash: [10, 10]
      }),
      text: new Text({
        offsetY: 2,
        font: '16px Tahoma',
        fill: new Fill({color: '#000000'})
      })
    }),

    [floorFeatures.RULER_LENGTH]: () => new Style({
      fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),
      stroke: new Stroke({
        color: '#f56c6c',
        width: 3
      }),
      text: new Text({
        offsetY: 2,
        font: '16px Tahoma',
        fill: new Fill({color: '#000000'})
      })
    }),

    [floorFeatures.RULER_AREA]: () => new Style({
      fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),
      stroke: new Stroke({
        color: '#f56c6c',
        width: 3
      }),
      text: new Text({
        offsetY: 2,
        font: '16px Tahoma',
        fill: new Fill({color: '#000000'})
      })
    }),

    [floorFeatures.RULER_ORIENTATION]: () => new Style({
      fill: new Fill({ color: 'rgba(255, 255, 255, .2)' }),
      stroke: new Stroke({
        color: colors.accent,
        width: 6
      }),
      text: new Text({
        offsetY: 2,
        font: '16px Tahoma',
        fill: new Fill({ color: '#000000' })
      })
    }),

    [floorFeatures.INTERMEDIATE_ACCEPTANCE]: () => new Style({
      stroke: new Stroke({
        color: 'rgb(230, 162, 60)',
        width: 4
      }),
      fill: new Fill({ color: 'rgba(230, 162, 60, 0.4)' })
    }),

    [floorFeatures.FINAL_ACCEPTANCE]: () => new Style ({
      stroke: new Stroke({
        color: 'rgb(103, 194, 58)',
        width: 4
      }),
      fill: new Fill({ color: 'rgba(103, 194, 58, 0.4)' })
    }),

    [floorFeatures.INTERMEDIATE_ACCEPTANCE_OLD]: () => new Style({
      stroke: new Stroke({
        color: 'rgb(230, 162, 60)',
        width: 1
      }),
      fill: new Fill({ color: 'rgba(230, 162, 60, 0.1)' })
    }),

    [floorFeatures.FINAL_ACCEPTANCE_OLD]: () => new Style({
      stroke: new Stroke({
        color: 'rgb(103, 194, 58)',
        width: 1
      }),
      fill: new Fill({ color: 'rgba(103, 194, 58, 0.1)' })
    }),

    ...getWorkPolygonFeatureTypes().map(type => ({
      type,
      color: ({
        [floorFeatures.WORK_POLYGON_NEW]: [243, 106, 110],
        [floorFeatures.WORK_POLYGON_CHANGED]: [242, 153, 74],
        [floorFeatures.WORK_POLYGON_UNCHANGED]: [39, 174, 96],
        [floorFeatures.WORK_POLYGON_CREATED]: [64, 158, 255],
        [floorFeatures.WORK_POLYGON_AT_WORK]: [93, 95, 239],
        [floorFeatures.WORK_POLYGON_COMPLETED]: [39, 174, 96],
        [floorFeatures.WORK_POLYGON_COMPLETED_PARTIALLY]: [242, 153, 74],
        [floorFeatures.WORK_POLYGON_WITH_PROBLEM]: [235, 87, 87],
        [floorFeatures.WORK_POLYGON_WITH_WARNING]: [187, 107, 217]
      })[type]
    }))
      .filter(x => x.color)
      .reduce((r, { type, color }) => ({
        ...r,
        [type]: () => new Style({
          stroke: new Stroke({ color: `rgb(${color.join(', ')})`, width: 1 }),
          fill: new Fill({ color: `rgba(${color.join(', ')}, 0.5)` })
        })
      }), {})
  },

  markup: {
    [facadeFeatures.PERSPECTIVE]: () => new Style({
      stroke: new Stroke({
        color: 'rgba(228, 116, 112, 0.5)',
        width: 3
      }),
      fill: new Fill({color: 'rgba(228, 116, 112, 0.5)'})
    }),

    [facadeFeatures.SHAPE]: () => new Style({
      stroke: new Stroke({
        color: 'transparent',
        width: 1
      })
    }),

    [facadeFeatures.FACADE]: ({ selected }) => new Style({
      image: new Circle({
        radius: 6,
        stroke: new Stroke({
          color: selected ? colors.black : 'rgb(31,120,180)',
          width: 1
        }),
        fill: new Fill({color: 'rgb(31,120,180)'})
      })
    }),

    [facadeFeatures.INSULATION]: ({ selected }) => new Style({
      image: new Circle({
        radius: 6,
        stroke: new Stroke({
          color: selected ? colors.black : 'rgb(254,127,14)',
          width: 1
        }),
        fill: new Fill({color: 'rgb(254,127,14)'})
      })
    }),

    [facadeFeatures.WALL]: ({ selected }) => new Style({
      image: new Circle({
        radius: 6,
        stroke: new Stroke({
          color: selected ? colors.black : 'rgb(43,160,45)',
          width: 1
        }),
        fill: new Fill({color: 'rgb(43,160,45)'})
      })
    }),

    [facadeFeatures.GLASS]: ({ selected }) => new Style({
      image: new Circle({
        radius: 6,
        stroke: new Stroke({
          color: selected ? colors.black : '#8E44AD',
          width: 1
        }),
        fill: new Fill({ color: '#8E44AD' })
      })
    }),

    [facadeFeatures.VOID]: ({ selected }) => new Style({
      image: new Circle({
        radius: 6,
        stroke: new Stroke({
          color: selected ? colors.black : '#16A085',
          width: 1
        }),
        fill: new Fill({ color: '#16A085' })
      })
    }),

    default: () => new Style({
      stroke: new Stroke({
        color: 'red',
        width: 3
      }),
      fill: new Fill({color: 'rgba(255, 0, 0, 0.1)'}),
      image: new Circle({
        radius: 6,
        stroke: new Stroke({
          color: 'rgb(31,120,180)',
          width: 1
        }),
        fill: new Fill({color: 'rgb(31,120,180)'})
      })
    }),

    mask: () => new Fill({
      color: 'rgba(0, 0, 0, 0.6)'
    })
  },

  'label': ({ feature }) => new Text({
    text: feature.get('name'),
    offsetY: 1,
    font: '16px sans-serif',
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, .5)',
      width: 3
    }),
    fill: new Fill({ color: '#ffffff' })
  }),

  'sight': ({ selected, rotated, rotation, origin }) => new Style({
    image: new Icon({
      src: sightIcon,
      scale: rotated && selected ? 1 : 0,
      rotation: rotated ? origin + rotation : 0,
      anchor: [.47, .8]
    })
  }),

  'cluster': ({ feature }) => new Style({
    image: new Circle({
      radius: 20,
      stroke: new Stroke({
        color: '#409EFF',
        width: 3
      }),
      fill: new Fill({color: '#fff'})
    }),
    text: new Text({
      offsetY: 1,
      text: (feature.get('features') || []).filter(feature => !feature.get('_ignored')).length + '',
      font: 'bold 18px sans-serif',
      fill: new Fill({color: '#494A4D'})
    })
  })
}

export default self
