<template>
    <div v-loading="loading"
         class="f-col">
        <!-- Header -->
        <div class="_py-1 border-bottom-1 border-gray-300">
            <our-label :value="strings.title" />
        </div>

        <div class="f-col">
            <div v-for="entity in entities"
                 :key="entity.key"
                 class="f-x-between _py-0.25">
                <!-- Entity path -->
                <our-label :value="entity.label" />

                <!-- Actions -->
                <div class="f">
                    <el-tooltip v-if="statisticsByFloors[entity.id]"
                                class="_pr-0.25 cursor-default">
                        <div class="f">
                            <our-label :value="statisticsByFloors[entity.id].finished"
                                       color="green-1 no-shrink" />
                            <our-label value="/"
                                       class="_px-0.25" />
                            <our-label :value="statisticsByFloors[entity.id].intermediate"
                                       color="orange-1 no-shrink" />
                        </div>

                        <template slot="content">
                            <div class="f-col">
                                <our-label :value="statisticsByFloors[entity.id].aboutFinished"
                                           color="green-1"
                                           dark />

                                <our-label :value="statisticsByFloors[entity.id].aboutIntermediate"
                                           color="orange-1"
                                           dark />
                            </div>
                        </template>
                    </el-tooltip>
                    <el-tooltip :content="strings.markup">
                        <is-button icon="plan"
                                   color="gray-600"
                                   @click="goToPlan(entity)" />
                    </el-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { getEntities, getPolygonsByFloors, filterFinishedPolygons, filterIntermediatePolygons } from '@/models/tasks'

import dialogs from '@/values/dialogs'

const strings = {
  title: 'Список приемки',
  markup: 'Перейти к плану'
}

export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      strings
    }
  },
  computed: {
    ...mapGetters('floorPlans', ['plans', 'plansLoading']),

    loading() {
      return this.plansLoading
    },

    entities() {
      return getEntities(this.task)
    },

    polygonsByFloors() {
      return getPolygonsByFloors(this.task)
    },

    statisticsByFloors() {
      const ids = Object.keys(this.polygonsByFloors)

      return ids.reduce((r, id) => {
        const a = this.polygonsByFloors[id].filter(filterFinishedPolygons).length
        const b = this.polygonsByFloors[id].filter(filterIntermediatePolygons).length

        return {
          ...r,
          [id]: {
            finished: a,
            intermediate: b,
            aboutFinished: `Окончательная приемка: ${a}`,
            aboutIntermediate: `Промежуточная приемка: ${b}`
          }
        }
      }, {})
    }
  },
  methods: {
    ...mapActions('tasks', ['setAcceptanceDataForPlanViewer']),
    ...mapActions('floorPlans', ['fetchPlans']),

    async goToPlan(entity) {
      const floorId = entity.id

      await this.fetchPlans({ floorId })

      const plan = this.plans[0]
      const acceptance = this.$route.params.taskId
      const editable = this.editable

      if (plan) {
        this.$router.push({
          name: 'project.plan',
          params: { planId: plan.id },
          query: { acceptance, editable }
        })
      } else dialogs.notFoundFloorPlan.call(this)
    }
  }
}
</script>
