export const LegendGroups = [{
  title: 'Статусы',
  groupItems: [{
    name: 'Просроченные',
    color: '#DB4A6D'
  },
  {
    name: 'В работе',
    color: '#FFEF98'
  },
  {
    name: 'Завершенные',
    color: '#6BC8C2'
  }]
}, {
  title: 'Кол-во изменений',
  groupItems: [{
    name: 'Много',
    color: '#F56C6C'
  },
  {
    name: ' Средне',
    color: '#E6A23C'
  },
  {
    name: 'Мало',
    color: '#67C23A'
  },
  {
    name: 'Нет',
    color: '#909399'
  }]
},
{
  title: 'Виды точек',
  groupItems: [{
    name: 'Квадрокоптер',
    icon: 'quadcopter',
    color: '#909399'
  },
  {
    name: 'Камера 360°',
    icon: 'camera',
    color: '#909399'
  }]
}
]
