<template>
    <div class="_mb-1">
        <div class="f-col space-y-1">
            <!-- Floors -->
            <div v-for="(objectData, index) in preparedObjects"
                 :key="index"
                 class="f-col">
                <!-- Header -->
                <div v-if="editable || objectData.withoutDefectPhotos.length"
                     class="pright-1 border-box">
                    <our-label :value="objectData.floorName"
                               primary />
                    <our-label :value="objectData.houseName" />
                </div>

                <!-- Body -->
                <task-photos-block :photos="objectData.withoutDefectPhotos"
                                   :photo-type="'acceptance_work_photo_without_defect'"
                                   :object="objectData.object"
                                   :editable="editable"
                                   with-user
                                   @on-click-photo="index => handleShowWithoutDefectPhotos(index, objectData.withoutDefectPhotos)" />
            </div>

            <!-- Defects -->
            <div class="f-col">
                <!-- Header -->
                <div v-if="(editable || canCreateDefect || canSelectDefect) || defectPhotos.length"
                     class="f-x-between f-y-center pright-1 border-box">
                    <!-- Title -->
                    <our-label value="Дефекты"
                               primary />

                    <!-- Go to tasks -->
                    <el-button type="text"
                               :disabled="!defectPhotos.length"
                               @click="goToDefects">
                        Перейти к дефектам
                    </el-button>
                </div>

                <!-- Body -->
                <task-photos-block :photos="defectPhotos"
                                   is-defect
                                   :editable="editable"
                                   :can-create-defect="canCreateDefect"
                                   :can-select-defect="canSelectDefect"
                                   with-user
                                   @create-defect="createDefect"
                                   @select-defect="selectDefect"
                                   @select-defect-old="selectDefectOld"
                                   @remove-defect="removeDefect"
                                   @on-click-photo="handleShowDefectPhotos" />
            </div>

            <!-- Checks -->
            <div v-if="withChecks"
                 class="f-col">
                <!-- Header -->
                <div v-if="editable || checklistPhotos.length"
                     class="pright-1 border-box">
                    <our-label value="Чек-лист"
                               primary />
                </div>

                <!-- Body -->
                <task-photos-block :photos="checklistPhotos"
                                   :photo-type="'acceptance_work_photo_check_list'"
                                   :editable="editable"
                                   with-user
                                   @on-click-photo="handleShowChecklistPhotos" />
            </div>
        </div>

        <!-- Gallery -->
        <task-photos-gallery v-if="showGallery"
                             :media="media"
                             :show-gallery="showGallery"
                             :show-caption="showCaption"
                             :position="galleryIndex"
                             @image-select="selectGalleryImage"
                             @on-close-gallery="closeGallery" />

        <!-- Selector -->
        <task-selector v-model="selectedTasks"
                       :visible="taskSelectorVisible"
                       multiple
                       for-building-order
                       @close="taskSelectorVisible = false"
                       @confirm="applyDefects" />
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import { type, getCaptures } from '@/models/tasks'

import TaskPhotosGallery from '@/components/task/TaskPhotosGallery'
import TaskPhotosBlock from '@/components/task/TaskPhotosBlock'
import TaskSelector from '@/components/tasks/TaskSelector'

export default {
  name: 'TaskPhotos',
  components: {
    TaskPhotosGallery,
    TaskPhotosBlock,
    TaskSelector
  },
  props: {
    task: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },

    canCreateDefect: {
      type: Boolean,
      default: false
    },

    canSelectDefect: {
      type: Boolean,
      default: false
    },

    withChecks: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showGallery: false,
      showCaption: false,
      media: [],
      galleryIndex: 0,

      selectedTasks: [],
      taskSelectorVisible: false
    }
  },
  computed: {
    ...mapGetters('tasks', [
      'contractorId', 
      'objects', 
      'withoutDefectPhotos', 
      'checklistPhotos', 
      'relatedTasks'
    ]),

    preparedObjects()  {
      return this.objects.map(({object_type, object_id, project_object_structure}) => {
        const {houseName, floorName} = this.createPhotoPath(project_object_structure);
        const withoutDefectPhotos = this.withoutDefectPhotos.filter(({data}) => data.object.object_id === object_id)

        return {
          houseName,
          floorName,
          withoutDefectPhotos,
          object: {
            object_type,
            object_id
          }
        }
      })
    },

    defectPhotos() {
      return this.relatedTasks.map(task => ({ 
        url: task.data.acceptance_work_defect_image?.storage_thumb_url || task.data.image_defect_url, 
        creator: task.creator,
        task
      }));
    },

    byAcceptance() {
      return this.task.type === type.ACCEPTANCE_OF_WORK
    },

    byBuildingOrder() {
      return this.task.type === type.BUILDING_ORDER
    }
  },
  watch: {
    relatedTasks: {
      handler(tasks) {
        this.selectedTasks = [...tasks]
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('form', {showForm: 'SHOW_FORM'}),
    ...mapActions('tasks', [
      'storeTaskImage', 
      'showTask', 
      'storeTask',
      'updateTask',
      'workTask',
      'removeRelatedTask',
      'toggleTasks'
    ]),

    createDefect() {
      const objectsData = this.preparedObjects.map(({houseName, floorName, object}) => ({houseName, floorName, object}))
      const payload = {
        task: this.task,
        contractorId: this.contractorId,
        objectsData
      };
      this.showForm({
        formName: 'task-photo-upload-form',
        formTitle: 'Добавить дефект',
        payload
      });
    },

    selectDefect() {
      this.$router.push({
        name: 'project.tasks.defects',
        query: {
          for: this.task.id,
          about: 'Выбор дефектов для предписания',
          selection: this.relatedTasks.map(x => x.id).join(','),

          'filter[allowedForBuildingOrderTask]': 1
        }
      })
    },

    selectDefectOld() {
      this.taskSelectorVisible = true
    },

    removeDefect(task) {
      this.$emit('remove-defect', task)
    },

    applyDefects(tasks) {
      this.$emit('apply-defects', tasks)
    },

    goToDefects() {
      this.$router.push({
        name: 'project.tasks.defects',
        query: {
          id: this.relatedTasks.map(x => x.id).join(',')
        }
      }) 
    },

    selectGalleryImage(image) {
      const i = this.media.findIndex(({ id }) => id === image?.id)

      this.galleryIndex = i
    },

    prepareMedia(photos) {
      return photos.map(({image}) => {
        const { id, storage_thumb_url, storage_url } = image;
        return {
          id,
          thumb: storage_thumb_url,
          src: storage_url
        }
      })
    },
    prepareDefectsMedia(tasks) {
      const makeAcceptanceCaption = task => {
        const { project_object_structure } = task.data.acceptance_work_object;
        const { houseName, floorName } = this.createPhotoPath(project_object_structure);

        const caption = `<div class="f-center h-full fs-0.8">${houseName} / ${floorName}</div>`

        return caption
      }

      return tasks.map(task => {
        const captures = getCaptures(task, { withLabels: true, withPrefixes: true })

        const images = captures.map(({ id, thumb, object_id, object_type }) => ({
          id,
          thumb,
          src: thumb,
          object_id,
          object_type
        }))

        const image = images[0]

        return {
          ...image,
          ...this.byAcceptance && { caption: makeAcceptanceCaption(task) }
        }
      })
    },
    createPhotoPath(projectObjectStructure) {
      const {house, floor} = projectObjectStructure;
      const houseStreet = house.street ? house.street : '';
      const houseNumber = house.number ? house.number : '';
      const houseName = `${houseStreet} ${houseNumber}`;
      const floorName = floor.number ? `Этаж ${floor.number}` : '';

      return {houseName, floorName};
    },
    handleShowWithoutDefectPhotos(index, photos) {
      this.media = this.prepareMedia(photos);
      this.galleryIndex = index;
      this.showGallery = true;
    },
    handleShowDefectPhotos(index) {
      this.media = this.prepareDefectsMedia(this.relatedTasks);
      this.galleryIndex = index;
      this.showGallery = true;
      this.showCaption = true;
    },
    handleShowChecklistPhotos(index) {
      this.media = this.prepareMedia(this.checklistPhotos);
      this.galleryIndex = index;
      this.showGallery = true;
    },
    closeGallery() {
      this.showGallery = false;
      this.showCaption = false;
    }
  }
}
</script>
