<template>
    <div v-show="points.length"
         class="abs r-0.5 depth-10 f f-col p-2 w-20">
        <!-- Header -->
        <div class="f f-v-center p-2 bg-rock rounded cursor-pointer"
             @click="toggle">
            <!-- Icon -->
            <icon v-if="icons.point"
                  :name="icons.point"
                  color="gray-500"
                  class="wh-1.5 _p-0.3 rounded bg-white border-box" />

            <!-- Title -->
            <p class="pl-2 m-0 text-white fs-0.9">
                {{ labels.title }}
            </p>

            <div class="grow" />

            <!-- Toggle -->
            <div class="f f-v-center wh-1.5">
                <minus-icon v-if="opened" 
                            width="16"
                            height="16"
                            color="#FAFAFA" />
                <plus-icon v-else
                           width="16"
                           height="16"
                           color="#FAFAFA" />
            </div>
        </div>

        <!-- Body -->
        <div v-show="opened"
             v-loading="loading"
             class="p-2 mt-4 bg-rock rounded">
            <!-- Jobs header -->
            <our-label v-if="hasSavedPoints"
                       :value="labels.jobs"
                       class="fs-0.9"
                       secondary />

            <!-- Jobs container -->
            <el-select v-if="hasSavedPoints"
                       ref="jobs"
                       class="mt-2 w-full"
                       :value="selectedJobsNames"
                       :disabled="!editable"
                       multiple
                       @change="doJobs">
                <el-option-group v-for="group in groupedJobs"
                                 :key="group.key"
                                 :label="group.name">
                    <el-option v-for="job in group.children"
                               v-show="!job.deleted"
                               :key="job.id"
                               :value="job.name"
                               :label="job.code + ' ' + job.name">
                        <del v-if="job.removed">{{ job.code + ' ' + job.name }}</del>
                        <span v-else>{{ job.code + ' ' + job.name }}</span>
                    </el-option>
                </el-option-group>
            </el-select>

            <!-- Tags header -->
            <our-label v-if="hasSavedPoints"
                       :value="labels.tags"
                       class="mt-2 fs-0.9"
                       secondary />

            <!-- Tags container -->
            <el-select v-if="hasSavedPoints"
                       :value="selectedTagsNames"
                       class="mt-2 w-full"
                       :disabled="!editable"
                       multiple
                       filterable
                       allow-create
                       @change="doTags">
                <el-option-group v-for="group in groupedTags"
                                 :key="group.key"
                                 :label="group.name">
                    <el-option v-for="tag in group.children"
                               :key="tag.id"
                               :value="tag.name" />
                </el-option-group>
            </el-select>

            <!-- Footer -->
            <div v-if="editable && hasSavedPoints"
                 class="f f-v-center pt-2">
                <!-- Confirm -->
                <el-button type="primary"
                           size="mini"
                           @click="confirm">
                    Сохранить
                </el-button>

                <!-- Cancel -->
                <el-button type="secondary"
                           size="mini"
                           @click="cancel">
                    Отменить
                </el-button>

                <div class="spacer" />
            </div>

            <!-- Empty -->
            <el-empty v-if="editable && !hasSavedPoints"
                      description="Сохраните точки для продолжения редактирования" />
        </div>

        <div v-if="editable"
             class="f-x-end mt-2">
            <!-- Remove -->
            <plan-button 
                icon="trash"
                tooltip="Удалить точку съемки"
                :permissions="permissionList['project.structure.management']"
                @click="remove" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { FEATURE_TYPES } from '@/utils/plan'
import { wordOfNumber } from '@/utils/format'

import dialogs from '@/values/dialogs'

import PlusIcon from '@/components/icons/Plus'
import MinusIcon from '@/components/icons/Minus'
import PlanButton from '@/components/map/PlanButton'

export default {
  components: {
    PlusIcon,
    MinusIcon,
    PlanButton
  },

  props: {
    editable: { type: Boolean, default: false }
  },

  data: () => ({
    opened: false
  }),

  computed: {
    ...mapGetters('viewer/plan', {
      'points': 'selectedPoints',
      'savedPoints': 'selectedSavedPoints'
    }),

    ...mapGetters('dirsRevision', [
      'tags',
      'jobs',
      'tagsLoading',
      'jobsLoading'
    ]),

    ...mapGetters('points', [
      'groupedTags',
      'groupedJobs',

      'fetchPointTagsLoading',
      'fetchPointJobsLoading',
      'saveTagsLoading',
      'saveJobsLoading',

      'intersectedPointsTags',
      'intersectedPointsJobs'
    ]),

    loading() {
      return [
        this.tagsLoading, this.jobsLoading, 
        this.fetchPointTagsLoading, this.fetchPointJobsLoading, 
        this.saveTagsLoading, this.saveJobsLoading
      ].some(x => x)
    },

    icons() {
      const multiple = this.points.length > 1

      const type = {
        [FEATURE_TYPES.CAMERA360]: 'circle',
        [FEATURE_TYPES.QUADCOPTER]: 'qopter',
        [FEATURE_TYPES.FACADE]: 'building',
        [FEATURE_TYPES.DEFECT]: 'rect' 
      }

      return {
        point: multiple ? 'list' : type[this.points[0]?.['type']]
      }
    },

    color() {
      return {
        [FEATURE_TYPES.CAMERA360]: '#9E9E9E',
        [FEATURE_TYPES.QUADCOPTER]: '#9E9E9E',
        [FEATURE_TYPES.FACADE]: '#9E9E9E',
        [FEATURE_TYPES.DEFECT]: '#E47470'
      }[this.points[0]?.['type']]
    },

    labels() {
      const count = this.points.length
      const multiple = count > 1

      const type = {
        [FEATURE_TYPES.CAMERA360]: 'Камера 360',
        [FEATURE_TYPES.QUADCOPTER]: 'Квадрокоптер',
        [FEATURE_TYPES.FACADE]: 'Фасад',
        [FEATURE_TYPES.DEFECT]: 'Дефект'
      }[this.points[0]?.['type']]

      const name = this.points[0]?.name

      return {
        title: multiple ? `Выбрано ${count} ${wordOfNumber(count, ['точка', 'точки', 'точек'])}` : `${type} − ${name}`,
        jobs: ['Виды работ', multiple && '(общие)'].filter(x => x).join(' '),
        tags: ['Теги', multiple && '(общие)'].filter(x => x).join(' ')
      }
    },

    selectedTagsNames: function() {
      return this.intersectedPointsTags
        .map(({ name }) => name) 
    },

    selectedJobsNames: function() { 
      return this.intersectedPointsJobs
        .map(({ name }) => name) 
    },

    hasSavedPoints() {
      return this.savedPoints.length
    }
  },

  mounted() {
    this.fetchTags()
    this.fetchJobs({ withDeleted: true })
  },

  updated() {
    this.postprocess()
  },

  methods: {
    ...mapActions('dirsRevision', [
      'fetchTags',
      'fetchJobs'
    ]),

    ...mapActions('points', [
      'fetchPointTags',
      'fetchPointJobs',

      'storePointsTags',
      'storePointsJobs',

      'saveTags',
      'saveJobs'
    ]),

    toggle() {
      this.opened = !this.opened
    },

    doTags(names) {
      this.storePointsTags(names)
    },

    doJobs(names) {
      this.storePointsJobs(names)
    },

    confirm() {
      Promise.all([this.saveTags(), this.saveJobs()]).then(() => this.fetchTags()).then(() => dialogs.saved.call(this))
    },

    cancel() {
      this.savedPoints.forEach(point => this.fetchPointTags(point))
      this.savedPoints.forEach(point => this.fetchPointJobs(point))
    },

    remove() {
      this.$emit('remove-point', this.points[0])
    },

    /**
     * TODO@shit
     */
    postprocess() {
      const cross = x => this.intersectedPointsJobs.some(y => y.is_deleted && x.innerText === y.code + ' ' + y.name) 
        ? x.classList.add('cross') 
        : x.classList.remove('cross')

      Array
        .from(this.$refs.jobs?.$el?.querySelectorAll?.('.el-select__tags-text') || [])
        .forEach(cross)
    }
  }
}
</script>
