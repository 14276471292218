<template>
    <el-drawer v-if="opened"
               :visible="opened"
               :direction="direction"
               :size="size"
               :modal="modal"
               class="right-side-dialog right-side-dialog__without-padding"
               :before-close="close"
               :show-close="false">
        <!-- Header -->
        <template slot="title">
            <div class="f-v-center f-x-between">
                <!-- Custom title -->
                <slot v-if="hasTitleSlot"
                      name="title" />

                <!-- Default title -->
                <our-label v-else
                           :value="title" />

                <!-- Actions -->
                <div class="f-v-center">
                    <!-- Custom -->
                    <slot name="actions" />

                    <!-- Close -->
                    <icon name="cross"
                          color="gray-600"
                          class="wh-2 cursor-pointer"
                          @click="close" />
                </div>
            </div>
        </template>

        <template #default>
            <el-form ref="form"
                     v-loading="loading"
                     :model="value"
                     :rules="rules"
                     :validate-on-rule-change="false"
                     size="mini"
                     label-position="top"
                     :class="classes.form">
                <slot name="default" />
            </el-form>
        </template>
    </el-drawer>
</template>

<script>
import dialogs from '@/values/dialogs'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Object, default: () => ({}) },
    title: { type: String, default: 'Форма' },
    size: { type: String, default: '340px' },
    modal: { type: Boolean, default: true },
    opened: { type: Boolean, default: false },
    direction: { type: String, default: 'rtl' },
    rules: { type: Object, default: () => ({}) },
    confirmable: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    formClass: { type: String, default: undefined }
  },
  data() {
    return {
      errors: {}
    }
  },
  computed: {
    classes() {
      return {
        form: {
          'wh-full _p-1': true,
          [this.formClass]: this.formClass
        }
      }
    },

    hasTitleSlot() {
      return !!this.$slots.title
    }
  },
  methods: {
    close() {
      const toPromise = this.confirmable && this.changed 
        ? () => dialogs.confirmClosing.call(this, { message: 'Вы уверены, что хотите закрыть форму? Несохраненные данные будут потеряны.' })
        : () => Promise.resolve(true)

      toPromise().then(() => {
        this.$emit('close')
      }).catch(() => {})
    },

    byValidation(handler) {
      this.$refs['form'].validate(handler)
    },

    byFieldValidation(name, handler) {
      this.$refs['form'].validateField(name, message => handler(!message))
    },

    validate() {
      this.$refs['form'].validate(() => {})
    }
  }
}
</script>
