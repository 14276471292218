export const VIEW_MODES = {
  POINT: 'point',
  ROOM: 'room',
  PROTOCOL: 'protocol',
  TECH_ROOM: 'techRoom',
  PREMISES: 'premises',
  ACCEPTANCE: 'acceptance'
}

export const FEATURE_TYPES = {
  CAMERA360: 'camera360',
  QUADCOPTER: 'quadcopter',
  FACADE: 'facade',
  DEFECT: 'defect',

  ROOM: 'room',
  PROTOCOL: 'protocol',
  PREMISES: 'premises',
  TECH_ROOM: 'techRoom',

  RULER_CALIBRATION: 'rulerCalibration',
  RULER_LENGTH: 'rulerLength',
  RULER_AREA: 'rulerArea',
  RULER_ORIENTATION: 'rulerOrientation',

  FINAL_ACCEPTANCE: 'finalAcceptance',
  INTERMEDIATE_ACCEPTANCE: 'intermediateAcceptance',
  FINAL_ACCEPTANCE_OLD: 'finalAcceptanceOld',
  INTERMEDIATE_ACCEPTANCE_OLD: 'intermediateAcceptanceOld',

  WORK_POLYGON_NEW: 'workPolygonNew',
  WORK_POLYGON_CHANGED: 'workPolygonChanged',
  WORK_POLYGON_UNCHANGED: 'workPolygonUnchanged',

  WORK_POLYGON_CREATED: 'work_polygon_created',
  WORK_POLYGON_AT_WORK: 'work_polygon_at_work',
  WORK_POLYGON_COMPLETED: 'work_polygon_completed',
  WORK_POLYGON_COMPLETED_PARTIALLY: 'work_polygon_completed_partially',
  WORK_POLYGON_WITH_PROBLEM: 'work_polygon_with_problem',
  WORK_POLYGON_WITH_WARNING: 'work_polygon_with_warning'
}
export const GEOMETRY_TYPES = {
  POINT:'Point',
  POLYGON:'Polygon',
  LINE_STRING: 'LineString'
}

export const DEFAULT_RADIUS =  6371008.8

export const ROOM_TYPES = {
  FLAT: 'flat',
  APARTMENT: 'apartment',
  STOREROOM: 'storeroom',
  PARKING: 'parking',
  NON_RESIDENTAL_PREMISES: 'non_residental_premises',
  TERRITORY: 'territory'
}

export const ROOM_TYPE_COLORS = {
  FLAT: '#DB4A6D',
  APARTMENT: '#FFEF98',
  STOREROOM: '#6BC8C2',
  PARKING: '#946BC8',
  NON_RESIDENTAL_PREMISES: '#DE8857',
  TERRITORY: '#4B8BEB'
}
