export const premises = [
  {
    'id': '87f46e20-9f86-11ec-8f2a-2b7031a98a21',
    'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
    'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
    'name': 'Квартира 167',
    'status': 'new',
    'type': 'flat',
    'state': 'wait_worker',
    'expired': false,
    'for_delete': false,
    'data': {
      'building_standard_id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
      'building_standard': {
        'id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
        'type': 'gost',
        'name': 'tret',
        'description': 'tret',
        'translated_type': 'ГОСТ',
        'created_at': '2021-06-25 07:12:18',
        'updated_at': '2021-06-25 13:23:18'
      },
      'color': '#DB4A6D',
      'marks': [
        [
          [
            185.89453125000003,
            723.4423828125001
          ],
          [
            184.19531250000003,
            599.3994140625
          ],
          [
            293.79492187500006,
            598.5498046875
          ],
          [
            297.19335937500006,
            731.9384765625
          ],
          [
            185.89453125000003,
            723.4423828125001
          ]
        ]
      ],
      'work_type_id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
      'work_type': {
        'id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
        'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
        'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
        'name': 'Общий',
        'created_at': '2022-02-11 14:13:10',
        'updated_at': '2022-02-11 14:13:20'
      },
      'contractor_id': 'd29b1670-d7e3-11eb-aa31-31d97d66b4af',
      'floor_plan_id': '4979b2d0-1ada-11ec-9c87-2db986cbec47',
      'inspector_id': '75714390-87fa-11ec-8ca2-f935c0ec0d3f'
    },
    'translated_status': 'Новые',
    'translated_type': 'Протокол',
    'translated_state': 'Ожидание исполнителя',
    'start_at': '2022-03-13 20:00:00',
    'expired_at': '2022-04-12 20:00:00',
    'created_at': '2022-03-09 08:54:29',
    'updated_at': '2022-03-09 08:54:29'
  },
  {
    'id': '87f46e20-9f86-11ec-8f2a-2b7031a98a21',
    'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
    'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
    'name': 'Кладовая',
    'status': 'storeroom',
    'type': 'storeroom',
    'state': 'wait_worker',
    'expired': false,
    'for_delete': false,
    'data': {
      'building_standard_id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
      'building_standard': {
        'id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
        'type': 'gost',
        'name': 'tret',
        'description': 'tret',
        'translated_type': 'ГОСТ',
        'created_at': '2021-06-25 07:12:18',
        'updated_at': '2021-06-25 13:23:18'
      },
      'color': '#6BC8C2',
      'marks': [
        [
          [
            363.46289062500006,
            366.60644531250006
          ],
          [
            369.41015625000006,
            319.02832031250006
          ],
          [
            494.3027343750001,
            317.32910156250006
          ],
          [
            494.3027343750001,
            362.35839843750006
          ],
          [
            363.46289062500006,
            366.60644531250006
          ]
        ]
      ],
      'work_type_id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
      'work_type': {
        'id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
        'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
        'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
        'name': 'Общий',
        'created_at': '2022-02-11 14:13:10',
        'updated_at': '2022-02-11 14:13:20'
      },
      'contractor_id': 'd29b1670-d7e3-11eb-aa31-31d97d66b4af',
      'floor_plan_id': '4979b2d0-1ada-11ec-9c87-2db986cbec47',
      'inspector_id': '75714390-87fa-11ec-8ca2-f935c0ec0d3f'
    },
    'translated_status': 'Новые',
    'translated_type': 'Протокол',
    'translated_state': 'Ожидание исполнителя',
    'start_at': '2022-03-13 20:00:00',
    'expired_at': '2022-04-12 20:00:00',
    'created_at': '2022-03-09 08:54:29',
    'updated_at': '2022-03-09 08:54:29'
  },
  {
    'id': '87f46e20-9f86-11ec-8f2a-2b7031a98a21',
    'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
    'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
    'name': 'Апартаменты 1',
    'status': 'new',
    'type': 'apartment',
    'state': 'wait_worker',
    'expired': false,
    'for_delete': false,
    'data': {
      'building_standard_id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
      'building_standard': {
        'id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
        'type': 'gost',
        'name': 'tret',
        'description': 'tret',
        'translated_type': 'ГОСТ',
        'created_at': '2021-06-25 07:12:18',
        'updated_at': '2021-06-25 13:23:18'
      },
      'color': '#FFEF98',
      'marks': [
        [
          [
            607.3007812500001,
            424.37988281250006
          ],
          [
            614.0976562500001,
            287.5927734375
          ],
          [
            725.3964843750001,
            290.9912109375
          ],
          [
            720.2988281250001,
            425.22949218750006
          ],
          [
            607.3007812500001,
            424.37988281250006
          ]
        ]
      ],
      'work_type_id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
      'work_type': {
        'id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
        'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
        'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
        'name': 'Общий',
        'created_at': '2022-02-11 14:13:10',
        'updated_at': '2022-02-11 14:13:20'
      },
      'contractor_id': 'd29b1670-d7e3-11eb-aa31-31d97d66b4af',
      'floor_plan_id': '4979b2d0-1ada-11ec-9c87-2db986cbec47',
      'inspector_id': '75714390-87fa-11ec-8ca2-f935c0ec0d3f'
    },
    'translated_status': 'Новые',
    'translated_type': 'Протокол',
    'translated_state': 'Ожидание исполнителя',
    'start_at': '2022-03-13 20:00:00',
    'expired_at': '2022-04-12 20:00:00',
    'created_at': '2022-03-09 08:54:29',
    'updated_at': '2022-03-09 08:54:29'
  }
]

export const acceptances = [
  {
    'id': '1',
    'type': 'finalAcceptanceOld',
    'task_data': {
      'task_id': 'ee855b50-b709-11ec-bd05-959e12788a90',
      'start_at': '2022-04-15 00:00:00',
      'comment_initiator': 'Армирование вертикальных конструкций',
      'comment_worker': 'Армирование не готово',
      'contractor': {
        'organization': 'ООО "СКР-10"'
      },
      'worker': {
        'name': 'Огарков Роман Вячеславович',
        'organization': 'АО ГК "Основа"'
      }
    },
    'marks': [
      [
        [
          247.99414062500003,
          621.6147460937501
        ],
        [
          249.03613281250003,
          327.7729492187501
        ],
        [
          327.185546875,
          326.7309570312501
        ],
        [
          331.353515625,
          607.0268554687501
        ],
        [
          247.99414062500003,
          621.6147460937501
        ]
      ]
    ]
  },
  {
    'id': '2',
    'type': 'intermediateAcceptanceOld',
    'task_data': {
      'task_id': 'ee855b50-b709-11ec-bd05-959e12788a90',
      'start_at': '2022-05-11 00:00:00',
      'comment_initiator': 'Плита перекрытия стилобата',
      'comment_worker': 'Работы приняты',
      'contractor': {
        'organization': 'ООО "СКР-10"'
      },
      'worker': {
        'name': 'Мефодьев Александр',
        'organization': 'ООО "ОБЛСТРОЙИНВЕСТ"'
      }
    },
    'marks': [
      [
        [
          589.767578125,
          268.3793945312501
        ],
        [
          588.7255859375,
          63.106933593750114
        ],
        [
          777.326171875,
          64.14892578125011
        ],
        [
          779.41015625,
          269.4213867187501
        ],
        [
          589.767578125,
          268.3793945312501
        ]
      ]
    ]
  },
  {
    'id': '3',
    'type': 'finalAcceptanceOld',
    'task_data': {
      'task_id': 'ee855b50-b709-11ec-bd05-959e12788a90',
      'start_at': '2022-05-11 00:00:00',
      'comment_initiator': 'Отделка',
      'comment_worker': 'Работы приняты',
      'contractor': {
        'organization': 'ООО "СКР-10"'
      },
      'worker': {
        'name': 'Мефодьев Александр',
        'organization': 'ООО "ОБЛСТРОЙИНВЕСТ"'
      }
    },
    'marks': [
      [
        [
          589.767578125,
          272.5473632812501
        ],
        [
          589.767578125,
          66.23291015625011
        ],
        [
          783.578125,
          66.23291015625011
        ],
        [
          782.5361328125,
          270.4633789062501
        ],
        [
          589.767578125,
          272.5473632812501
        ]
      ]
    ]
  },
  {
    'id': '4',
    'type': 'intermediateAcceptanceOld',
    'task_data': {
      'task_id': 'ee855b50-b709-11ec-bd05-959e12788a90',
      'start_at': '2022-08-09 00:00:00',
      'comment_initiator': 'Плита перекрытия стилобата',
      'comment_worker': 'Работы приняты',
      'contractor': {
        'organization': 'ООО "СКР-10"'
      },
      'worker': {
        'name': 'Мефодьев Александр',
        'organization': 'ООО "ОБЛСТРОЙИНВЕСТ"'
      }
    },
    'marks': [
      [
        [
          251.1201171875,
          602.8588867187501
        ],
        [
          323.017578125,
          601.8168945312501
        ],
        [
          320.93359375,
          332.9829101562501
        ],
        [
          255.2880859375,
          332.9829101562501
        ],
        [
          251.1201171875,
          602.8588867187501
        ]
      ]
    ]
  },
  {
    'id': '5',
    'type': 'finalAcceptanceOld',
    'task_data': {
      'task_id': 'ee855b50-b709-11ec-bd05-959e12788a90',
      'start_at': '2022-05-11 00:00:00',
      'comment_initiator': 'Окна',
      'comment_worker': 'Работы приняты',
      'contractor': {
        'organization': 'ООО "СКР-10"'
      },
      'worker': {
        'name': 'Мефодьев Александр',
        'organization': 'ООО "ОБЛСТРОЙИНВЕСТ"'
      }
    },
    'marks': [
      [
        [
          500.15625,
          564.3051757812501
        ],
        [
          510.576171875,
          322.5629882812501
        ],
        [
          712.72265625,
          322.5629882812501
        ],
        [
          706.470703125,
          529.9194335937501
        ],
        [
          675.2109375,
          525.7514648437501
        ],
        [
          672.0849609375,
          575.7670898437501
        ],
        [
          500.15625,
          564.3051757812501
        ]
      ]
    ]
  },
  {
    'id': '6',
    'type': 'intermediateAcceptanceOld',
    'task_data': {
      'task_id': 'ee855b50-b709-11ec-bd05-959e12788a90',
      'start_at': '2022-05-12 00:00:00',
      'comment_initiator': 'Плита перекрытия стилобата',
      'comment_worker': 'Работы не приняты',
      'contractor': {
        'organization': 'ООО "СКР-10"'
      },
      'worker': {
        'name': 'Мефодьев Александр',
        'organization': 'ООО "ОБЛСТРОЙИНВЕСТ"'
      }
    },
    'marks': [
      [
        [
          537.66796875,
          512.2055664062501
        ],
        [
          542.8779296875,
          366.3266601562501
        ],
        [
          590.8095703125,
          366.3266601562501
        ],
        [
          587.68359375,
          515.3315429687501
        ],
        [
          537.66796875,
          512.2055664062501
        ]
      ]
    ]
  },
  {
    'id': '7',
    'type': 'intermediateAcceptanceOld',
    'task_data': {
      'task_id': 'ee855b50-b709-11ec-bd05-959e12788a90',
      'start_at': '2022-06-01 00:00:00',
      'comment_initiator': 'Отделка потолков',
      'comment_worker': 'Работы приняты',
      'contractor': {
        'organization': 'ООО "СКР-10"'
      },
      'worker': {
        'name': 'Мефодьев Александр',
        'organization': 'ООО "ОБЛСТРОЙИНВЕСТ"'
      }
    },
    'marks': [
      [
        [
          81.275390625,
          208.9858398437501
        ],
        [
          78.1494140625,
          68.31689453125011
        ],
        [
          159.4248046875,
          67.27490234375011
        ],
        [
          161.5087890625,
          207.9438476562501
        ],
        [
          81.275390625,
          208.9858398437501
        ]
      ]
    ]
  },
  {
    'id': '8',
    'type': 'finalAcceptanceOld',
    'task_data': {
      'task_id': 'ee855b50-b709-11ec-bd05-959e12788a90',
      'start_at': '2022-07-10 00:00:00',
      'comment_initiator': 'Стяжка',
      'comment_worker': 'Работы приняты',
      'contractor': {
        'organization': 'ООО "СКР-10"'
      },
      'worker': {
        'name': 'Мефодьев Александр',
        'organization': 'ООО "ОБЛСТРОЙИНВЕСТ"'
      }
    },
    'marks': [
      [
        [
          125.0390625,
          278.7993164062501
        ],
        [
          120.87109375,
          164.1801757812501
        ],
        [
          197.978515625,
          166.2641601562501
        ],
        [
          192.7685546875,
          278.7993164062501
        ],
        [
          125.0390625,
          278.7993164062501
        ]
      ]
    ]
  }
]
