<template>
    <div class="cols-min-minmax cursor-pointer"
         @click="click">
        <!-- Icon -->
        <is-button 
            icon="rect-outlined"
            class="without-events"
            :color="colors.icon" />

        <div class="cols-minmax-min">
            <!-- Name -->
            <el-tooltip 
                :content="name"
                :enterable="false"
                :open-delay="500">
                <is-label 
                    :value="name"
                    :tertiary="!selected"
                    truncate
                    dark />
            </el-tooltip>

            <!-- Number -->
            <is-label
                :value="number"
                :tertiary="!selected"
                x-align="end"
                class="w-2"
                dark />
        </div>
    </div>
</template>

<script>
import { getWorkPolygonColor, getWorkPolygonName } from '@/models/plans'

export default {
  props: {
    polygon: { type: Object, default: null },
    layer: { type: Object, default: null },
    index: { type: Number, default: 0 },
    selected: { type: Boolean, default: false },
    units: { type: Array, default: () => [] },
    animated: { type: Boolean, default: true }
  },
  data() {
    return {}
  },
  computed: {
    colors() {
      return {
        icon: getWorkPolygonColor(this.polygon, { layer: this.layer })
      }
    },

    name() {
      return getWorkPolygonName(this.polygon, { layer: this.layer })
    },
    
    number() {
      return this.index + 1
    }
  },
  watch: {
    selected(x) {
      x && this.$el.scrollIntoView({ behavior: this.animated ? 'smooth' : 'instant' })
    }
  },
  methods: {
    click() {
      this.$emit('open', this.polygon)
    }
  }
}
</script>
