<template>
    <fieldable :label="label"
               :name="name"
               :errors="errors"
               :required="required"
               :no="independent">
        <!-- Selector -->
        <el-select v-loading="loading"
                   :value="value"
                   :placeholder="placeholder"
                   :disabled="disabled"
                   :remote-method="search"
                   :loading="loading"
                   :value-key="asKey ? null : 'id'"
                   size="mini"
                   class="w-full"
                   remote
                   filterable
                   clearable
                   @input="change"
                   @clear="clear">
            <!-- Options -->
            <el-option v-for="(defect, index) in defects"
                       :key="defect.id"
                       :label="defect.name"
                       :value="asKey ? defect.id : defect">
                <div class="f-x-between f-y-center">
                    <span>{{ (index + 1) + '. ' + defect.name }}</span>
                </div>
            </el-option>
        </el-select>
    </fieldable>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Fieldable from '@/components/fields/Fieldable'

export default {
  components: {
    Fieldable
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Object, default: null },
    items: { type: Array, default: null },
    name: { type: String, default: null },
    label: { type: String, default: 'Лот дефекта' },
    placeholder: { type: String, default: 'Выбрать' },
    disabled: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    required: { type: Boolean, default: false },

    independent: { type: Boolean, default: false },
    asKey: { type: Boolean, default: false }
  },
  data() {
    return {
      defects: [],
      searchQuery: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters('tasks', ['lotDefects', 'lotDefectsLoading'])
  },
  watch: {
    items(defects) {
      this.apply(defects)
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions('tasks', ['fetchLotDefects']),

    fetch() {
      this.loading = true

      is((this.items && Promise.resolve(this.items)) || this.fetchLotDefects({
        search: this.searchQuery
      }))
        .then(this.apply.bind(this))
        .finally(() => this.loading = false)
    },

    apply(defects) {
      this.defects = defects
    },

    search(query) {
      this.searchQuery = query
      this.fetch()
    },

    clear() {
      this.searchQuery = ''
      this.fetch()
      this.$emit('change', null)
    },
    change(value) {
      this.$emit('change', value)
    }
  }
}
</script>
