export default axios => ({
  getLotDefects: (payload) => axios.get('dirs/tasks/lot-defects', payload),
  getJobTypes: (payload) => axios.get('dirs/job-types', payload),
  getJobTypesTree: payload => axios.get('dirs/job-types/tree', payload),
  getUserTags: (payload) => axios.get('dirs/tags', payload),
  getFacadeStitchImageStatuses: () => axios.get('dirs/facades/stitch-image-statuses'),
  getFacadeImageTypes: () => axios.get('dirs/facades/image-types'),
  getFacadePointTypes: () => axios.get('dirs/facades/point-types'),
  getFacadeRecognitionStatuses: () => axios.get('dirs/facades/recognition-statuses'),
  getProjectDocumentTypes: () => axios.get('dirs/project-document-types'),
  getAutodeskSchedule: () => axios.get('dir/schedule'),
  getAstSchedule: () => axios.get('dir/ast/schedule'),

  /**
   * @see {@link https://gitlab.com/yks-osnova/smart-building/smart-building-server/-/blob/feature/TERRAWEB-423/docs/api/v1.md#get-apiv2dirsjob-types }
   */
  getJobsByProject: ({ project, withDeleted }) => axios.get('dirs/job-types', {
    params: {
      sort: 'code',
      filter: {
        project_id: project.project_id || project.id,
        with_trashed: withDeleted
      },
      page: {
        number: 1,
        size: 500
      }
    }
  }),

  /**
   * @see {@link https://gitlab.com/yks-osnova/smart-building/smart-building-server/-/blob/feature/TERRAWEB-423/docs/api/v1.md#get-apiv2dirsjob-types }
   */
  getJobsByPoint: ({ project, point, withDeleted }) => axios.get('dirs/job-types', {
    params: {
      sort: 'code',
      filter: {
        project_id: project.project_id || project.id,
        'planDefiningPoint.id': point.id,
        with_trashed: withDeleted
      },
      page: {
        number: 1,
        size: 500
      }
    }
  }),

  /**
   * @see {@link https://gitlab.com/yks-osnova/smart-building/smart-building-server/-/blob/feature/TERRAWEB-423/docs/api/v1.md#get-apiv2dirstags }
   */
  getTagsByProject: ({ project }) => axios.get('dirs/tags', {
    params: {
      filter: {
        project_id: project.project_id || project.id
      },
      sort: 'name',
      page: {
        number: 1,
        size: 500
      }
    }
  }),

  /**
   * @see {@link https://gitlab.com/yks-osnova/smart-building/smart-building-server/-/blob/feature/TERRAWEB-423/docs/api/v1.md#get-apiv2dirstags }
   */
  getTagsByPoint: ({ project, point }) => axios.get('dirs/tags', {
    params: {
      filter: {
        project_id: project.project_id || project.id,
        'planDefiningPoint.id': point.id
      },
      sort: 'name',
      page: {
        number: 1,
        size: 500
      }
    }
  }),

  getNotificationKinds: () => axios.get('dirs/notification-kinds').then(r => r.data.data || []),

  getWorkScheduleTypes: () => axios.get('dirs/gpr-types').then(r => r.data.data || []),

  getJobTypesAsTree: ({ project }) => axios.get('dirs/job-types/tree', {
    params: {
      filter: {
        project_id: project.id
      }
    }
  }),

  getJobTypesAsTreeForScaled: ({ project }) => axios.get('dirs/scaled-job-types/tree', {
    params: {
      filter: {
        project_id: project.id
      }
    }
  }),

  getJobType: id => axios.get(`dirs/job-types/${id}`),
  getJobTypeForScaled: id => axios.get(`dirs/scaled-job-types/${id}`),

  storeJobType: payload => axios.post('dirs/job-types', payload),
  storeJobTypeForScaled: payload => axios.post('dirs/scaled-job-types', payload),

  editJobType: (id, payload) => axios.put(`dirs/job-types/${id}`, payload),
  editJobTypeForScaled: (id, payload) => axios.put(`dirs/scaled-job-types/${id}`, payload),

  deleteJobType: id => axios.delete(`dirs/job-types/${id}`),
  deleteJobTypeForScaled: id => axios.delete(`dirs/scaled-job-types/${id}`),

  getGprStatuses: () => axios.get('dirs/gpr-statuses'),
  getGprStatusesForScaled: () => axios.get('dirs/scaled-gpr-statuses'),

  getLogActionTypes: () => axios.get('dirs/logs/action-types').then(r => r.data.data || []),

  getObjects: ({ filter, page, size }) => axios.get('admin/dirs/1c/objects', {
    params: {
      filter,
      sort: '-created_at',
      page: {
        number: page,
        size
      }
    }
  }),
  getObjectsForAccess: ({ filter }) => axios.get('contact/dirs/1c/objects', {
    params: {
      filter,
      page: {
        size: 0
      }
    }
  }),
  updateObject: x => axios.patch(`admin/dirs/1c/objects/${x?.id}`, x),
  createObject: x => axios.post('admin/dirs/1c/objects', x),
  removeObject: x => axios.delete(`admin/dirs/1c/objects/${x?.id}`)
})
