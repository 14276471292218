import Snowflakes from 'magic-snowflakes'

export const defineSomeMagic = () => {
  const snow = new Snowflakes({
    speed: 4
  });

  snow.start()
  snow.hide()

  let snowy = false

  const sprinkle = () => {
    snow.show()
    setTimeout(() => {
      snow.hide()
      snowy = false
    }, 2000)
    snowy = true
  }

  window['wantSomeMagic'] = () => process.env.VUE_APP_SOME_MAGIC === 'true' && !snowy && sprinkle()
}
