<template>
    <el-container v-loading="loading"
                  direction="vertical">
        <page-header v-if="task"
                     :page-title="pageTitle">
            <!-- Go back -->
            <template #button>
                <el-button @click="goToList">
                    <i class="el-icon-back" /> к списку
                </el-button>
            </template>

            <!-- Title for checking act -->
            <template v-if="isCheckingActType"
                      #title>
                <div>
                    <span class="text-size--small-title text-weight--500">{{
                        isRepeatedCheckingAct ? 'Вторичный акт проверки' : 'Первичный акт проверки'
                    }} </span>
                    <span class="text-size--small-title text-color--info">{{ pageTitle }}</span>
                </div>
            </template>
            
            <el-row type="flex"
                    align="middle"
                    :gutter="16">
                <template v-if="isCheckingActType && showCheckPointCounter">
                    <el-col class="span-auto">
                        <div class="text-color--info text-size--body-small">
                            Проверено
                            <span class="text-weight--700">
                                {{ getCheckedCheckpoints.checked }} /
                                {{ getCheckedCheckpoints.total }}
                            </span>
                        </div>
                    </el-col>
                    <el-col class="span-auto">
                        <el-button type="primary"
                                   :disabled="getCheckedCheckpoints.checked!==getCheckedCheckpoints.total"
                                   @click="handleClickCompleteCheckAction">
                            Закончить проверку
                        </el-button>
                    </el-col>
                </template>
                <el-col class="span-auto">
                    <el-dropdown @command="handleClickActions">
                        <el-button type="text">
                            <i class="el-icon-more"
                               style="transform: rotate(90deg)" />
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <template v-if="isCheckingActType &&!isRepeatedCheckingAct">
                                <el-dropdown-item :command="{ name: 'repeatTask' }">
                                    Создать вторичный акт проверки
                                </el-dropdown-item>
                            </template>
                            <template v-if="isBuildingOrderType || isCheckingActType">
                                <el-dropdown-item v-if="isActionAllowed(allowedActions,'send_to_mail')"
                                                  :command="{ name: 'sendEmail' }">
                                    Отправить на почту
                                </el-dropdown-item>
                                <el-row type="flex"
                                        justify="center"
                                        lign="middle">
                                    <el-divider direction="horizontal"
                                                class="my-0" />
                                    <el-col class="span-auto mx-2">
                                        <span class="text-size--supplementary text-color--info">Скачать</span>
                                    </el-col>
                                    <el-divider direction="horizontal"
                                                class="my-0" />
                                </el-row>
                                <el-dropdown-item v-if="task.data.checking_act_url"
                                                  :command="{
                                                      name: 'download',
                                                      payload: [{
                                                          title: 'Акт проверки',
                                                          url: task.data.checking_act_url,
                                                          preview_available: task.data.checking_act_doc_available_preview
                                                      }]
                                                  }">
                                    Акт проверки
                                </el-dropdown-item>
                                <el-dropdown-item v-if="task.data.building_order_doc_url"
                                                  :command="{
                                                      name: 'download',
                                                      payload: [{
                                                          title: 'Предписание',
                                                          url: task.data.building_order_doc_url,
                                                          preview_available: task.data.building_order_doc_available_preview,
                                                          updated: true,
                                                      }, {
                                                          title: 'Приложение к предписанию',
                                                          url: task.data.building_order_appendix_doc_url,
                                                          preview_available: task.data.building_order_appendix_available_preview
                                                      }]
                                                  }">
                                    Предписание
                                </el-dropdown-item>
                                <el-row type="flex"
                                        justify="center"
                                        align="middle">
                                    <el-divider direction="horizontal"
                                                class="my-0" />
                                    <el-col class="span-auto mx-2">
                                        <span class="text-size--supplementary text-color--info">Предпросмотр</span>
                                    </el-col>
                                    <el-divider direction="horizontal"
                                                class="my-0" />
                                </el-row>
                                <el-dropdown-item v-if="task.data.checking_act_url"
                                                  :command="{
                                                      name: 'preview',
                                                      payload: [{
                                                          title: 'Акт проверки',
                                                          url: task.data.checking_act_url,
                                                          preview_available: task.data.checking_act_doc_available_preview
                                                      }]
                                                  }">
                                    Акт проверки
                                </el-dropdown-item>
                                <el-dropdown-item v-if="task.data.building_order_doc_url"
                                                  :command="{
                                                      name: 'preview',
                                                      payload: [{
                                                          title: 'Предписание',
                                                          url: task.data.building_order_doc_url,
                                                          preview_available: task.data.building_order_doc_available_preview,
                                                          updated: true,
                                                      }, {
                                                          title: 'Приложение к предписанию',
                                                          url: task.data.building_order_appendix_doc_url,
                                                          preview_available: task.data.building_order_appendix_available_preview,
                                                      }]
                                                  }">
                                    Предписание
                                </el-dropdown-item>
                            </template>

                            <!-- Delete -->
                            <access :permissions="deletionPermissions">
                                <el-dropdown-item divided
                                                  :command="{name:'delete'}">
                                    <span class="text-color--danger"> Удалить </span>
                                </el-dropdown-item>
                            </access>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
            </el-row>
        </page-header>
        <el-main class="pr-0 py-0">
            <el-row v-if="task"
                    type="flex"
                    style="min-height: 100%">
                <el-col :span="18"
                        class="pr-4">
                    <el-tabs v-model="activeTabName"
                             @tab-click="handleClickTab">
                        <el-tab-pane v-if="isCheckingActType"
                                     label="Лист проверки"
                                     name="checking-act">
                            <InspectionList :check-list.sync="initGroups"
                                            :preloaded="isPreloaded"
                                            @template:updated="fetch" />
                            <el-row v-if="showTemplateActions"
                                    type="flex"
                                    class="my-4"
                                    justify="end">
                                <el-button type="primary"
                                           @click="handleClickSaveTemplate">
                                    Создать шаблон
                                </el-button>
                                <el-button type="primary"
                                           @click="handleClickStartChecking">
                                    Начать проверку
                                </el-button>
                            </el-row>
                        </el-tab-pane>

                        <!-- Common -->
                        <el-tab-pane label="Информация"
                                     name="project.task.info"
                                     class="_pb-1">
                            <task-type-defects-and-violations v-if="isDefectsType"
                                                              :task="task"
                                                              :editable-fields="editableFields"
                                                              :allowed-actions="allowedActions" />
                            <task-type-plain v-if="isPlainType"
                                             :task="task"
                                             :editable-fields="editableFields"
                                             :allowed-actions="allowedActions" />
                            <task-type-building-order v-if="isBuildingOrderType"
                                                      :task="task"
                                                      :editable-fields="editableFields"
                                                      :allowed-actions="allowedActions" />
                            <task-type-photo-order v-if="isPhotoOrderType"
                                                   :task="task"
                                                   :is-new-task="isNewTask"
                                                   :editable-fields="editableFields"
                                                   :allowed-actions="allowedActions" />
                            <task-type-checking-act v-if="isCheckingActType"
                                                    :allowed-actions="allowedActions" />
                            <task-type-acceptance-of-work v-if="isAcceptanceType"
                                                          :show-acceptance-edit-buttons="showAcceptanceEditButtons"
                                                          :allowed-actions="allowedActions" />
                        </el-tab-pane>

                        <!-- Photos -->
                        <el-tab-pane v-if="isAcceptanceType || isBuildingOrderType"
                                     name="project.task.photos">
                            <template slot="label">
                                <el-badge :value="taskPhotosCount"
                                          :hidden="!taskPhotosCount"
                                          class="task__tab-badge">
                                    {{ isAcceptanceType ? 'Фотографии' : 'Нарушения' }}
                                </el-badge>
                            </template>
                            <el-row type="flex">
                                <el-col>
                                    <task-photos v-if="activeTabName === 'project.task.photos'"
                                                 :task="task"
                                                 :editable="showAcceptanceEditButtons"
                                                 :with-checks="isAcceptanceType"
                                                 :can-create-defect="canCreateDefect"
                                                 :can-select-defect="canSelectDefect"
                                                 @apply-defects="applyDefects"
                                                 @remove-defect="removeDefect" />
                                </el-col>
                            </el-row>
                        </el-tab-pane>

                        <!-- Documents -->
                        <el-tab-pane label="Документы"
                                     name="project.task.documents">
                            <div slot="label">
                                <el-badge :value="filesCount"
                                          :hidden="!filesCount"
                                          class="task__tab-badge">
                                    Документы
                                </el-badge>
                            </div>
                            <el-row type="flex">
                                <el-col>
                                    <task-documents v-if="activeTabName === 'project.task.documents'"
                                                    :task="task" />
                                </el-col>
                            </el-row>
                        </el-tab-pane>

                        <!-- History -->
                        <el-tab-pane label="История"
                                     name="project.task.history">
                            <el-row type="flex">
                                <el-col>
                                    <task-history v-if="activeTabName === 'project.task.history'" />
                                </el-col>
                            </el-row>
                        </el-tab-pane>

                        <!-- Comments -->
                        <el-tab-pane label="Комментарии"
                                     name="project.task.comments">
                            <div slot="label">
                                <el-badge :value="taskMessages.length"
                                          :hidden="!taskMessages.length"
                                          class="task__tab-badge">
                                    Комментарии
                                </el-badge>
                            </div>
                            <el-row type="flex">
                                <el-col>
                                    <task-comments v-if="activeTabName === 'project.task.comments'" />
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </el-col>
                <el-col :span="7"
                        class="d-flex flex--grow">
                    <el-card shadow="always"
                             class="w-full border--none">
                        <task-description :task="task"
                                          :field-to-display="fieldToDisplay"
                                          :show-acceptance-edit-buttons="showAcceptanceEditButtons" />
                    </el-card>
                </el-col>
            </el-row>
        </el-main>
        <task-form v-if="task && task.type !== 'checking_act'"
                   @update="getTask" />
        <document-preview v-if="dialogVisible"
                          :url="docUrlForPreview"
                          :visible="dialogVisible"
                          :doc-template-id="docTemplateId"
                          :edited-item="editedItem || {}"
                          @close="dialogVisible = false; docUrlForPreview = ''" />
        <el-dialog title="Прогресс загрузки"
                   :visible.sync="progressDialogVisible"
                   width="30%"
                   :show-close="false"
                   center>
            <el-row type="flex"
                    align="middle"
                    justify="center">
                <el-col class="span-auto">
                    Загружено {{ progressCount }} / {{ totalCount }}
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog title="Дополнительные поля"
                   :visible.sync="fillingFieldsDialogVisible"
                   width="100%"
                   :show-close="true"
                   custom-class="filling-fields-dialog"
                   center>
            <el-row type="flex"
                    align="middle"
                    justify="center">
                <el-col>
                    <el-form ref="taskFillingFieldsForm"
                             size="mini" 
                             :model="editedItem">
                        <el-form-item v-for="(item, index) in fillingFieldsList" 
                                      :key="index" 
                                      :label="`${item.translate_field_name}`"
                                      :prop="`${item.field_name}`">
                            <el-input v-if="item.type === 'fill'" 
                                      v-model="editedItem[item.field_name]"
                                      :placeholder="`${item.translate_field_name}`"
                                      :value="item.prev_task_value || item.default_value || ''"
                                      type="text" />
   
                            <el-select v-if="item.type === 'choice'"
                                       v-model="editedItem[item.field_name]"
                                       clearable
                                       class="width-full"
                                       :placeholder="`${item.translate_field_name}`">
                                <el-option v-for="option in item.choice_values"
                                           :key="option"
                                           :label="option"
                                           :value="option" />
                            </el-select>
                        </el-form-item>
                        <el-form-item class="mt-4">
                            <el-row>
                                <el-col class="span-auto">
                                    <el-button type="primary"
                                               @click="submitForm('taskFillingFieldsForm')">
                                        Продолжить
                                    </el-button>
                                    <el-button @click="handleClose">
                                        Отменить
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </el-dialog>
    </el-container>
</template>
<script>
import PageHeader from '@/components/layout/PageHeader';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import TaskDocuments from '@/components/task/TaskDocuments';
import TaskComments from '@/components/task/TaskComments';
import TaskDescription from '@/components/task/TaskDescription';
import TaskHistory from '@/components/task/TaskHistory';
import TaskPhotos from '@/components/task/TaskPhotos';
import TaskTypeDefectsAndViolations from '@/components/task/TaskTypeDefectsAndViolations';
import TaskTypePlain from '@/components/task/TaskTypePlain';
import TaskTypeBuildingOrder from '@/components/task/TaskTypeBuildingOrder';
import TaskTypePhotoOrder from '@/components/task/TaskTypePhotoOrder';
import TaskTypeCheckingAct from '@/components/task/TaskTypeCheckingAct';
import TaskTypeAcceptanceOfWork from '@/components/task/TaskTypeAcceptanceOfWork';
import DocumentPreview from '@/components/task/DocumentPreview';
import {cloneDeep} from 'lodash';

import { resourceable } from '@/store/connectors'

import { hasBookedSchedule, getBookedScheduleLabel } from '@/models/tasks'
import { download } from '@/utils/browser'


export default {
  name: 'Task',
  components: {
    DocumentPreview,
    TaskTypeDefectsAndViolations,
    TaskTypePlain,
    TaskTypeBuildingOrder,
    TaskTypePhotoOrder,
    TaskTypeCheckingAct,
    TaskTypeAcceptanceOfWork,
    TaskDescription,
    TaskDocuments,
    TaskComments,
    TaskHistory,
    TaskPhotos,
    PageHeader,
    InspectionList: () => import('@/components/acts/InspectionList'),
    TaskForm: () => import('@/components/forms/TaskForm')
  },
  mixins: [
    resourceable({ on: 'tasks', name: 'editableFieldsWithValidation', mounted: ({ self }) => ({ id: self.$route.params.taskId }) })
  ],
  props: {
    activeTabProp: {
      type: String,
      default: function () {
        return 'project.task.info';
      }
    },
    isNewTask: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    taskInfo: null,
    clonedTask: null,
    templateType: 'new',
    docUrlForPreview: '',
    dialogVisible: false,
    activeTabName: 'project.task.info',
    loading: false,
    progressCount: 0,
    totalCount: 0,
    progressDialogVisible: false,
    fillingFieldsList: [],
    fillingFieldsDialogVisible: false,
    editedItem: {},
    docTemplateId: null,
    taskPayload: null,
    fillingFieldsDialogType: ''
  }),
  computed: {
    ...mapGetters({profile: 'auth/profile'}),
    ...mapState('tasks', {
      task: (state) => state.task,
      editableFields: (state) => state.editableFields,
      allowedActions: (state) => state.allowedActions
    }),
    ...mapState('checkingActs', ['checkingAct', 'stage', 'isPreloaded', 'template']),
    ...mapGetters('tasks', [
      'isBuildingOrderType',
      'isDefectsType',
      'isCheckingActType',
      'isPhotoOrderType',
      'isPlainType',
      'isAcceptanceType',
      'taskPhotosCount',
      'filesCount',
      'relatedTasks',
      'taskMessages'
    ]),
    ...mapGetters('checkingActs', ['getCheckedCheckpoints']),

    canCreateDefect() {
      return !!this.editableFields.includes('allow_add_new_defect_tasks')
    },

    canSelectDefect() {
      return !!this.editableFields.includes('defect_task_ids')
    },

    deletionPermissions() {
      return [
        this.isDefectsType && 'project_tasks_defects_delete'
          || this.isBuildingOrderType && 'project_tasks_building_orders_delete'
          || this.isAcceptanceType && 'project_tasks_acceptance_works_delete'
      ]
    },

    initGroups: {
      get() {
        return this.template;
      },
      set(value) {
        this.setTemplate(value)
      }
    },
    isRepeatedCheckingAct() {
      return this.getNested(this.task, 'data.repeated', null);
    },
    showCheckPointCounter() {
      return this.isCheckingActType && this.activeTabName === 'checking-act'
    },
    showTemplateActions() {
      return this.stage !== 2 && !this.isPreloaded
    },
    fieldToDisplay() {
      switch (true) {
      case this.isDefectsType:
        return ['type', 'createdAt', 'updatedAt', 'status', 'kindDefect', 'expiredAt', 'initiator', 'worker', 'generalContractor']
      case this.isBuildingOrderType:
        return ['type', 'createdAt', 'updatedAt', 'status', 'expiredAt', 'initiator', 'worker', 'manager', 'inspector']
      case this.isPhotoOrderType:
        return ['type', 'createdAt', 'updatedAt', 'status', 'expiredAt', 'initiator', 'worker', 'commentWorker']
      case this.isAcceptanceType:
        return [
          'type',
          'createdAt',
          'startAt',
          'updatedAt',
          'status',
          'initiator',
          'engineer',
          'contractor',
          'commentInitiator',
          'axis',
          'objects',
          'jobTypes',
          'commentWorker',
          'commentWorkerSecondStage',
          'commentContractor',
          'documents'
        ];
      case this.isCheckingActType:
        return ['type', 'createdAt', 'updatedAt', 'status', 'expiredAt', 'initiator'];
      default:
        return ['type', 'createdAt', 'updatedAt', 'status', 'expiredAt', 'initiator', 'worker']
      }
    },
    pageTitle() {
      if (this.isCheckingActType) {
        return `${this.task.project.name} / Дом №${this.task.data.house} / Корпус ${this.task.data.building} / Секция ${this.task.data.section}  / Этаж ${this.task.data.floor} / Кв. ${this.task.data.flat}  `;
      } else {
        return [
          `№${this.task.number_in_project}`,
          this.task.name || this.task.translated_type,
          hasBookedSchedule(this.task) && getBookedScheduleLabel(this.task, { prefix: 'в' })
        ].filter(x => x).join(' ')
      }
    },
    showAcceptanceEditButtons() {
      return !!this.editableFields.includes('polygons');
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.activeTabName = this.$route.name;
      }
    },
    '$route.params.taskId': {
      immediate: true,
      async handler() {
        try {
          const email = this.$route.query.email
          const query = email && { email } || {}

          await this.fetch(query);

          if (this.$route.query.action) {
            this.makeTaskAction({
              taskId: this.$route.params.taskId,
              payload: {
                action: this.$route.query.action,
                data: this.task
              }
            });
          }
          if (this.isCheckingActType) {
            this.activeTabName = 'checking-act'
          }
        } catch (e) {
          return e
        }
      }
    }
  },
  created() {
    this.activeTabName = this.$route.name;
  },
  async beforeDestroy() {
    await Promise.all([this.clearEditableFields(),
                       this.clearAllowedActions(), this.clearTask()])
  },
  methods: {
    ...mapActions('tasks', [
      'clearEditableFields',
      'clearAllowedActions',
      'makeTaskAction',
      'showTask',
      'storeTask',
      'updateTask',
      'startTask',
      'sendToRevisionTask',
      'finishTask',
      'completeTask',
      'clearTask',
      'getTaskEditableFields',
      'getTaskAllowedActions',
      'multipleDestroyTask',
      'getTaskFillingFields',
      'storeTaskFillingFields'
    ]),
    ...mapActions('dialogs/confirmation', ['confirm']),
    ...mapMutations({showDialog: 'dialog/SHOW_DIALOG'}),
    ...mapMutations({
      setStage: 'checkingActs/SET_STAGE',
      setIsPreloaded: 'checkingActs/SET_IS_PRELOADED',
      setIsEditState: 'checkingActs/SET_IS_EDIT_STATE',
      setTemplate: 'checkingActs/SET_CHECKING_ACT_TEMPLATE',
      showDialog: 'dialog/SHOW_DIALOG',
      setInitialState: 'checkingActs/SET_INITIAL_STATE'
    }),
    ...mapActions('checkingActs', ['setAct', 'createCheckingActTemplate', 'getCheckingActTemplate']),

    fetch(query) {
      return this.getTask(query) 
    },

    async submitForm () {
      this.fillingFieldsDialogVisible = false;

      if (this.fillingFieldsDialogType === 'preview') {
        this.docUrlForPreview = this.taskPayload;
        this.dialogVisible = true;
      }

      if (this.fillingFieldsDialogType === 'download') {
        const response = await this.storeTaskFillingFields({
          taskId: this.$route.params.taskId,
          docTemplateId: this.docTemplateId,
          payload: { data: this.editedItem, accept: 'application/msword' }
        });

        const url = URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}));

        const name = this.task.data.building_order_doc.name;
        const number = this.task.data.doc_number;
        download(url, {openable: true, name: name+' №'+number})

        setTimeout(() => {
          window.open(this.taskPayload[1].url + '?accept=application/msword')
          this.loading = false
        }, 3000)
      }
    },

    handleClose() {
      this.fillingFieldsDialogVisible = false;
      this.editedItem = {};
    },

    async applyDefects(tasks) {
      this.loading = true

      await this.updateTask({
        taskId: this.task.id,
        payload: {
          defect_task_ids: tasks.map(x => x.id)
        }
      })

      this.loading = false
    },

    async removeDefect(task) {
      this.loading = true

      await this.updateTask({
        taskId: this.task.id,
        payload: {
          defect_task_ids: this.relatedTasks.filter(x => x.id !== task.id).map(x => x.id)
        }
      })

      this.loading = false
    },

    isActionAllowed(actions, actionType) {
      if (!actions || !actionType) {
        return
      }
      return !!actions.find(action => action.name === actionType);
    },

    handleClickActions({
      name,
      payload = null
    }) {
      switch (name) {
      case 'sendEmail':
        this.handleSendEmail();
        break;
      case 'repeatTask':
        this.handleRepeatTask();
        break;
      case 'preview':
        this.showPreview(payload);
        break;
      case 'download':
        this.downloadFile(payload);
        break;
      case 'delete':
        this.handleDelete()
        break;
      default:
        return;
      }
    },

    async handleClickSaveTemplate() {
      try {
        const callback = async () => {
          this.showDialog({
            dialogName: 'checking-act-template-created',
            dialogTitle: 'Новый шаблон сохранён!',
            action: null,
            callback: null
          })
          await this.handleClickStartChecking();
        }
        this.showDialog({
          dialogName: 'checking-act-template',
          dialogTitle: 'Создание нового шаблона',
          action: this.createCheckingActTemplate,
          payload: this.initGroups,
          callback: callback
        });
      } catch (e) {
        console.log(e)
      }
    },
    async handleClickStartChecking() {
      try {
        this.loading = true;
        this.setAct(this.checkingAct)
        this.setStage(2);
        await this.createCheckingActTemplate({
          private: true,
          project_id: this.task.project_id,
          relation_task_id: this.task.id,
          groups: this.initGroups
        })
        await this.fetch();
      } finally {
        this.loading = false;
      }
    },

    async showPreview(payload) {
      const fieldsResponses = await this.setDataFillingFields();
      if (payload.filter(item => item.updated).length) {
        this.fillingFieldsDialogType = 'preview';
        this.taskPayload = payload;
        if(fieldsResponses.length) {
          this.fillingFieldsDialogVisible = true;
        } else {
          this.submitForm();
        }
      } else {
        this.docUrlForPreview = payload;
        this.dialogVisible = true;
      }
    },

    async setDataFillingFields() {
      const docTemplateId =this.task.data.building_order_doc.id;
      this.docTemplateId = docTemplateId;
      const fieldsResponses =  await this.handleGetTaskFillingFields(docTemplateId);
      this.fillingFieldsList = fieldsResponses;
      this.editedItem = fieldsResponses.reduce((obj, cur) => {
        return ({ ...obj, [cur.field_name]: cur.prev_task_value || cur.default_value || ''})
      }, {})
      return fieldsResponses
    },

    async handleGetTaskFillingFields(docTemplateId) {
      const {data} = await this.getTaskFillingFields({
        taskId: this.$route.params.taskId,
        docTemplateId
      })
      return data
    },

    async downloadFile(payload) {
      if (payload.filter(item => item.updated).length) {
        const fieldsResponses = await this.setDataFillingFields();
        this.taskPayload = payload;
        this.fillingFieldsDialogType = 'download';
        if (fieldsResponses.length) {
          this.fillingFieldsDialogVisible = true;
        } else {
          this.submitForm();
        }
        return false;
      } 

      payload.forEach((item, index) => {
        if(!index) {
          window.open(item.url + '?accept=application/msword')
        } else {
          this.loading = true;
          setTimeout(() => {
            window.open(item.url + '?accept=application/msword')
            this.loading = false
          }, 3000)
        }
      })
    },

    async handleClickCompleteCheckAction() {
      try {
        this.loading = true
        let preparedCheckList = this.prepareCheckPointList(this.initGroups);
        let {
          attachFileList,
          detachFileList
        } = this.prepareFilesToSend(this.initGroups);
        await this.makeTaskAction({
          taskId: this.$route.params.taskId,
          payload: {
            action: 'complete_check_action',
            data: {values: preparedCheckList}
          }
        })
        if (attachFileList.length) {
          await this.sendingAttachedFiles(attachFileList);
        }
        if (detachFileList.length) {
          await this.sendingDetachFiles(detachFileList)
        }
        this.fetch()
        this.$message({
          type: 'success',
          message: 'Изменения успешно сохранены'
        })
      } finally {
        this.loading = false;
      }
    },
    async sendingAttachedFiles(fileList) {
      try {
        this.progressDialogVisible = true;
        await Promise.all(fileList);
      } finally {
        this.clearProgressDialog();
      }
    },
    async sendingDetachFiles(fileList) {
      try {
        await this.$api.checkingActs.detach({
          taskId: this.$route.params.taskId,
          payload: {check_point_ids: fileList}
        })
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'При отправке запроса на сервер произошла ошибка'
        })
      }
    },
    clearProgressDialog() {
      this.progressDialogVisible = false;
      setTimeout(() => {
        this.totalCount = 0;
        this.progressCount = 0;
      }, 1000)
    },
    prepareCheckPointList(list) {
      let array = []
      list.forEach(group => {
        let mappedArray = group.check_points.map(checkPoint => ({
          check_point_id: checkPoint.id,
          ready: checkPoint.value.ready,
          comment: checkPoint.value.comment
        }))
        array.push(...mappedArray);
      })
      return array;
    },
    prepareFilesToSend(list) {
      let listOfExistingFiles = []
      this.clonedTask.data.checking_act_template.check_point_groups.forEach(group => {
        let mappedArray = group.check_points.map(checkPoint => {
          if (checkPoint.value.image) {
            return checkPoint.id
          }
        })
        listOfExistingFiles.push(...mappedArray.filter(item => typeof item !== 'undefined'));
      })
      let attachFileList = [];
      let detachFileList = [];
      list.forEach(group => {
        group.check_points.forEach(checkPoint => {
          if (listOfExistingFiles.indexOf(checkPoint.id) !== -1 && checkPoint.value.image === null) {
            detachFileList.push(checkPoint.id);
          } else if (checkPoint.value.image && !checkPoint.value.image.id) {
            let formData = new FormData();
            formData.append('image', checkPoint.value.image.raw);
            formData.append('check_point_id', checkPoint.id);
            attachFileList.push(
              async () => {
                this.$api.checkingActs.attach(this.task.id, formData).then(() => {
                  this.progressCount++;
                })
              }
            )
          } else {
            return
          }
        })
      })
      this.totalCount = attachFileList.length;
      return {
        attachFileList,
        detachFileList
      };
    },
    handleSendEmail() {
      this.showDialog({
        dialogName: 'send-mail-dialog',
        dialogTitle: 'Отправить на почту',
        dialogSubtitle: 'Заполните форму, чтобы создать акт проверки',
        action: this.sendEmail
      });
    },

    async handleRepeatTask() {
      const callback = (taskId) => {
        this.$router.push({
          name: 'management.checking.acts.id',
          params: {taskId: taskId}
        })
      }
      this.showDialog({
        dialogName: 'checking-act-repeat',
        dialogTitle: 'Вторичный акт проверки',
        action: this.storeTask,
        callback,
        payload: {checking_act_task_id: this.$route.params.taskId}
      });
    },
    async sendEmail(data) {
      try {
        this.loading = true;
        this.makeTaskAction({
          taskId: this.$route.params.taskId,
          payload: {
            action: 'send_to_mail',
            data: data
          }
        })
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'При отправке произошла ошибка'
        })
      } finally {
        this.loading = false;
      }
    },

    getEditableFields() {
      this.getTaskEditableFields({taskId: this.$route.params.taskId});
    },

    getAllowedActions() {
      this.getTaskAllowedActions({taskId: this.$route.params.taskId});
    },

    handleDelete() {
      let callback = () => {
        this.multipleDestroyTask({params: {ids: [this.$route.params.taskId]}})
          .then(() => {
            this.goToList({ fetchable: true })

            this.$message({
              type: 'success',
              message: 'Задача успешно удалена'
            });
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'При удалении произошла ошибка'
            });
          });
      };
      this.confirm({
        message: 'Вы уверены, что хотите удалить задачу?',
        callback
      });
    },

    async getTask(query) {
      try {
        this.loading = true;
        let response = await this.showTask({
          taskId: this.$route.params.taskId,
          payload: {
            params: {
              ...query,
              include: [
                'project',
                'creator',
                'children',
                'members.user.organization',
                'memberInitiator.user.organization',
                'memberInspectors.user.organization',
                'memberWorkers.user.organization',
                'defectGeneralContractor.organization',
                'acceptanceOfWorkContractor.organization',
                'objectsBuildingOrderConnection.object',
                'objectsAcceptanceOfWorkConnection.object',
                'subjectsBuildingOrderConnection.subject',
                'subjectsAcceptanceOfWorkConnection.subject',
                'linkedDefectProjectObjects'
              ],
              append: ['checking_act_template_with_values', 'files_count']
            }
          }
        });
        this.taskInfo = response.data;
        this.clonedTask = cloneDeep(response.data)
        let checkingActTemplate = this.getNested(response.data, 'data.checking_act_template', null);
        if (checkingActTemplate) {
          this.setIsPreloaded(true);
          this.setStage(2);
          this.setTemplate(checkingActTemplate.check_point_groups);
        } else {
          this.setIsPreloaded(false);
          this.setStage(1);
          this.setTemplate(this.initGroups)
        }
      } catch (e) {
        this.$notify({
          title: 'Система',
          type: 'info',
          message: 'Произошла ошибка'
        });
      } finally {
        this.loading = false;
      }
    },
    handleClickTab() {
      this.$router.push({
        name: this.activeTabName
      })
    },
    goToList ({ fetchable = false } = {}) {
      const name = ({
        'defects_and_violations': 'project.tasks.defects',
        'building_order': 'project.tasks.building.order',
        'photo_order': 'project.tasks.photo.order',
        'acceptance_of_work': 'project.tasks.acceptance'
      })[this.task.type]

      this.$router.push({ 
        name, 
        params: {
          type: this.task.type,
          fetchable
        } 
      })
    }
  }
};
</script>
<style lang="scss">
.task__tab-badge {
  .item {
    margin-top: 10px;
    margin-right: 40px;
  }

  position: relative;

  .el-badge__content {
    position: absolute;
    transform: scale(0.8) translateY(-50%);

    &.is-fixed {
      top: 25%;
      right: auto;
      transform: scale(0.8) translateY(-25%);

      &.is-dot {
        right: auto;
      }
    }
  }
}
.filling-fields-dialog {
  max-width: 420px;
}
</style>
