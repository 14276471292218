import Polygon from 'ol/geom/Polygon'
import Feature from 'ol/Feature'

import { FEATURE_TYPES } from '../../utils/plan'
import { difference, intersection, intersectionWithZip, unique } from '../../utils/immutable';

import { getPolygons, toPolygon } from '../../models/tasks';
import { isAcceptance, filterFeatureOfTask, isWorkPolygon } from '../../models/shapes';
import {isWorkLayerConfirmed} from '../../models/plans';

import jack from '../../utils/graphics/OpenLayersJack'

export default ({ planId: x, map, source }) => {
  let planId = x
  let task
  let polygons, taskPolygons, workPolygons = []
  let taskPolygonsById = {}
  let width, height
  let offsetWidth, offsetHeight

  return {
    applyBackground({ width: w, height: h, offsetWidth: ow, offsetHeight: oh }) {
      jack
        .by({ map, source })
        .toFeatures()
        .forEach(feature => jack.by({ source, feature }).move(ow - offsetWidth, oh - offsetHeight))

      width = w
      height = h
      offsetWidth = ow
      offsetHeight = oh
    },

    applyTask(x) {
      task = x

      // Get polygons from task
      taskPolygons = getPolygons(task)

      // Generate map for access to tasks polygons by id
      taskPolygonsById = taskPolygons.reduce((r, x) => ({ ...r, [x.id]: x }), {})

      // Build features for polygons
      const features = taskPolygons
        .filter(({ position }) => position?.floorPlan?.id === planId)
        .map(({ id, type, vertices, createdAt }) => new Feature({
          geometry: new Polygon([vertices.map(([x, y]) => [x + offsetWidth, y + offsetHeight])]),
          id,
          acceptanceType: type,
          type: ({
            'finish': FEATURE_TYPES.FINAL_ACCEPTANCE,
            'temp': FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE
          })[type],
          createdAt,
          task
        }))

      // Remove previous another (not for current acceptance) polygons
      jack
        .by({ map, source })
        .filter(isAcceptance)
        .filter(filterFeatureOfTask(task))
        .forEach(feature => jack.by({ source, feature }).remove())
      
      // Add polygons
      source.addFeatures(features)
    },

    applyTaskPolygons(x) {
      polygons = x

      const polygonsOfAnotherTasks = difference(polygons, taskPolygons, (a, b) => a.id === b.id)

      const features = polygonsOfAnotherTasks.map(({ id, type, vertices, createdAt }) => new Feature({
        geometry: new Polygon([vertices.map(([x, y]) => [x + offsetWidth, y + offsetHeight])]),
        id,
        acceptanceType: type,
        type: ({
          'finish': FEATURE_TYPES.FINAL_ACCEPTANCE_OLD,
          'temp': FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE_OLD
        })[type],
        createdAt
      }))

      const isNotTaskFeature = feature => feature?.get('task')?.id !== task?.id

      jack
        .by({ map, source })
        .filter(isAcceptance)
        .filter(isNotTaskFeature)
        .forEach(feature => jack.by({ source, feature }).remove())

      features.map(
        feature => jack.by({ source, feature })
          .draw()
      )
    },

    applyWorkPolygons(x, { planId: y, layer, comparable } = {}) {
      const planChanged = planId !== y && !!(planId = y)

      const polygons = workPolygons = x

      const exists = jack
        .by({ map, source })
        .filter(isWorkPolygon)

      const creatable = planChanged 
        ? polygons 
        : difference(polygons, exists, (a, b) => a.id === b.get('id'))

      const updatable = planChanged 
        ? [] 
        : intersectionWithZip(polygons, exists, (a, b) => a._updated && a.id === b.get('id') && a._updated !== b.get('polygon')._updated)

      const removable = planChanged 
        ? exists 
        : difference(exists, polygons, (a, b) => a.get('id') === b.id)

      const toFeatureType = polygon => (!comparable && isWorkLayerConfirmed(layer) && polygon?.data?.work_status) 
        ? ({
          'created': FEATURE_TYPES.WORK_POLYGON_CREATED,
          'at_work': FEATURE_TYPES.WORK_POLYGON_AT_WORK,
          'completed': FEATURE_TYPES.WORK_POLYGON_COMPLETED,
          'completed_partially': FEATURE_TYPES.WORK_POLYGON_COMPLETED_PARTIALLY,
          'with_problem': FEATURE_TYPES.WORK_POLYGON_WITH_PROBLEM,
          'with_warning': FEATURE_TYPES.WORK_POLYGON_WITH_WARNING
        })[polygon?.data?.work_status] 
        : ({
          'new': FEATURE_TYPES.WORK_POLYGON_NEW,
          'changed': FEATURE_TYPES.WORK_POLYGON_CHANGED,
          'unchanged': FEATURE_TYPES.WORK_POLYGON_UNCHANGED
        })[polygon?.status]

      creatable
        .map(polygon => ({
          ...polygon,
          polygon
        }))
        .map(({ id, marks, polygon }) => new Feature({
          id,
          geometry: new Polygon([marks.map(([x, y]) => [x * width - offsetWidth, (1 - y) * height - offsetHeight])]),
          type: toFeatureType(polygon),
          polygon
        }))
        .forEach(feature => jack.by({ source, feature }).draw())

      updatable
        .forEach(([polygon, feature]) => {
          feature.set('polygon', polygon)
          feature.set('type', toFeatureType(polygon))
        })

      removable
        .forEach(feature => jack.by({ source, feature }).remove())

      // const features = jack.by({ source }).toFeatures()
      // console.log({ id, planChanged, polygons: x, exists, removable, creatable, features })
      // Слав, я закомментил

      return {
        shouldDeselect: !!removable.length,
        shouldReselect: !!updatable.length
      }
    },

    clearWorkPolygons() {
      jack
        .by({ map, source })
        .filter(isWorkPolygon)
        .forEach(feature => jack.by({ source, feature }).remove())
    },

    getTaskPolygons() {
      return taskPolygons
    },

    getTaskPolygonsById() {
      return taskPolygonsById
    },

    getPolygonsForStore({ planId }) {
      console.assert(task, 'Undefined task')

      const features = source
        .getFeatures()
        .filter(isAcceptance)

      const polygonsForCreate = features
        .filter(feature => !feature.get('id'))
        .map(feature => toPolygon({
          id: null,
          planId,
          type: feature.get('acceptanceType'),
          vertices: feature.getGeometry().getCoordinates()[0].map(([x, y]) => [x - offsetWidth, y - offsetHeight])
        })) 

      const polygonsFromEditor = features
        .map(feature => toPolygon({
          id: feature.get('id') || null,
          planId,
          type: feature.get('acceptanceType'),
          vertices: feature.getGeometry().getCoordinates()[0]
        }))

      const polygonsFromEditorByTask = features
        .filter(feature => taskPolygonsById[feature.get('id')])
        .map(feature => toPolygon({
          id: feature.get('id') || null,
          planId,
          type: feature.get('acceptanceType'),
          vertices: feature.getGeometry().getCoordinates()[0]
        }))

      const polygonsFromTaskByNotPlan = taskPolygons
        .filter(({ position }) => position.floorPlan?.id !== planId)
        .map(({ id, type, vertices, position }) => toPolygon({
          id, 
          planId: position.floorPlan?.id, 
          type, 
          vertices
        }))

      const polygonsFromTaskByPlan = taskPolygons
        .filter(({ position }) => position.floorPlan?.id === planId)
        .map(({ id, type, vertices, position }) => toPolygon({
          id, 
          planId: position.floorPlan?.id, 
          type, 
          vertices
        })) 
  
      const polygonsFromTaskByPlanAndEditor = intersection(polygonsFromEditor, polygonsFromTaskByPlan, (a, b) => a.id && b.id ? a.id === b.id : false)

      const polygons = unique([
        ...polygonsForCreate, 
        ...polygonsFromEditorByTask, 
        ...polygonsFromTaskByPlanAndEditor, 
        ...polygonsFromTaskByNotPlan
      ], (a, b) => a.id && b.id ? a.id === b.id : false)

      polygons.map(polygon => ({
        ...polygon,
        marks: polygon.marks.map(([x, y]) => [x - offsetWidth, y - offsetHeight])
      }))

      return polygons
    }
  }
}
