export default axios => ({
  storeImages: ({ type, images }) => {
    const data = images.reduce((r, x, i) => {
      r.append(`images[${i}][image]`, x)
      r.append(`images[${i}][type]`, type)

      return r
    }, new FormData())

    return axios.post('task-images', data)
  },

  storeDefectImage: ({ image }) => {
    const data = new FormData()

    data.append('image', image)

    return axios.post('tasks-defect-image', data)
  },

  addDefectMark: ({ task, photoId, yolo }) => axios.post(`tasks/${task?.id}/add-defect-mark`, {
    defining_point_image_id: photoId,
    yolo
  }),

  storeTaskImage: (taskId, payload) => axios.post(`tasks/${taskId}/images`, payload),
  getTaskImage: (taskId, taskImageId) => axios.get(`tasks/${taskId}/images/${taskImageId}`),
  getTaskFillingFields: (taskId, docTemplateId) => axios.get(`tasks/${taskId}/docs/${docTemplateId}/filling-fields`),
  storeTaskFillingFields: (taskId, docTemplateId, payload) => axios.post(`tasks/${taskId}/docs/${docTemplateId}`, payload, { responseType: 'blob' }),
  deleteTaskImage: (taskId, taskImageId) => axios.delete(`tasks/${taskId}/images/${taskImageId}`),
  getDefectKinds: () => axios.get('tasks/kinds-defects'),
  getGprTasksCount: (projectId, payload) => axios.get(`projects/${projectId}/gpr/tasks-count`, payload),

  getTasksCount: params => axios.get('tasks/count', { params }),
  getTasksCountByOrganization: params => axios.get('tasks/count-by-organizations', { params }),
  getTasksCountByUsers: params => axios.get('tasks/count-by-users', { params }),
  getTasksCountInTime: params => axios.get('tasks/count-in-time', { params })
});
