import { FEATURE_TYPES } from '@/utils/plan'

export const isPoint = feature => feature && [
  FEATURE_TYPES.CAMERA360, 
  FEATURE_TYPES.QUADCOPTER, 
  FEATURE_TYPES.FACADE, 
  FEATURE_TYPES.DEFECT
].includes(feature.get('type'))

export const isAcceptance = feature => feature && [
  FEATURE_TYPES.FINAL_ACCEPTANCE,
  FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE,
  FEATURE_TYPES.FINAL_ACCEPTANCE_OLD,
  FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE_OLD
].includes(feature.get('type'))

export const isWorkPolygon = feature => feature && feature.get('polygon') && [
  FEATURE_TYPES.WORK_POLYGON_NEW,
  FEATURE_TYPES.WORK_POLYGON_CHANGED,
  FEATURE_TYPES.WORK_POLYGON_UNCHANGED,
  FEATURE_TYPES.WORK_POLYGON_CREATED,
  FEATURE_TYPES.WORK_POLYGON_AT_WORK,
  FEATURE_TYPES.WORK_POLYGON_COMPLETED,
  FEATURE_TYPES.WORK_POLYGON_COMPLETED_PARTIALLY,
  FEATURE_TYPES.WORK_POLYGON_WITH_PROBLEM,
  FEATURE_TYPES.WORK_POLYGON_WITH_WARNING
].includes(feature.get('type'))

export const getWorkPolygonColorByFeature = feature => feature && {
  [FEATURE_TYPES.WORK_POLYGON_NEW]: 'red-pavel-1',
  [FEATURE_TYPES.WORK_POLYGON_CHANGED]: 'orange-pavel-1',
  [FEATURE_TYPES.WORK_POLYGON_UNCHANGED]: 'green-2',
  [FEATURE_TYPES.WORK_POLYGON_CREATED]: 'blue-pavel-2',
  [FEATURE_TYPES.WORK_POLYGON_AT_WORK]: 'iris-pavel-1',
  [FEATURE_TYPES.WORK_POLYGON_COMPLETED]: 'green-pavel-3',
  [FEATURE_TYPES.WORK_POLYGON_COMPLETED_PARTIALLY]: 'orange-pavel-3',
  [FEATURE_TYPES.WORK_POLYGON_WITH_PROBLEM]: 'red-pavel-2',
  [FEATURE_TYPES.WORK_POLYGON_WITH_WARNING]: 'purple-pavel-2'
}[feature.get('type')] || 'blue-pavel-1'

export const getWorkPolygonFeatureTypes = () => [
  FEATURE_TYPES.WORK_POLYGON_NEW,
  FEATURE_TYPES.WORK_POLYGON_CHANGED,
  FEATURE_TYPES.WORK_POLYGON_UNCHANGED,
  FEATURE_TYPES.WORK_POLYGON_CREATED,
  FEATURE_TYPES.WORK_POLYGON_AT_WORK,
  FEATURE_TYPES.WORK_POLYGON_COMPLETED,
  FEATURE_TYPES.WORK_POLYGON_COMPLETED_PARTIALLY,
  FEATURE_TYPES.WORK_POLYGON_WITH_PROBLEM,
  FEATURE_TYPES.WORK_POLYGON_WITH_WARNING
]

export const filterFeatureOfTask = task => feature => feature?.get('task')?.id === task.id
