<template>
    <div class="rows-minmax-min">
        <!-- Table -->
        <el-table :data="tasks"
                  class="with-scrolled-body"
                  row-class-name="cursor-pointer"
                  @sort-change="sort"
                  @row-click="clickTask">
            <!-- Number -->
            <el-table-column :label="labels.number"
                             prop="number_in_project"
                             width="100"
                             sortable="custom">
                <template #default="{ row }">
                    <el-tag type="info">
                        {{ getNumberWithPrefix(row) }}
                    </el-tag>
                </template>
            </el-table-column>

            <!-- Type -->
            <el-table-column :label="labels.type"
                             prop="type"
                             width="190"
                             sortable="custom">
                <template #default="{ row }">
                    <el-tag type="info">
                        <div class="f-y-center space-x-0.25">
                            <icon v-if="iconByTaskType(row.type)"
                                  :as="iconByTaskType(row.type)"
                                  class="wh-0.9" />
                            <p class="_m-0">
                                {{ row.translated_type }}
                            </p>
                        </div>
                    </el-tag>
                </template>
            </el-table-column>

            <!-- Name -->
            <el-table-column :label="labels.name"
                             prop="name"
                             sortable="custom" />

            <!-- Status -->
            <el-table-column :label="labels.status"
                             width="210"
                             prop="state"
                             sortable="custom">
                <template #default="{ row }">
                    <task-status :task="row" />
                </template>
            </el-table-column>

            <!-- Created at -->
            <el-table-column :label="labels.createdAt"
                             prop="created_at"
                             sortable="custom">
                <template #default="{ row }">
                    {{ labelAt(row.created_at, { iso: true, withCapital: true, zoned: false }) }}
                </template>
            </el-table-column>

            <!-- Initiator -->
            <el-table-column :label="labels.initiator">
                <template #default="{ row }">
                    <user-thumb :user="getInitiatorUser(row)"
                                align="center"
                                compact
                                with-avatar />
                </template>
            </el-table-column>

            <!-- Worker -->
            <el-table-column :label="labels.worker">
                <template #default="{ row }">
                    <user-thumb :user="getWorkerOrAnotherUser(row)"
                                align="center"
                                compact
                                with-avatar />
                </template>
            </el-table-column>
        </el-table>

        <!-- Pagination -->
        <div class="f-v-center h-3 border-top-1 border-gray-200">
            <el-pagination background
                           :current-page="pagination.page"
                           :page-size="pagination.size"
                           :page-sizes="[60, 120, 180, 240]"
                           :total="pagination.total"
                           layout="prev, pager, next, sizes"
                           @size-change="resize"
                           @current-change="paginate" />
        </div>
    </div>
</template>

<script>
import { labelAt } from '@/utils/date'

import { types, getInitiatorUser, getWorkerOrAnotherUser, getNumberWithPrefix } from '@/models/tasks'

import TaskStatus from '@/components/tasks/TaskStatus'
import UserThumb from '@/components/users/UserThumb'

import DefectIcon from '@/components/icons/menu/DefectIcon'
import BuildingOrderIcon from '@/components/icons/menu/BuildingOrderIcon'
import AcceptanceIcon from '@/components/icons/menu/AcceptanceIcon'
import PhotoOrderIcon from '@/components/icons/menu/PhotoOrderIcon'
import ApprovalIcon from '@/components/icons/menu/ApprovalIcon'

const labels = {
  number: 'Номер',
  type: 'Тип',
  name: 'Задача',
  status: 'Статус',
  createdAt: 'Создана',
  initiator: 'Инициатор',
  worker: 'Исполнитель'
}

export default {
  components: {
    TaskStatus,
    UserThumb
  },
  props: {
    tasks: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => null }
  },
  data() {
    return {
      labels,

      labelAt,
      getInitiatorUser,
      getWorkerOrAnotherUser,
      getNumberWithPrefix
    }
  },
  methods: {
    iconByTaskType(type) {
      return ({
        [types.DEFECTS_AND_VIOLATIONS]: DefectIcon,
        [types.BUILDING_ORDER]: BuildingOrderIcon,
        [types.ACCEPTANCE_OF_WORK]: AcceptanceIcon,
        [types.PHOTO_ORDER]: PhotoOrderIcon,
        [types.APPROVAL_PROCESS]: ApprovalIcon
      })[type]
    },

    clickTask(task) {
      this.$emit('task-click', task)
    },

    resize(size) {
      this.$emit('resize', size)
    },

    paginate(page) {
      this.$emit('paginate', page)
    },

    sort({ prop, order }) {
      this.$emit('sort', { key: prop, order })
    }
  }
}
</script>
