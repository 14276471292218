<template>
    <div>
        <!-- Common -->
        <div>
            <our-label :value="displayed.status"
                       class="lh-1.5" />
            <our-label :value="displayed.createdAt"
                       class="lh-1.5"
                       tertiary />
        </div>

        <!-- Creator -->
        <user-thumb :user="displayed.creator"
                    with-organization
                    with-avatar />

        <!-- Actions -->
        <div class="f-x-end f-y-center">
            <!-- Download -->
            <tooltip :text="labels.aboutDownload">
                <is-button icon="download"
                           :disabled="!getResult(value)"
                           @click="download" />
            </tooltip>

            <!-- Info -->
            <tooltip>
                <is-button icon="info" />

                <template slot="value">
                    <div class="w-16 space-v-0.5">
                        <!-- Message -->
                        <our-label v-if="getMessage"
                                   :value="getMessage"
                                   tertiary
                                   dark />

                        <!-- Title -->
                        <our-label :value="labels.aboutFilters"
                                   tertiary
                                   dark />

                        <!-- Filters -->
                        <div v-for="filter in value._translatedFilters"
                             :key="filter.id"
                             class="f-x-between">
                            <our-label :value="filter.name"
                                       class="_pr-0.25"
                                       tertiary
                                       dark />
                            <our-label v-if="filter.value"
                                       :value="filter.value"
                                       align="end"
                                       dark />
                        </div>
                    </div>
                </template>
            </tooltip>

            <!-- Delete -->
            <tooltip :text="labels.aboutDelete">
                <is-button icon="cross"
                           @click="remove" />
            </tooltip>
        </div>
    </div>
</template>

<script>
import { getTranslatedStatus, getCreatedAtLabel, getCreator, getResult } from '@/models/services'

import UserThumb from '@/components/users/UserThumb'

const labels = {
  aboutDownload: 'Скачать',
  aboutDelete: 'Удалить',
  aboutFilters: 'Экспортировано по фильтру:' 
}

export default {
  components: {
    UserThumb
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      getResult,

      labels
    }
  },
  computed: {
    displayed() {
      return {
        status: getTranslatedStatus(this.value),
        createdAt: getCreatedAtLabel(this.value),
        creator: getCreator(this.value)
      }
    },

    hasResult() {
      return !!getResult(this.value)
    },

    getMessage() {
      return this.value.data?.message
    }
  },
  methods: {
    download() {
      this.$emit('download', this.value)
    },

    remove() {
      this.$emit('remove', this.value)
    }
  }
}
</script>
