import intersect  from '@turf/intersect'
import area from '@turf/area'
import * as helpers from '@turf/helpers'

export const defineNeedForTranslate = (firstСoordinates, secondСoordinates) => {
  const firstPolygon = firstСoordinates && helpers.polygon(firstСoordinates)
  const secondPolygon = secondСoordinates && helpers.polygon(secondСoordinates)

  const intersection = intersect(firstPolygon, secondPolygon)

  const toTranslate = () => {
    const intersectionArea = area(intersection)

    const firstPolygonArea = area(firstPolygon)
    const secondPolygonArea = area(secondPolygon)

    const firstPolygonProportion = (intersectionArea / firstPolygonArea)
    const secondPolygonProportion = (intersectionArea / secondPolygonArea)

    return firstPolygonProportion > 0.8 && secondPolygonProportion > 0.8
  }

  return intersection && toTranslate() || false
}