import { lineLength } from 'geometric'
import { FEATURE_TYPES } from '@/utils/plan'
import { key } from '@/utils/common'
import { intersection, difference, union, sortAsName, values } from '@/utils/immutable'
import { loadable, resourceable, actionable } from '@/store/mixins'
import {state} from '../../models/tasks'

const uniqueItems = ({ points, itemsByPoint }) => points
  // Union items by points
  .reduce((result, { id }) => [...result, ...itemsByPoint[id] || []], [])
  // Union unique items by id
  .reduce((result, each) => ({ ...result, [each.id]: each }), { values })
  // Translate to array
  .values()

const intersectedItems = ({ points, itemsByPoint }) => points
  // Select items by points
  .map(({ id }) => itemsByPoint[id]).filter(x => x)
  // Inersect items
  .reduce((result, each, _, source) => intersection(result.length ? result : source[0], each, (a, b) => a.name === b.name), [])

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'point',
      from: ({ api, payload: { id } }) => api.points.getPoint(id)
    }),

    actionable({
      name: 'applyPlanPoints',
      loadable: true,
      at: ({ api }, { plan, pointsForCreate, pointsForDelete, pointsForUpdate }) =>
        api.definingPoints.applyPlanPoints({
          plan,
          pointsForCreate,
          pointsForDelete,
          pointsForUpdate
        })
    }),

    actionable({
      name: 'applyRoomPoints',
      loadable: true,
      at: ({ api }, { room, pointsForCreate, pointsForDelete, pointsForUpdate }) =>
        api.definingPoints.applyRoomPoints({
          room,
          pointsForCreate,
          pointsForDelete,
          pointsForUpdate
        })
    }),

    actionable({
      name: 'clearViewedPoint',
      at: ({ state }) => state.viewedPoint = null
    }),

    actionable({
      name: 'clearViewedPoints',
      at: ({ state }) => state.viewedPoints = []
    }),

    loadable({ action: 'fetchPointTags' }),
    loadable({ action: 'fetchPointJobs' }),
    loadable({ action: 'saveTags' }),
    loadable({ action: 'saveJobs' })
  ],
  state: {
    viewedPoints: [],
    viewedPoint: null,

    nearestOrigin: null,
    nearestPoints: [],

    tagsByPoint: {},
    jobsByPoint: {},

    uniquePointsTags: [],
    uniquePointsJobs: [],

    intersectedPointsTags: [],
    intersectedPointsJobs: [],

    planDelta: 1
  },
  getters: {
    viewedPoints: state => state.viewedPoints,
    viewedPoint: state => state.viewedPoint,
    nearestOrigin: state => state.nearestOrigin,
    nearestPoints: state => state.nearestPoints,

    pointTags: state => point => state.tagsByPoint[point.id] || [],
    pointJobs: state => point => state.jobsByPoint[point.id] || [],

    groupedTags: (_a, _b, _c, rootGetters) => {
      const sourceTags = rootGetters['dirsRevision/tags']
      const uniqueTags = rootGetters['points/uniquePointsTags']
      
      const result = [{
        name: 'Использованные',
        key: key(),
        children: uniqueTags
      }, {
        name: 'Все',
        key: key(),
        children: difference(sourceTags, uniqueTags, (a, b) => a.name === b.name)
      }] 

      return result
    },

    groupedJobs: (_a, _b, _c, rootGetters) => {
      const sourceJobs = rootGetters['dirsRevision/jobs']
      const uniqueJobs = rootGetters['points/uniquePointsJobs']

      const result = [{
        name: 'Использованные',
        key: key(),
        children: uniqueJobs.map(x => ({ ...x, removed: x.is_deleted }))
      }, {
        name: 'Все',
        key: key(),
        children: difference(sourceJobs, uniqueJobs, (a, b) => a.name === b.name).map(x => ({ ...x, deleted: x.is_deleted }))
      }]

      return result
    },

    uniquePointsTags: state => state.uniquePointsTags,
    uniquePointsJobs: state => state.uniquePointsJobs,

    intersectedPointsTags: state => state.intersectedPointsTags,
    intersectedPointsJobs: state => state.intersectedPointsJobs
  },
  mutations: {
    SET_VIEWED_POINTS: (state, points) => state.viewedPoints = points,
    SET_VIEWED_POINT: (state, point) => state.viewedPoint = point,

    SET_PLAN_DELTA: (state, planDelta) => state.planDelta = planDelta,

    PREPARE_NEAREST_POINTS: state => {
      const points = state.viewedPoints
      const planDelta = state.planDelta
      const point = points.find(each => each.id === state.viewedPoint.id)

      const { id, x, y } = point || {}

      state.nearestOrigin = point

      state.nearestPoints = point && points
        // Take only points with 360 photo
        .filter(each => each.id !== id && each.type === FEATURE_TYPES.CAMERA360)
        // Prepare distance
        .map(each => ({ ...each, distance: lineLength([[each.x, each.y], [x, y]]) * planDelta}))
        || []
    },

    SET_POINT_TAGS: (state, { point: { id }, tags, points }) => {
      state.tagsByPoint = { ...state.tagsByPoint, [id]: tags }
      state.uniquePointsTags = uniqueItems({ points, itemsByPoint: state.tagsByPoint })
      state.intersectedPointsTags = intersectedItems({ points, itemsByPoint: state.tagsByPoint })
    },

    SET_POINT_JOBS: (state, { point: { id }, jobs, points }) => {
      state.jobsByPoint = { ...state.jobsByPoint, [id]: jobs }
      state.uniquePointsJobs = uniqueItems({ points, itemsByPoint: state.jobsByPoint })
      state.intersectedPointsJobs = intersectedItems({ points, itemsByPoint: state.jobsByPoint })
    },

    SET_POINTS_TAGS: (state, { points, tags }) => {
      const predicate = (a, b) => a.name === b.name

      const changedTagsByPoint = points.reduce((result, { id }) => ({
        ...result,
        [id]: union(difference(state.tagsByPoint[id], state.intersectedPointsTags, predicate), tags, predicate).sort(sortAsName(x => x.name))
      }), state.tagsByPoint)

      state.tagsByPoint = changedTagsByPoint
      state.uniquePointsTags = uniqueItems({ points, itemsByPoint: state.tagsByPoint })
      state.intersectedPointsTags = intersectedItems({ points, itemsByPoint: state.tagsByPoint })
    },

    SET_POINTS_JOBS: (state, { points, jobs }) => {
      const predicate = (a, b) => a.name === b.name

      const changedJobsByPoint = points.reduce((result, { id }) => ({
        ...result,
        [id]: union(difference(state.jobsByPoint[id], state.intersectedPointsJobs, predicate), jobs, predicate).sort(sortAsName(x => x.code))
      }), state.jobsByPoint)

      state.jobsByPoint = changedJobsByPoint
      state.uniquePointsJobs = uniqueItems({ points, itemsByPoint: state.jobsByPoint })
      state.intersectedPointsJobs = intersectedItems({ points, itemsByPoint: state.jobsByPoint })
    }
  },
  actions: {
    viewPoints: ({ commit }, { points, planDelta }) => {
      commit('SET_VIEWED_POINTS', points)
      commit('SET_PLAN_DELTA', planDelta)
      commit('PREPARE_NEAREST_POINTS')
    },

    viewPoint: ({ commit }, { point }) => {
      commit('SET_VIEWED_POINT', point)
      commit('PREPARE_NEAREST_POINTS')
    },

    fetchPointTags: async function({ commit, rootGetters: getters }, point) {
      const project = getters['project/project']
      const points = getters['viewer/plan/selectedSavedPoints']

      const tags = await this.$api.dirsV2.getTagsByPoint({ project, point }).then(response => response.data?.data?.data || [])

      commit('SET_POINT_TAGS', { point, tags, points })
    },

    fetchPointJobs: async function({ commit, rootGetters: getters }, point) {
      const project = getters['project/project']
      const points = getters['viewer/plan/selectedSavedPoints']

      const jobs = await this.$api.dirsV2.getJobsByPoint({ project, point, withDeleted: true }).then(response => response.data?.data?.data || [])

      commit('SET_POINT_JOBS', { point, jobs, points })
    },

    storePointsTags: async function({ commit, rootGetters: getters }, names) {
      const points = getters['viewer/plan/selectedSavedPoints']
      const tags = getters['dirsRevision/tags']

      commit('SET_POINTS_TAGS', { points, tags: names.map(name => tags.find(tag => tag.name === name) || { id: null, name }) })
    },

    storePointsJobs: async function({ commit, rootGetters: getters }, names) {
      const points = getters['viewer/plan/selectedSavedPoints']
      const jobs = getters['dirsRevision/jobs']

      commit('SET_POINTS_JOBS', { points, jobs: names.map(name => jobs.find(job => job.name === name)) })
    },

    saveTags: async function({ rootGetters: getters }) {
      const points = getters['viewer/plan/selectedSavedPoints']

      await Promise.all(points.map(point => this.$api.definingPoints.updatePointTags({ point, tags: getters['points/pointTags'](point) })))
    },

    saveJobs: async function({ rootGetters: getters }) {
      const points = getters['viewer/plan/selectedSavedPoints']

      await Promise.all(points.map(point => this.$api.definingPoints.updatePointJobs({ point, jobs: getters['points/pointJobs'](point) })))
    }
  }
}
