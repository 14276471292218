import Projection from 'ol/proj/Projection'
import View from 'ol/View'
import ImageStatic from 'ol/source/ImageStatic'
import ImageLayer from 'ol/layer/Image'
import Map from 'ol/Map'
import { getCenter } from 'ol/extent'
import {promisify} from '../utils/immutable'

export default {
  /**
   * @type {Map}
   */
  map: null,

  sources: {
    /**
     * @type {ImageStatic}
     */
    image: null
  },

  layers: {
    /**
     * @type {ImageLayer}
     */
    image: null
  },

  extent: [0, 0, 2480, 3508],

  projection: new Projection({
    code: 'xkcd-image',
    units: 'pixels',
    extent: [0, 0, 2480, 3508]
  }),

  create: function(target) {
    const instance = { ...this }

    instance.map = new Map({
      target,
      view: new View({
        projection: this.projection,
        center: getCenter(this.extent),
        zoom: 2
      }),
      controls: []
    })

    return instance
  },

  apply: async function({ left, right, result }) {
    return promisify(resolve => {
      const createSource = url => { 
        const source = new ImageStatic({ url, projection: this.projection, imageExtent: this.extent })

        source.on('imageloadend', () => { !Object.values(sources).some(x => x.getState() === 'loading') && resolve() })
        source.on('imageloaderror', () => { !Object.values(sources).some(x => x.getState() === 'loading') && resolve() })

        return source
      }

      const createLayer = source => new ImageLayer({ source })

      const sources = this.sources = {
        ...left && { left: createSource(left.preview) },
        ...right && { right: createSource(right.preview) },
        ...result && { result: createSource(result) }
      }

      const layers = this.layers = {
        ...left && { left: createLayer(sources.left) },
        ...right && { right: createLayer(sources.right) },
        ...result && { result: createLayer(sources.result) }
      }

      Object.values(layers).forEach(x => this.map.addLayer(x))

      setTimeout(() => this.map.updateSize(), 100)
    })
  },

  modify: function({ visibility }) {
    this.layers.left && this.layers.left.setVisible(visibility[0])
    this.layers.right && this.layers.right.setVisible(visibility[1])
    this.layers.result.setVisible(visibility[0] && visibility[1]) 
  },

  clear: function() {
    Object.values(this.sources || {}).filter(x => x).forEach(x => x.dispose())
    Object.values(this.layers || {}).filter(x => x).forEach(x => (x.dispose() || true) && this.map.removeLayer(x))
    this.map.changed()
  }
}
