<template>
    <div :class="classes.root">
        <!-- If icon -->
        <div v-if="icon"
             class="f-y-center">
            <!-- Icon -->
            <icon :name="icon"
                  class="no-shrink _mr-0.5"
                  :color="colors.icon" />

            <!-- Label -->
            <span>{{ label }}</span>
        </div>

        <!-- Else -->
        <span v-else>{{ label }}</span>
    </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: 'N/A' },
    active: { type: Boolean, default: false },
    color: { type: String, default: null },
    icon: { type: String, default: null }
  },
  computed: {
    classes() {
      return {
        root: {
          'inline _px-0.5 _py-0.25 fs-0.9 rounded border-box lh-0.9 cursor-pointer': true,
          'text-gray-500 bg-gray-200': !this.active && !this.color,
          'text-white bg-accent': this.active,
          [`bg-${this.color} text-white`]: this.color
        }
      }
    },

    colors() {
      return {
        icon: this.color || (this.dark ? 'gray-100' : 'gray-400')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: 1.25rem;
  height: 1.25rem;
  padding: .10rem;
  box-sizing: border-box;
}
</style>
