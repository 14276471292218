<template>
    <div class="photo-joystick"
         :class="{ 'b-15': shouldConsideGPR }">
        <div class="f">
            <!-- Controls -->
            <el-button-group v-if="shouldDisplayControls">
                <el-button :disabled="!hasPrevImage"
                           :class="{ 'text-white': true, 'is-disabled': !hasPrevImage }"
                           @click="doPrev">
                    <i class="el-icon-arrow-left" />
                </el-button>
                <el-button :disabled="!hasNextImage"
                           :class="{ 'text-white': true, 'is-disabled': !hasNextImage }"
                           @click="doNext">
                    <i class="el-icon-arrow-right" />
                </el-button>
            </el-button-group>

            <!-- Description --> 
            <plan-button :tooltip="description"
                         class="_ml-0.5"
                         icon="location" />

            <!-- Warnings -->
            <plan-button v-if="hasWarnings"
                         :tooltip="labels.aboutOrientation"
                         class="_ml-0.5"
                         color="red-1"
                         icon="warning">
                <template #tooltip>
                    <ul class="_px-1 _py-0.25 _m-0 space-y-0.25">
                        <li v-if="!hasPhotoOrientation">
                            {{ labels.aboutOrientation }}
                        </li>
                        <li v-if="!hasPlanNorth">
                            {{ labels.aboutNorth }}
                        </li>
                        <li v-if="!hasPlanDelta">
                            {{ labels.aboutDelta }}
                        </li>
                    </ul>
                </template>
            </plan-button>
        </div>
    </div> 
</template>

<script>
import PlanButton from '@/components/map/PlanButton'

export default {
  components: {
    PlanButton
  },
  props: {
    shouldDisplayControls: Boolean,
    shouldConsideGPR: Boolean,
    hasPrevImage: Boolean,
    hasNextImage: Boolean,

    hasPhotoOrientation: Boolean,
    hasPlanNorth: Boolean,
    hasPlanDelta: Boolean,

    description: {
      type: String,
      default: null
    }
  },
  computed: {
    labels() {
      return {
        aboutOrientation: 'Фотография не имеет данных ориентации в пространстве',
        aboutNorth: 'На плане не указано направление на север',
        aboutDelta: 'На плане не указан масштаб'
      }
    },

    hasWarnings() {
      return !this.hasPhotoOrientation || !this.hasPlanNorth || !this.hasPlanDelta
    }
  },
  methods: {
    doPrev() { this.$emit('on-prev') },
    doNext() { this.$emit('on-next') }
  }
}
</script>

<style lang="scss">
.photo-joystick {
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
  z-index: 99;

  .el-button {
    display: inline-flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #313131 !important;
    border-color: #313131 !important;
    border-top: none;
    border-bottom: none;
    padding: 10px;

    &-group {
      display: inline-flex;

      .el-button {
        height: auto;
      }
    }

    &:hover {
      background: #409eff !important;
      border-color: #409eff !important;
    }

    &.is-active {
      background: #409eff !important;
      border-color: #409eff !important;
    }

    &.is-disabled {
      background-color: #2C2C2C !important;
      border-color: #2C2C2C !important;
      color: #616161 !important;
    }
  }
}
</style>
