import Draw from 'ol/interaction/Draw'
import { Translate } from 'ol/interaction'
import LineString from 'ol/geom/LineString'
import { Feature, Map } from 'ol'
import ImageStatic from 'ol/source/ImageStatic'
import ImageLayer from 'ol/layer/Image'
import Point from 'ol/geom/Point'

/**
 * @typedef OLJContext
 * @property {OpenLayer~Map} [map]
 * @property {OpenLayer~Source} [source]
 * @property {OpenLayer~Feature} [feature]
 */

/**
 * Exception messages
 */
const messages = {
  undefinedMap: () => 'OpenLayer~Map must be defined',
  undefinedSource: () => 'OpenLayer~Source must be defined',
  undefinedFeature: () => 'OpenLayer~Feature must be defined',
  undefinedGeometry: () => 'OpenLayer~Geometry must be defined',
  undefinedProperty: name => `${name} must be defined`
}

/**
 * Static values
 */
const values = {
  arrowSize: 16,
  arrowRotationA: 3.92699,
  arrowRotationB: 2.35619
}

/**
 * List of active interactions 
 */
const interactions = []


/**
 * @namespace OpenLayersJack
 */
export default {

  /**
   * @type {OLJContext}
   */
  context: {},

  /**
   * @type {Object}
   */
  settings: {},

  /**
   * Create builder by context
   * @param {Object} [context.map] - The OpenLayer~Source 
   *  @see {@link https://openlayers.org/en/latest/apidoc/module-ol_Map-Map.html}
   * @param {Object} [context.source] - The OpenLayer~Feature 
   *  @see {@link https://openlayers.org/en/latest/apidoc/module-ol_source_Source-Source.html}
   * @param {Object} [context.feature] - The OpenLayer~Map 
   *  @see {@link https://openlayers.org/en/latest/apidoc/module-ol_Feature-Feature.html}
   * @returns {OpenLayersJack}
   */
  by: function(context) {
    context.feature && !context.geometry && (context.geometry = context.feature.getGeometry())
    
    return { ...this, context, settings: {} }
  },

  /**
   * @param {import('ol/PluggableMap').MapOptions} options
   * @returns {ImageStatic}
   */
  createMap: function(options) {
    return new Map(options)
  },

  /**
   * @param {import('ol/source/Source').Options} options
   * @returns {ImageStatic}
   */
  createImageSource: function(options) {
    return new ImageStatic(options)
  },

  /**
   * @param {import('ol/layer/Layer').Options} options
   * @returns {ImageLayer}
   */
  createImageLayer: function(options) {
    return new ImageLayer(options)
  },

  /**
   * Start drawing by type
   * @param {string} type - Type of drawing geometry
   * @param {Object} properties - The drawing options
   * @param {boolean} properties.withoutApply - Draw a geometry without add to source
   *  @see {@link https://openlayers.org/en/latest/apidoc/module-ol_geom_GeometryType.html}
   * @returns {OpenLayersJack}
   */
  as: function(type, properties) {
    if (this._pass()) return this

    return {
      'path': this.asPath,
      'line': this.asLine,
      'arrow': this.asArrow,
      'polygon': this.asPolygon,
      'point': this.asPoint
    }[type].bind(this)(properties)
  },

  /**
   * Start interactive drawing of path
   * @param {Object} properties - The drawing options   
   * @param {boolean} properties.withoutApply - Draw a geometry without add to source
   * @returns {OpenLayersJack}
   */
  asPath: function({ withoutApply } = {}) {
    if (this._pass()) return this

    this._start({ type: 'LineString', withoutApply })

    return this
  },

  /**
   * Start interactive drawing of line
   * @param {Object} properties - The drawing options
   * @param {boolean} properties.withoutApply - Draw a geometry without add to source
   * @returns {OpenLayersJack}
   */
  asLine: function({ withoutApply } = {}) {
    if (this._pass()) return this

    this._start({ type: 'LineString', withoutApply })

    const { draw } = this.context

    this.onChange(({ geometry }) => geometry.getCoordinates().length === 3 && draw.finishDrawing())

    return this
  },

  /**
   * Start interactive drawing of arrow
   * @param {Object} properties - The drawing options
   * @param {boolean} properties.withoutApply - Draw a geometry without add to source
   * @returns {OpenLayersJack}
   */
  asArrow: function({ withoutApply } = {}) {
    if (this._pass()) return this

    this._start({ type: 'LineString', withoutApply })

    const { source } = this.context

    const children = []

    this.onStart(({ feature, style }) => {
      children[0] = new Feature({ geometry: new LineString([]) })
      children[1] = new Feature({ geometry: new LineString([]) })

      children[0].setStyle(style)
      children[1].setStyle(style)

      children[0].set('_ignored', true)
      children[1].set('_ignored', true)

      source.addFeature(children[0])
      source.addFeature(children[1])

      feature.set('children', children)
    })

    this.onChange(({ geometry }) => {
      geometry.forEachSegment((start, end) => {
        children[0].getGeometry().setCoordinates([end, [end[0] + values.arrowSize, end[1]]])
        children[1].getGeometry().setCoordinates([end, [end[0] + values.arrowSize, end[1]]])

        children[0].getGeometry().rotate(this._angle(start, end) - values.arrowRotationA, end)
        children[1].getGeometry().rotate(this._angle(start, end) - values.arrowRotationB, end)
      })
    })

    this.onChange(({ draw, geometry }) => geometry.getCoordinates().length === 3 && draw.finishDrawing())

    return this
  },

  /**
   * Start interactive drawing of polygon
   * @param {Object} properties - The drawing options
   * @param {boolean} properties.withoutApply - Draw a geometry without add to source
   * @returns {OpenLayersJack}
   */
  asPolygon: function({ withoutApply } = {}) {
    if (this._pass()) return this

    this._start({ type: 'Polygon', withoutApply })

    return this
  },

  /*
   * Start interactive drawing of point
   * @param {Object} properties - The drawing options
   * @param {boolean} properties.withoutApply - Draw a geometry without add to source
   * @returns {OpenLayersJack}
   */
  asPoint: function({ withoutApply } = {}) {
    if (this._pass()) return this

    this._start({ type: 'Point', withoutApply })

    return this
  },

  /*
   * Start interactive drawing of lasso
   * @param {Object} properties - The drawing options
   * @param {boolean} properties.withoutApply - Draw a geometry without add to source
   * @returns {OpenLayersJack}
   */
  asLasso: function({ withoutApply } = {}) {
    if (this._pass()) return this

    this._start({ type: 'Polygon', freehand: true, withoutApply })

    return this
  },

  /**
   * Draw point
   * @returns {OpenLayersJack}
   */
  isPoint: function(properties) {
    if (this._pass()) return this

    const { x, y } = properties || {}

    const feature = new Feature({ geometry: new Point([x, y]), x, y })
    
    this.context['feature'] = feature
    this.context['geometry'] = feature.getGeometry()

    return this
  },

  /**
   * Draw line
   * @returns {OpenLayersJack}
   */
  isLine: function(properties) {
    if (this._pass()) return this

    const { from, to } = properties || {}

    console.assert(from, messages.undefinedProperty('from'))
    console.assert(to, messages.undefinedProperty('to'))

    const feature = new Feature({ geometry: new LineString([from, to]), x: from[0], y: from[1] })

    this.context['feature'] = feature
    this.context['geometry'] = feature.getGeometry()

    return this
  },

  /**
   * Draw arrow
   * @returns {OpenLayersJack}
   */
  isArrow: function(properties) {
    if (this._pass()) return this

    const { from, to, size } = properties

    return this.isLine(properties)
      .takeChild(({ source }) => this
        .by({ source })
        .isLine({ from: to, to: [to[0] + size || values.arrowSize, to[1]] })
        .rotate(this._angle(from, to) - values.arrowRotationA, to)
        .toFeature()
      )
      .takeChild(({ source }) => this
        .by({ source })
        .isLine({ from: to, to: [to[0] + size || values.arrowSize, to[1]] })
        .rotate(this._angle(from, to) - values.arrowRotationB, to)
        .toFeature()
      )
  },

  /**
   * Hook given child feature
   * @param {Function} taken - Get child feature by inheritance builder
   * @returns {OpenLayersJack}
   */
  takeChild: function(taken) {
    if (this._pass()) return this

    const { toChildren } = this.settings

    this.settings['toChildren'] = [...toChildren || [], taken]

    return this
  },

  /*
   * Execute a provided function for each feature child
   * @param {Function} handler - Function to execute on each feature child
   * @returns {OpenLayersJack}
   */
  forChild: function(handler) {
    if (this._pass()) return this

    const { feature } = this.context

    console.assert(feature, messages.undefinedFeature)

    if (!feature) return this

    const children = feature.get('children') || []

    children.forEach(handler)

    return this
  },

  /**
   * Called when interactive drawing began
   * @param {Function} handler
   * @returns {OpenLayersJack}
   */
  onStart: function(handler) {
    if (this._pass()) return this

    const { draw } = this.context

    draw.on('drawstart', () => handler(this.context))

    return this
  },

  /**
   * Called when interactive drawing finished
   * @param {Function} handler
   * @returns {OpenLayersJack}
   */
  onEnd: function(handler) {
    if (this._pass()) return this

    const { draw } = this.context

    draw.on('drawend', () => handler(this.context))

    return this
  },

  /**
   * Called when interactive drawing aborted
   * @param {Function} handler
   * @returns {OpenLayersJack}
   */
  onAbort: function(handler) {
    if (this._pass()) return this

    const { draw } = this.context

    draw.on('drawabort', () => handler(this.context))

    return this
  },

  /**
   * Called when interactive drawing updated
   * @param {Function} handler
   * @returns {OpenLayersJack}
   */
  onChange: function(handler) {
    if (this._pass()) return this

    this.onStart(event => event.feature.on('change', () => handler(this.context)))

    return this
  },

  /**
   * Called when translate interaction finished
   * @see {@link https://openlayers.org/en/latest/apidoc/module-ol_interaction_Translate-Translate.html}
   * @param {Function} handler
   * @returns {OpenLayersJack}
   */
  onTranslateEnd: function(handler) {
    if (this._pass()) return this

    const { map } = this.context

    console.assert(map, messages.undefinedMap)

    if (!map) return this

    map.get('translate')?.on('translateend', event => handler({
      feature: event.features.getArray()[0],
      features: event.features.getArray()
    }))

    return this
  },

  /**
   * Add property for OpenLayer~Feature
   * @param {string} key - The key of the property
   * @param {string} value - The value of the property
   * @returns {OpenLayersJack}
   */
  property: function(key, value) {
    if (this._pass()) return this

    const to = this.context['properties'] || (this.context['properties'] = {})

    to[key] = value

    this._applyProperties()

    return this
  },

  /**
   * Add properties for OpenLayer~Feature
   * @param {Object[]} properties - The properties array
   * @returns {OpenLayersJack}
   */
  properties: function(properties) {
    if (this._pass()) return this

    const to = (this.context['properties'] || (this.context['properties'] = {}))

    Object.entries(properties).forEach(([key, value]) => to[key] = value)

    this._applyProperties()

    return this
  },

  /**
   * Set coordinates for the geometry
   * @returns {OpenLayersJack}
   */
  position: function(x, y) {
    if (this._pass()) return this

    const { geometry } = this.context

    console.assert(geometry, messages.undefinedGeometry())

    if (!geometry) return this

    geometry.setCoordinates([x, y])

    return this
  },

  /**
   * Change coordinates of the geometry
   * @returns {OpenLayersJack}
   */
  move: function(dx, dy) {
    if (this._pass()) return this

    const { geometry } = this.context

    console.assert(geometry, messages.undefinedGeometry())

    if (!geometry) return this

    geometry.getType() === 'Polygon' && geometry.setCoordinates([geometry.getCoordinates()[0].map(([x, y]) => [x + dx, y + dy])])
    geometry.getType() === 'Point' && geometry.setCoordinates(geometry.getCoordinates().map(([x, y]) => [x + dx, y + dy]))

    return this
  },

  /**
   * Relative rotation of the geometry
   * @param {number} value - The rotation angle in radians
   * @param {number[]} origin - The origin of rotation
   * @returns {OpenLayersJack}
   */
  rotate: function(value, origin) {
    if (this._pass()) return this

    const { feature, geometry } = this.context

    console.assert(feature, messages.undefinedFeature())
    console.assert(geometry, messages.undefinedGeometry())

    if (!feature || !geometry) return this

    geometry.rotate(value, origin)

    this.settings['transformations'] = [...this.settings.transforms || [], { type: 'rotation', value, origin }]

    this._applyTransformations()

    return this
  },

  /**
   * Set style by constructor & custom properties
   *  @see {@link https://openlayers.org/en/latest/apidoc/module-ol_style_Style-Style.html}
   * @param {Function} constructor - Recieve style object by feature & custom properties
   * @param {Object} properties - Custom properties to pass in style constructor
   * @returns {OpenLayersJack}
   */
  styled: function(constructor, properties = {}) {
    if (this._pass()) return this

    this.settings['toStyle'] = constructor
    this.settings['forStyle'] = properties

    this._applyStyle()

    return this
  },

  /**
   * Add the feature in the source
   * @returns {OpenLayersJack}
   */
  draw: function() {
    if (this._pass()) return this

    const { source, feature } = this.context

    console.assert(source, messages.undefinedSource())
    console.assert(feature, messages.undefinedFeature())

    if (!source || !feature) return this

    this._applyProperties()
    this._applyStyle()
    this._applyChildren()
    this._applyTransformations()

    this._draw(feature)

    return this
  },

  /**
   * Refresh the feature or features in source
   * @returns {OpenLayersJack}
   */
  redraw: function() {
    if (this._pass()) return this

    const { source, feature } = this.context

    if (feature) {
      this._applyProperties()
      this._applyStyle()

      ;(feature.get('children') || []).forEach(feature => this.by({ feature }).redraw())

      return this
    }

    if (source) {
      this.by({ source }).toFeatures().forEach(feature => this.by({ feature }).redraw())

      return this
    }

    return this
  },

  /**
   * Remove the feature   
   * @returns {OpenLayersJack}
   */
  remove: function() {
    if (this._pass()) return this

    const { source, feature } = this.context

    console.assert(source, messages.undefinedSource())

    if (!source || !feature) return this

    source.hasFeature(feature) && source.removeFeature(feature)

    ;(feature?.get('children') || []).forEach(child => source.removeFeature(child))

    return this
  },

  /**
   * Clear source
   * @returns {OpenLayersJack}
   */
  clear: function() {
    if (this._pass()) return this

    const { source } = this.context

    console.assert(source, messages.undefinedSource())

    if (!source) return this

    source.clear()

    return this
  },

  /**
   * Stop all drawing
   * @returns {OpenLayersJack}
   */
  stop: function() {
    if (this._pass()) return this

    const { map } = this.context

    console.assert(map, messages.undefinedMap)

    if (!map) return this

    interactions.forEach(map.removeInteraction.bind(map))

    return this
  },

  /**
   * Return a features from the source    
   * @returns {Object[]}
   */
  toFeatures: function() {
    if (this._pass()) return this

    const { source } = this.context

    console.assert(source, messages.undefinedSource)

    if (!source) return []

    return source.getFeatures().filter(feature => !feature.get('_ignored'))
  },

  /**
   * Return the feature by predicate
   * @param {Function} predicate - Return condition result for each feature   
   * @returns {Object}
   */
  find: function(predicate) {
    if (this._pass()) return this

    return this.toFeatures().find(predicate)
  },

  /**
   * Return the features by predicate
   * @param {Function} predicate - Return condition result for each feature
   * @returns {Object}
   * 
   */
  filter: function(predicate) {
    if (this._pass()) return this

    return this.toFeatures().filter(predicate)
  },

  /**
   * Enable a translate interaction for OpenLayer~Map by features from OpenLayer~Source
   * @see {@link https://openlayers.org/en/latest/apidoc/module-ol_interaction_Translate-Translate.html}
   * @returns {OpenLayersJack}
   */
  withTranslate: function() {
    if (this._pass()) return this

    const { map, source } = this.context

    console.assert(map, messages.undefinedMap)
    console.assert(source, messages.undefinedSource)

    if (!map || !source) return this

    const translate = new Translate({
      features: source.getFeaturesCollection()
    })

    map.addInteraction(translate)
    map.set('translate', translate)

    return this
  },

  /**
   * Disable a translate interaction for OpenLayer~Map
   * @returns {OpenLayersJack}
   */
  withoutTranslate: function() {
    if (this._pass()) return this

    const { map } = this.context

    console.assert(map, messages.undefinedMap)

    if (!map) return this

    map.removeInteraction(map.get('translate'))
    map.unset('translate')

    return this
  },

  /**
   * Return a feature from the context
   * @returns {Object}
   */
  toFeature: function() {
    if (this._pass()) return this

    const { feature } = this.context

    this._applyProperties()
    this._applyStyle()
    this._applyChildren()
    this._applyTransformations()

    return feature
  },

  /**
   * Pass next instruction if condition is false
   * @param {Function} - Return condition result
   * @returns {OpenLayersJack}
   */
  if: function(predicate) {
    this['_passed'] = !(typeof predicate === 'function' ? predicate() : predicate)

    return this
  },

  /**
   * Start the condition block. Pass nested interactions if condition is false
   * @param {Function} - Return condition result
   * @returns {OpenLayersJack}
   */
  begin: function(predicate) {
    this['_freeze'] = !(typeof predicate === 'function' ? predicate() : predicate)

    return this
  },

  /**
   * End the condition block
   * @returns {OpenLayersJack}
   */
  end: function() {
    this['_freeze'] = false

    return this
  },

  _start: function({ type, freehand, withoutApply }) {
    const { source, map } = this.context

    const draw = new Draw({ 
      type,
      freehand,
      ...!withoutApply && { source }
    })

    this.context['draw'] = draw
    this.settings['withoutApply'] = withoutApply

    interactions.forEach(interaction => map.removeInteraction(interaction))

    interactions.push(draw)
    map.addInteraction(draw)

    draw.on('drawstart', ({ feature }) => {
      this.context['feature'] = feature
      this.context['geometry'] = feature.getGeometry()
      
      this._applyProperties()
      this._applyStyle()
      this._applyChildren()
      this._applyTransformations()
    })

    draw.on('drawend', this._end.bind(this))

    draw.on('drawabort', () => this.remove())
  },

  _end: function() {
    const { map, draw } = this.context
    const { withoutApply } = this.settings

    map.removeInteraction(draw)

    withoutApply && this.remove()
  },

  _applyStyle: function() {
    const { feature } = this.context

    const a = this.settings['toStyle'] || feature.get('_toStyle')
    const b = this.settings['forStyle'] || feature.get('_forStyle')

    feature && (this.context['style'] = feature && a?.({ feature, ...b }))

    const { style } = this.context

    feature && style && feature.setStyle(() => [style])
    feature && style && feature.set('_toStyle', a)
    feature && style && feature.set('_forStyle', { feature, ...b })
  },

  _applyProperties: function() {
    const { feature, properties } = this.context
    feature && properties && feature.setProperties(properties)
  },

  _applyChildren: function() {
    const { source, feature } = this.context

    const a = feature.get('_toStyle')
    const b = feature.get('_forStyle')

    let on = source && feature

    const { toChildren = [] } = this.settings

    on && (this.context['children'] = [
      ...this.context['children'] || [], 
      ...toChildren
        .map(constructor => constructor(this.context))
        .filter(child => child)
        .map(child => {
          child.set('_ignored', true)

          const style = a?.({ feature: child, ...b })

          a && b && !child.get('_toStyle') && !child.get('_forStyle') && child.setStyle(style)
          a && b && !child.get('_toStyle') && child.set('_toStyle', a)
          a && b && !child.get('_forStyle') && child.set('_forStyle', { feature: child, ...b })

          return child
        })])
    on && (this.settings['toChildren'] = [])

    const { children } = this.context

    on = source && feature && children

    on && feature.set('children', children)
  },

  _applyTransformations: function() {
    const { children = [] } = this.context
    const { transformations = [] } = this.settings

    children.forEach(child => transformations.forEach(transformation => {
      transformation['type'] === 'rotation' && child.getGeometry().rotate(transformation['value'], transformation['origin'])
    }))
  },

  _pass: function() {
    return (this['_passed'] || this['_freeze']) && !(this['_passed'] = false)
  },

  _draw: function(feature) {
    const { source } = this.context

    source && feature && source.addFeature(feature)
    source && feature && (feature.get('children') || []).forEach(this._draw.bind(this))
  },

  _angle: (from, to) => Math.atan2(to[1] - from[1], to[0] - from[0])
}
