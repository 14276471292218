<template>
    <div class="h-full rows-minmax-min hidden-y">
        <!-- Layers -->
        <div class="hidden-x hidden-y">
            <plan-layer 
                v-for="layer in layers"
                :key="layer.id"
                :layer="layer"
                :selected="layerSelected(layer)"
                :polygons="layerPolygons(layer)"
                :selected-polygons="selectedPolygons"
                :units="units"
                :animated="animated"
                :disabled="!opened"
                @layer-upgrade="$emit('layer-upgrade', $event)"
                @layer-compare="$emit('layer-compare', $event)"
                @layer-remove="$emit('layer-remove', $event)"
                @polygon-open="$emit('polygon-open', $event)" />
        </div>

        <!-- Toggle -->
        <div class="border-top-1 border-black h-1.5 f-center cursor-pointer"
             @click="toggle">
            <icon :name="icons.toggle"
                  color="gray-600"
                  class="wh-0.9" />
        </div>
    </div>
</template>

<script>
import PlanLayer from '@/components/map/PlanLayer'

export default {
  components: {
    PlanLayer
  },
  props: {
    layers: { type: Array, default: () => [] },
    selectedLayer: { type: Object, default: null },
    selectedPolygons: { type: Array, default: () => [] },
    polygonsByLayers: { type: Object, default: () => ({}) },
    polygonsByLayersLoading: { type: Object, default: () => ({}) },
    units: { type: Array, default: () => [] },
    animated: { type: Boolean, default: true },
    opened: { type: Boolean, default: true }
  },
  computed: {
    icons() {
      return {
        toggle: this.opened ? 'corner-right' : 'corner-left'
      }
    },

    layerSelected() {
      return layer => layer && this.selectedLayer && layer.id === this.selectedLayer.id
    },

    layerPolygons() {
      return layer => this.polygonsByLayers[layer?.id] || []
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle', !this.opened)
    }
  }
}
</script>
