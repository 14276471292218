import Cookie from 'js-cookie'
import UAParser from 'ua-parser-js'
import { toLocalDateFromISO  } from '@/utils/datetime';
import { PERMISSION_BACKWARD_COMPATIBILITY } from '@/utils/permissions';
import { actionable } from '@/store/mixins';
import { fromError } from '@/utils/common';

export default {
  namespaced: true,
  mixins: [
    actionable({
      name: 'confirmPasswordReset',
      loadable: true,
      at: ({ api }, { code, password, password_again }) => 
        api.auth.resetPasswordConfirm({ code, password, password_again })
          .catch(fromError)
    })
  ],
  state: {
    profile: null,
    permissions: [],
    licence: [],
    loggedIn: !!Cookie.get('token'),
    hasRefreshToken: !!Cookie.get('refresh_token'),
    isRefreshed: false,
    routes: [],
    addRoutes: [],
    profileLicence: null,

    sessions: [],
    lastSession: null,

    shareKey: null,
    shareBarcode: null
  },

  getters: {
    profile: state => state.profile,
    profileId: state => state.profile.id,
    loggedIn: state => state.loggedIn,
    hasRefreshToken: state => state.hasRefreshToken,
    isRefreshed: state => state.isRefreshed,
    permissions: state => state.permissions,

    sessions: state => state.sessions,
    lastSession: state => state.lastSession,

    licence: state => state.licence,
    profileLicence: state => state.profileLicence,
    showSystemNotifications: state => state.profile.show_system_notifications,
    hasPermissions: (state, getters) => (...permissionNames) => {
      if (!getters.permissions) {
        return false;
      }

      for (const permissionName of permissionNames) {
        // TODO see src/utils/permissions.js
        // const indexOfPermission = getters.permissions.findIndex((permission) => permission.slug === permissionName);
        const indexOfPermission = getters.permissions.findIndex((permission) => (PERMISSION_BACKWARD_COMPATIBILITY ? permission.slug_new : permission.slug) === permissionName);

        if (indexOfPermission === -1) {
          return false;
        }
      }

      return true;
    },
    hasAnyPermission: (state, getters) => (...permissionNames) => {
      if (!getters.permissions) {
        return false;
      }

      for (const permissionName of permissionNames) {
        // TODO see src/utils/permissions.js
        // const indexOfPermission = getters.permissions.findIndex((permission) => permission.slug === permissionName);
        const indexOfPermission = getters.permissions.findIndex((permission) => (PERMISSION_BACKWARD_COMPATIBILITY ? permission.slug_new : permission.slug) === permissionName);

        if (indexOfPermission !== -1) {
          return true;
        }
      }

      return false;
    },
    hasLicenceAbilities: (state, getters) => (...abilityNames) => {
      if (!getters.licence || !abilityNames?.length) {
        return false;
      }

      for (const abilityName of abilityNames) {
        if (!getters.licence.some((licenceAbility) => licenceAbility.name === abilityName)) {
          return false;
        }
      }

      return true;
    },
    hasAnyLicenceAbility: (state, getters) => (...abilityNames) => {
      if (!getters.licence) {
        return false;
      }

      for (const abilityName of abilityNames) {
        if (getters.licence.some((licenceAbility) => abilityName === licenceAbility)) {
          return true;
        }
      }

      return false;
    }
  },

  mutations: {
    SET_PROFILE: (state, payload = null) => {
      state.profile = payload;
      state.loggedIn = !!payload;
    },
    SET_PROFILE_PERMISSIONS: (state, payload = null) => {
      state.permissions = payload;
    },
    SET_REFRESHED: (state, payload = false) => {
      state.isRefreshed = payload;
    },
    SET_PROFILE_LICENCE: (state, payload) => {
      state.licence = payload;
    },
    SET_PROFILE_LICENCE_INFO: (state, payload) => {
      state.profileLicence = payload;
    },

    SET_SESSIONS: (state, sessions = []) => {
      state.sessions = sessions
      state.lastSession = sessions[0]
    },

    SET_SHARE_DATA: (state, { key, barcode }) => {
      state.shareKey = key
      state.shareBarcode = barcode
    },

    CLEAR_SHARE_DATA: (state) => {
      state.shareKey = state.shareBarcode = null
    }
  },

  actions: {
    LOGIN: async function ({dispatch}, payload) {
      const { data } = await this.$api.auth.login(payload);

      await resetTokensByResponseData(data);

      this.$socket.echoClient.connector.options.auth.headers.Authorization = `Bearer ${data.data.access.token}`;

      await dispatch('GET_PROFILE');
    },
    loginAsUser: async function ({dispatch}, payload) {
      let {data} = await this.$api.auth.loginAsUser(payload);
      await resetTokensByResponseData(data);
      this.$socket.echoClient.connector.options.auth.headers.Authorization = `Bearer ${data.data.access.token}`;
      await dispatch('GET_PROFILE');
    },
    loginByToken: async function ({dispatch}, payload) {
      let {data} = await this.$api.auth.loginByToken(payload);
      await resetTokensByResponseData(data);
      this.$socket.echoClient.connector.options.auth.headers.Authorization = `Bearer ${data.data.access.token}`;
      await dispatch('GET_PROFILE');
    },

    RESET_PROFILE: async function ({commit}) {
      commit('SET_PROFILE');
      commit('SET_PROFILE_PERMISSIONS');
      await clearTokens();
    },

    REFRESH_TOKEN: async function ({dispatch}, {refresh_token}) {
      const { data } = await this.$api.auth.refreshToken({refresh_token});

      await resetTokensByResponseData(data);

      this.$socket.echoClient.connector.options.auth.headers.Authorization = `Bearer ${data.data.access.token}`;

      await dispatch('GET_PROFILE');
    },

    LOGOUT: async function ({dispatch}) {
      await this.$api.auth.logout();
      await dispatch('RESET_PROFILE');
      this.$socket.echoClient.disconnect();
      this.$socket.echoClient.connect();
    },

    logoutIf: async function({dispatch}, payload) {
      await this.$api.auth.logoutIf(payload);
      await dispatch('getSessions');
    },

    logoutElse: async function({dispatch}) {
      await this.$api.auth.logoutElse();
      await dispatch('getSessions');
    },

    share: async function({ commit }) {
      const { key } = (await this.$api.auth.getShareKey())?.data?.data || {}
      const barcode = (await this.$api.auth.getShareBarcode({ key }))?.data?.data

      commit('SET_SHARE_DATA', {
        key,
        barcode 
      }) 
    },

    clearShare: async function({ commit }) {
      commit('CLEAR_SHARE_DATA')
    },

    GET_PROFILE: async function ({
      commit,
      dispatch
    }) {
      let {data} = await this.$api.auth.profile();
      commit('SET_PROFILE', data.data);
      await dispatch('GET_PROFILE_PERMISSIONS');
      await dispatch('getProfileLicense');
      await dispatch('account/fetchAccess', { type: 'system' }, { root: true })
      await dispatch('common/fetchSettings', {}, { root: true })

      dispatch('account/fetchUnreadNotificationCount', {}, { root: true })

      // await dispatch('permissions/generateRoutes', state.permissions,
      //   {root: true});
    },

    GET_PROFILE_PERMISSIONS: async function ({
      commit,
      state,
      dispatch
    }) {
      let {data} = await this.$api.auth.profilePermissions();
      commit('SET_PROFILE_PERMISSIONS', data.data);
      await dispatch('access/generateRoutes', state.permissions,
        {root: true});
    },

    UPDATE_PROFILE: async function ({dispatch}, payload) {
      let {data} = await this.$api.auth.updateProfile(payload);
      dispatch('GET_PROFILE');
      return data;
    },
    UPDATE_PROFILE_AVATAR: async function ({dispatch}, payload) {
      let {data} = await this.$api.auth.updateProfileAvatar(payload);
      dispatch('GET_PROFILE');
      return data;
    },
    DELETE_PROFILE_AVATAR: async function ({dispatch}, payload) {
      let {data} = await this.$api.auth.deleteProfileAvatar(payload);
      dispatch('GET_PROFILE');
      return data;
    },

    getSessions: async function({commit}) {
      const sessions = (await this.$api.auth.getSessions())?.data?.data?.data || [];

      const mutations = sessions.map(session => {
        const agent = UAParser(session.user_agent)

        const browser = agent['browser']['name'] && agent['browser']['version'] && `${agent['browser']['name']} ${agent['browser']['version']}` 
        const os = agent['os']['name'] && agent['os']['version'] && `${agent['os']['name']} ${agent['os']['version']}` 

        return ({ 
          ...session, 
          last_activity_at: toLocalDateFromISO(session['last_activity_at']),
          access_token_expired_at: toLocalDateFromISO(session['access_token_expired_at']),
          refresh_token_expired_at: toLocalDateFromISO(session['refresh_token_expired_at']),
          browser,
          os
        })
      })

      commit('SET_SESSIONS', mutations);
    },

    getProfileNotifications: async function (context, payload) {
      let {data} = await this.$api.auth.getProfileNotifications(payload);
      return data;
    },
    getProfileLicense: async function ({dispatch}, payload) {
      let {data} = await this.$api.auth.getProfileLicence(payload);
      if (data.data.data[0]) {
        await dispatch('getLicenseFunctionalities', data.data.data[0].id)
        await dispatch('getProfileLicenceInfo', data.data.data[0].id)
      }
      return data;
    },
    getLicenseFunctionalities: async function ({commit}, payload) {
      let {data} = await this.$api.auth.getLicenceAbilities(payload);
      commit('SET_PROFILE_LICENCE', data.data);
      return data;
    },
    getProfileLicenceInfo: async function ({commit}, payload) {
      let data = await this.$api.auth.getProfileLicenceInfo(payload);
      commit('SET_PROFILE_LICENCE_INFO', data.data.data);
      return data;
    }
  }
};

// Private repositories
async function resetTokensByResponseData(responseData) {
  const rootDomain = getRootDomain();
  const tokenExpires = new Date(responseData.data.access.expires_at);
  const refreshExpires = new Date(responseData.data.refresh.expires_at);

  await clearTokens();
  Cookie.set('token', responseData.data.access.token,
    {
      expires: tokenExpires,
      domain: rootDomain
    });
  Cookie.set('refresh_token', responseData.data.refresh.token,
    {
      expires: refreshExpires,
      domain: rootDomain
    });
}

async function clearTokens() {
  const rootDomain = getRootDomain();

  Cookie.remove('token', {domain: rootDomain});
  Cookie.remove('refresh_token', {domain: rootDomain});
}

function getRootDomain() {
  return location.hostname.split('.')
    .slice(-2)
    .join('.');
}

