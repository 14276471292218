<template>
    <el-container v-if="projectLoaded && accessLoadedForProject"
                  v-loading="accessLoading"
                  style="height: 100%;">
        <navigator v-if="showAsidePanel"
                   ref="navigator" />
        <transition name="fade"
                    mode="out-in">
            <router-view @compared="collapseNavigator" />
        </transition>
    </el-container>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import Navigator from '@/components/asidepanels/Navigator';
import socketEvents from '@/utils/socketEvents';

export default {
  name: 'Project',
  components: {Navigator},
  data: () => ({currentForm: 'class-form'}),
  computed: {
    ...mapGetters({
      showSystemNotifications: 'auth/showSystemNotifications'
    }),
    ...mapGetters('project', ['projectLoaded']),
    ...mapGetters('account', ['accessLoading', 'accessLoadedForProject']),

    showAsidePanel() {
      const exclusions = [
        'project.home',
        'project.notifications.lcr',
        'project.notifications.dpw',

        'project.tasks',
        'project.task',
        'project.calendar',
        'project.heatmap.v2',
        'project.work-schedule',
        'project.gpr',
        'project.document',
        'project.approval',
        'project.approval.info',

        'project.about',
        'project.about.common',
        'project.about.users',
        'project.about.activity',

        'project.jobs.progress',
        'project.work.schedule.mock',
        'settings.profile',
        'settings.common',
        'settings.structure',
        'settings.organizations',
        'settings.users',
        'settings.roles',
        'settings.bim',
        'settings.ast',
        'settings.licenses',
        'settings.neural',
        'settings.notifications'
      ];

      return !exclusions.some(name => this.$route.name.includes(name));
    },
    projectChannelName() {
      return `v1.projects.${this.$route.params.projectId}`;
    }

  },
  created() {
    this.closeProject();
    this.loadStructure();
    this.listenProjectEventsNew();
  },
  async mounted() {
    const id = this.$route.params.projectId

    this.sentUserActivity({
      slug: 'project',
      type: 'opened',
      subject_id: id
    })

    await this.fetchProject(id)
    await this.fetchProjectOptions()
    await this.fetchAccess({ type: 'project' })
  },
  beforeDestroy() {
    this.leaveProjectChannel()
  },
  destroyed() {
    this.closeProject()
    this.clearProjectAccess()
    this.clearTasks()
  },
  methods: {
    ...mapActions('activity', ['sentUserActivity']),
    ...mapActions('project', ['fetchProject']),
    ...mapActions('projects', ['fetchProjectOptions']),
    ...mapActions('account', ['fetchAccess', 'clearProjectAccess']),
    ...mapActions('tasks', ['clearTasks']),

    goBack() {
      switch (this.$route.name) {
      default:
        this.$router.go(-1);
      }
    },

    collapseNavigator() {
      this.$refs.navigator?.collapse?.()
    },

    ...mapActions({
      closeProject: 'project/clearProject',
      getProjectStatisticsAfterStructure: 'project/getProjectStatisticsAfterStructure',
      clearNavBar: 'navigator/clear',
      updateNavBar: 'navigator/update'
    }),

    loadStructure() {
      this.clearNavBar();
      // this.getProjectStatisticsAfterStructure({projectId:this.$route.params.projectId});
      this.updateNavBar({ui_project: true});
    },
    //TODO Remove it after tests
    // listenProjectEvents() {
    //   this.$socket.bindCommonListener(this.$socket.echoClient.private(this.projectChannelName),
    //                                   (event) => {
    //                                     this.$notify({
    //                                       title: 'Система',
    //                                       type: 'info',
    //                                       message: event.description,
    //                                     });
    //                                   }, socketEvents);
    // },
    listenProjectEventsNew() {
      socketEvents.forEach(eventName => {
        // eslint-disable-next-line no-unused-vars
        this.$socket.echoClient.private(this.projectChannelName).listen(eventName, (event) => {
          // if (this.showSystemNotifications){
          //   this.$notify({
          //     title: 'Система',
          //     type: 'info',
          //     message: event.description,
          //     position:'bottom-right'
          //   });
          // }
          let eventNameFormatted = this.eventNameSplitter(eventName);
          switch (eventNameFormatted) {
          case 'houses':
          case 'floors':
          case 'floor-plans':
            this.loadStructure();
            break;
          default:
            return;
          }
        });
      });
    },


    leaveProjectChannel() {
      this.$socket.echoClient.leave(this.projectChannelName);
    }
  }
};
</script>
<style lang="scss">
.ol-map {
  height: 100%;
  width: 100%;
}

.el-drawer__container {
  overflow: hidden;
}

.no-padding {
  padding: 0 !important;
}

.no-scroll {
  overflow: hidden !important;
}
</style>
