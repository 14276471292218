import { parseISO } from 'date-fns'
import { fromZonedTime } from '@/utils/date'

export default axios => ({
  getProjects: payload => axios.get('projects', payload).then(response => response?.data?.data || {}),

  getProjectOptions: ({ project }) => axios.get(`projects/${project.id}/options`)
    .then(x => x?.data?.data || {})
    .then(options => {
      const { entries } = options
      
      return {
        ...options,
        entries: entries.map(x => ({
          ...x,
          ...x.name === 'restrict_call_acceptance_work_today_at' && { value: x.value && fromZonedTime(parseISO(x.value)) }
        }))
      }
    }),

  updateProjectOptions: ({ project, options }) => axios.put(`projects/${project.id}/options`, options),

  getProject(projectId) {
    return axios.get(`projects/${projectId}`);
  },
  options(projectId) {
    return axios.get(`projects/${projectId}/options`);
  },
  storeProject(payload) {
    return axios.post('projects', payload);
  },
  getProjectDashboard(projectId) {
    return axios.get(`projects/${projectId}/dashboard`);
  },
  getProjectImages(projectId, payload) {
    return axios.get(`projects/${projectId}/images`, payload);
  },
  storeProjectImages(projectId, payload) {
    return axios.post(`projects/${projectId}/images`, payload)
  },
  deleteProjectImage(projectId, imageId) {
    return axios.delete(`projects/${projectId}/images/${imageId}`);
  }
})
