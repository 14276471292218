import {fabric} from 'fabric-pure-browser';
import {nanoid} from 'nanoid';
import {Vector3} from 'three';

/**
 * Transform hex number to a css color string.
 *
 * @param {Number} hex
 * @return {String}
 * @public
 */
export const hexToColor = hex => `#${hex.toString(16)}`;

/**
 * Transform uv coords to world coords (x,y - coords of center)
 *
 * @param {Number[]} yolo
 * @param {Number} maxWidth
 * @param {Number} maxHeight
 * return {{tl: {Point}, tr: {Point}, bl: {Point}}}
 */
export const uvToWorld = (yolo, maxWidth, maxHeight) => {
  const [x, y, width, height] = yolo;
  const xOffset = maxWidth / 2;
  const yOffset = maxHeight / 2

  const toWorldX = uvX => uvX * maxWidth - xOffset;
  const toWorldY = uvY => uvY * maxHeight - yOffset;
  const toWorld = point => new fabric.Point(toWorldX(point.x), toWorldY(point.y));

  return {
    tl: toWorld(new fabric.Point(x - width / 2, y - height / 2)),
    tr: toWorld(new fabric.Point(x + width / 2, y - height / 2)),
    bl: toWorld(new fabric.Point(x - width / 2, y + height / 2))
  }
};

/**
 *
 * @param point
 * @param maxWidth
 * @param maxHeight
 * @returns {fabric.Point}
 */
export const uvPointToWorld = (point, maxWidth, maxHeight) => {
  const toWorldX = uvX => uvX * maxWidth;
  const toWorldY = uvY => uvY * maxHeight;
  return new fabric.Point(toWorldX(point.x), toWorldY(point.y));
}
export const getCenterOfLine = (point1, point2) => {
  return point1.midPointFrom(point2);
}

/**
 * Get center of uv coords
 *
 * @param {Number[]} yolo
 * @param {Number} maxWidth
 * @param {Number} maxHeight
 * return {Point}
 */
export const getCenterByUv = (yolo, maxWidth, maxHeight) => {
  const {
    tr,
    bl
  } = uvToWorld(yolo, maxWidth, maxHeight);
  return tr.midPointFrom(bl);
}

/**
 *
 * @param point
 * @returns {{phi: number, radius: number, theta: number}}
 */
export const getSphericalCoordinates = (point) => {
  return {
    phi: Math.atan2(Math.sqrt(point.x * point.x + point.z * point.z), point.y),
    theta: Math.atan2(point.z, point.x),
    radius: Math.sqrt(point.x * point.x + point.y * point.y + point.z * point.z)
  };
}

/**
 *
 * @param {Number} phi
 * @param {Number} theta
 * @param {Number} radius
 * @returns {{x: number, y: number, z: number}}
 */
export const getCartesianCoordinates = (phi, theta, radius) => {
  return new Vector3(
    radius * Math.sin(phi) * Math.cos(theta),
    radius * Math.cos(phi),
    radius * Math.sin(phi) * Math.sin(theta)
  );
}

/**
 * TODO@refactor
 * @deprecated Moved to immutable utility
 * Generate a unique string key
 * @returns {string}
 */
export const key = () => nanoid()

export const toggleCapital = (value, condition) => condition ? value[0].toUpperCase() + value.slice(1) : value

export const fromRequestError = ({ response }) => {
  throw ({
    messages: Object.values(response?.data?.data?.data || {})
  })
}

export const fromError = ({ response }) => {
  throw ({
    messages: response?.data?.data?.data || {}
  })
}

export const flatCollectionError = (messages, key) => messages[key] || Object.entries(messages).find(([k]) => k.startsWith(key))?.[1]
