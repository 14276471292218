import * as THREE from 'three'
import { createRequestPool } from './RequestPool'
import { chordByRadiusAndDistanceHalf, normalizeAngle, toRadians } from '@/utils/math'
import { promisify } from '@/utils/immutable'
import { getVerticesAsArrayOf3, setVerticesFromBufferOf3 } from '@/utils/three/compatibility'
import {createSpherePlaneShader} from '../values/shaders'

const PLANE_WIDTH = 1
const PLANE_HEIGHT = 1
const PLANE_WIDTH_POLYGONS = 4
const PLANE_HEIGHT_POLYGONS = 4
const SPHERE_MORPH_RADIUS = 2
const SPHERE_RADIUS = 1

const WIREFRAME = false

const MORPH = true

export const createTileSphere = ({ maxZoom, tileImage, buildTileUrlV3, onLayerAdded, onLayerSelected, onLayerLoaded, onTileLoading }) => {
  let image

  let lastAppliedZoom
  let lastAppliedLon
  let lastAppliedLat

  let layers = {}
  let loadedTiles = {}

  let meshes = []

  const requests = createRequestPool()

  let levels = []

  const refreshOrientation = ({ ignore } = {}) => {
    const lon = lastAppliedLon
    const lat = lastAppliedLat

    lastAppliedLon = null
    lastAppliedLat = null

    applyOrientation(lon, lat, { ignore })
  }

  let tileLoadingCount = 0

  const increaseTileLoadingCount = x => {
    tileLoadingCount += x
    tileLoadingCount == 0 ? onTileLoading(false) : onTileLoading(true)
  }

  const applyImage = x => promisify((resolve, reject) => tileImage({ image: x.storage_path })
    .then(r => {
      image = x

      levels = r.levels

      resolve()
    })
    .catch(e => reject(e))
  )

  const applyZoom = (zoom, { ignore = false } = {}) => {
    zoom = levels.length - 1 - maxZoom + zoom

    if (zoom === lastAppliedZoom) {
      return
    }

    lastAppliedZoom = zoom

    let layer = layers[zoom]

    if (layer) {
      onLayerSelected?.(layer)

      console.log({zoom})

      refreshOrientation()

      return
    }

    const level = levels[zoom]
    const maxLevel = levels[levels.length - 1]

    const { width, height, rows, cols, tiles } = level
    const { width: maxWidth, height: maxHeight } = maxLevel

    const ratio = height / width
    const ratioInvert = width / height

    const planesByRowCol = {}

    const planes = tiles.map(({row, col, x, y, width, height}) => {
      /** @type {THREE.PlaneGeometry} **/
      const geometry = new THREE.PlaneGeometry(width, height, PLANE_WIDTH_POLYGONS, PLANE_HEIGHT_POLYGONS)

      geometry.translate(x + width / 2, -y - height / 2, 0)

      const material = new THREE.ShaderMaterial(createSpherePlaneShader({ radius: SPHERE_MORPH_RADIUS }));

      const mesh = new THREE.Mesh(geometry, material);

      return planesByRowCol[row + '_' + col] = mesh
    })

    const radius = SPHERE_MORPH_RADIUS

    const verticesWithMesh = planes
      .map(mesh => ({mesh, vertices: getVerticesAsArrayOf3(mesh.geometry)}))
      .reduce((r, {mesh, vertices}) => [
        ...r,
        ...vertices.map(x => ({mesh, vertex: x}))
      ], [])

    const verticesWithMeshByY = verticesWithMesh
      .reduce((r, {mesh, vertex: [x, y, z]}) => ({
        ...r,
        [y]: [...r[y] || [], {mesh, vertex: [x, y, z]}]
      }), {})

    const boundX = [
      Math.min(...verticesWithMesh.map(({vertex: [x]}) => x)),
      Math.max(...verticesWithMesh.map(({vertex: [x]}) => x))
    ]

    const boundY = [
      Math.min(...Object.keys(verticesWithMeshByY).map(x => +x)),
      Math.max(...Object.keys(verticesWithMeshByY).map(x => +x))
    ]

    const meshWithVerticesAndTransformation = planes
      .map(mesh => ({mesh, vertices: getVerticesAsArrayOf3(mesh.geometry)}))
      .map(({mesh, vertices}) => ({
        mesh,
        vertices,
        verticesWithTransformation: vertices.map(([x, y, z]) => ({
          position: [x, y, z],
          transformation: [
            Math.abs(x - boundX[0]) / Math.abs(boundX[1] - boundX[0]),
            [Math.abs(y - boundY[0]) / (Math.abs(boundY[1] - boundY[0]) / 2)].map(x => x > 1 ? 1 - (x - 1) : x)[0],
            boundY[0] + Math.abs(boundY[1] - boundY[0]) / 2
          ]
        }))
      }))

    const meshWithVerticesAndResult = meshWithVerticesAndTransformation
      .map(({mesh, vertices, verticesWithTransformation}) => ({
        mesh,
        vertices,
        verticesWithTransformation,
        result: verticesWithTransformation.map(({position: [_px, py, pz], transformation: [rx, rd, yh]}) => {
          const chord = chordByRadiusAndDistanceHalf(radius, radius - radius * rd)
          //const y = radius - (chord / radius) * rd
          //const y = (radius - (chord / radius) * rd) * (ratioInvert * 0.75)
          const y = (radius - radius * rd) * (Math.PI / 2)

          const v = new THREE.Vector3(
            chord / radius,
            py >= yh ? y : -y,
            pz
          )
            .applyAxisAngle(new THREE.Vector3(0, 1, 0), -toRadians(rx * 360))

          return v.toArray()
        }).flat()
      }))

    // apply transformed values

    MORPH && meshWithVerticesAndResult.map(({ mesh, result }) => {
      setVerticesFromBufferOf3(mesh.geometry, result)
      mesh.needsUpdate = true
    })

    // group

    /** @type {THREE.Group} **/
    const mesh = new THREE.Group()

    mesh.add(...planes)

    mesh.scale.set(SPHERE_RADIUS, SPHERE_RADIUS, SPHERE_RADIUS)

    meshes.push(mesh)

    const dim = [rows, cols]

    layer = layers[zoom] = {
      zoom,

      mesh,
      planesByRowCol,

      imageWidth: width,
      imageHeight: height,
      imageMaxWidth: maxWidth,
      imageMaxHeight: maxHeight,

      tiles,

      dim
    }

    onLayerAdded?.(layer)
    onLayerSelected?.(layer)

    console.log({zoom, width, height, ratio, dim})

    refreshOrientation({ ignore })
  }

  const applyOrientation = (lon, lat, { ignore = false } = {}) => {
    if (lon === lastAppliedLon || lat === lastAppliedLat) {
      return
    }

    lon = normalizeAngle(lon)
    lat = normalizeAngle(lat)

    lastAppliedLon = lon
    lastAppliedLat = lat

    const layer = layers[lastAppliedZoom] || {}

    const {
      planesByRowCol,

      dim,
      tiles
    } = layer

    if (!dim) {
      return
    }

    const a = 360 / (dim[0] || 360)
    const b = 360 / (dim[1] || 360)

    const xfrom = Math.floor(normalizeAngle(lon - 90) / a)
    const xto = Math.floor(normalizeAngle(lon + 90) / a)

    const yfrom = Math.floor(normalizeAngle(180 - lat - 120) / b)
    const yto = Math.floor(normalizeAngle(180 - lat + 120) / b)

    // console.log("apply lon", lon, xfrom, xto)
    // console.log("apply lat", lat, yfrom, yto)

    const intersected = (x, from, to) => ignore || (from > to ? (x >= from || x <= to) : (x >= from && x <= to))

    //const intersected = () => true
    
    let tileFinishingCount = dim[0] * dim[1]

    const increaseTileFinishingCount = x => {
      tileFinishingCount += x
      tileFinishingCount === 0 && onLayerLoaded?.(layer)
    }

    const getTileBy = (image, x, y, z) => {
      const { row, col } = tiles.find(({ row, col }) => row === x && col === y)
      const mesh = planesByRowCol[row + '_' + col]

      const url = buildTileUrlV3({ image: image.storage_path, x, y, z })

      !loadedTiles[url] && requests.push(() => promisify((resolve) => (new THREE.TextureLoader()).load(url, texture => {
        mesh.material.uniforms.baseTexture.value = texture
        mesh.material.uniforms.hasTexture.value = true
        mesh.material.needsUpdate = true
        resolve()
      }))
        .then(() => {
          increaseTileLoadingCount(-1)
          increaseTileFinishingCount(-1)
        })
        .catch(e => {
          console.error(e)

          loadedTiles[url] = false

          increaseTileLoadingCount(-1)
          increaseTileFinishingCount(-1)
        })
      )

      !loadedTiles[url] && increaseTileLoadingCount(1)

      !loadedTiles[url] && (loadedTiles[url] = true)
    }

    for (let x = 0; x < dim[0]; x++) {
      if (intersected(x, xfrom, xto)) {
        for (let y = 0; y < dim[1]; y++) {
          if (intersected(y, yfrom, yto)) {
            getTileBy(image, x, y, lastAppliedZoom)
          }
        }
      }
    }
  }

  const dispose = () => {
    meshes.map(x => x.dispose?.())
    meshes = []
  }

  return {
    applyImage,
    applyZoom,
    applyOrientation,
    dispose
  }
}
