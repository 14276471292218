<template>
    <div :class="classes.root"
         @click="click">
        <img class="wh-full rounded-md"
             :src="src">
    </div>
</template>

<script>
export default {
  props: {
    image: { type: Object, default: null },
    selected: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return {
        root: {
          'wh-8': true,
          'outline-1 outline-accent rounded-md': this.selected,
          'cursor-pointer': !this.selected
        }
      }
    },

    src() {
      return [this.image?.thumb, this.image?.src].find(is)
    }
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    }
  }
}
</script>
