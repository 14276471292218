import { labelAt } from '@/utils/date'

export const labels = {
  floor: 'этаж',
  point: 'точка',
  photo: 'фотография от'
}

export const formatPosition = ({ 
  project, 
  house, 
  floor, 
  definingPoint, 
  defining_point_image 
} = {}, { 
  separator = ', ' ,
  withoutProject = false 
} = {}) => {
  const result = [
    // project
    !withoutProject && project?.name,
    // house
    [
      house && !house.label && house.street,
      house && !house.label && house.number,
      house && !house.label && house.housing && `к${house.housing}`,
      house && house.label
    ].filter(x => x).join(' '),
    // floor
    floor && !floor.label && floor.number,
    floor && floor.label,
    // point
    definingPoint && definingPoint.name && `${labels.point} ${definingPoint.name}`,
    // photo
    defining_point_image && labelAt(defining_point_image.shot_at, { prefix: labels.photo, iso: true, withTime: false })
  ].filter(x => x).join(separator)

  return result
}

export const getPlanIdFromPosition = ({ floorPlan } = {}) => floorPlan?.id
export const getPointIdFromPosition = ({ definingPoint } = {}) => definingPoint?.id
export const getPhotoIdFromPosition = ({ defining_point_image } = {}) => defining_point_image?.id

export const hasTableau = ({ tableau_url }) => !!tableau_url
