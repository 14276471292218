<template>
    <keep-alive>
        <el-container direction="vertical"
                      style="height: 100%">
            <!--            <page-header-filter />-->
            <page-header :returnable="false">
                <template v-if="!readonly">
                    <!-- Upload photo -->
                    <access :permissions="permissionList['project.photo.management']"
                            :licence-abilities="licenceAbilitiesList['upload_photos_by_licence']"
                            hidable>
                        <el-button icon="el-icon-upload2"
                                   type="primary"
                                   @click="handlePhotoUpload">
                            Добавить фото
                        </el-button>
                    </access>

                    <!-- Upload photos -->
                    <access v-if="false"
                            :permissions="permissionList['project.photo.management']"
                            :licence-abilities="licenceAbilitiesList['upload_photos_by_licence']"
                            hidable>
                        <el-button type="primary"
                                   icon="el-icon-upload2"
                                   @click="showUploader">
                            Добавить фотографии (beta)
                        </el-button>
                    </access>
                </template>
            </page-header>

            <!-- Photos -->
            <el-main v-loading="loading"
                     v-infinite-scroll="loadPhotos"
                     infinite-scroll-disabled="disabled"
                     infinite-scroll-distance="100"
                     class="photos-container">
                <!-- Photo -->
                <card v-for="(photo, index) in photosState.photos"
                      :key="photo.id"
                      :photo="photo"
                      :index="index" />
                <!-- Loading caption -->
                <div v-if="photosState.totalCount"
                     class="total-counter py-1 px-2">
                    <span class="text-color--white caption">Загружено:{{ photosCount }}/{{ photosState.totalCount }}</span>
                </div>
            </el-main>

            <!-- Uploader -->
            <uploader :opened="uploading"
                      @close="hideUploader"
                      @load="applyUploader" />
        </el-container>
    </keep-alive>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import PageHeader from '@/components/layout/PageHeader';

import {imagePathShortFilter} from '@/filters/photo';
import Card from '@/components/photos/PhotoCard'

import Uploader from '@/components/photos/PhotoUploader.vue'

export default {
  name: 'ProjectPhotos',
  components: {
    PageHeader,
    Card,
    Uploader
  },
  filters: {imagePathShortFilter},
  mixins: [],
  props: {
    taskFilter: {
      type: String,
      default: ''
    },
    photoRoute: {
      type: String,
      default: 'project.photo'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      uploading: false,

      //TODO Это возможно нужно будет выпилить
      filter: {project: null}
    };
  },
  computed: {
    ...mapGetters({
      project: 'project/structure',
      projectTree: 'project/projectTree',
      projectFilter: 'project/filter'
    }),

    projectFilter() {
      return !this.taskFilter ? this.$store.getters['project/projectFilter']('photos') : null;
    },
    photosCount() {
      return this.$store.getters['photos/photosCount'](this.currentPhotoType);
    },
    photosState() {
      return this.$store.getters['photos/photosState'](this.currentPhotoType);
    },
    currentPage() {
      return this.photosState.page.number;
    },
    disabled() {
      return this.loading || this.photosState.noMore;
    },
    selectedFilters() {
      return this.$store.getters['photos/selectedFilters'](this.currentPhotoType);
    },
    //TODO Выенсти в миксину или в какой-нибудь ютилс хелпер
    currentPhotoType() {
      if (!this.$route.params.projectId) {
        return 'allPhotos';
      }

      switch (this.taskFilter) {
      case 'hasNullConfirmedRecognitions':
        return 'unconfirmed';
      case 'hasInWorkTasks':
        return 'inwork'
      case 'hasFinishedTasks':
        return 'finished';
      default:
        return 'projectPhotos'
      }
    },
    defectImagesFilter() {
      return [
        'hasInWorkTasks',
        'hasFinishedTasks'
      ].indexOf(this.taskFilter) !== -1
        ? this.taskFilter
        : null;
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: function (filter) {
        this.setSelectedFilters({
          photoType: this.currentPhotoType,
          selectedFilters: filter
        });
      }
    },
    project: {
      immediate: true,
      handler() {
        if (this.project.id) {
          this.updateNavBar({
            ui_go_back: false,
            bar_photo: null,
            bar_classes: true
          });
        }
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    delete this.filter.filter;
    await this.setFilter({});
    await this.setSelectedFilters({
      photoType: this.currentPhotoType,
      selectedFilters: {...this.filter}
    });
    this.$root.$off('onFilterChange', this.filterChangeListener);
    next()
  },
  mounted() {
    this.$root.$on('onFilterChange', this.filterChangeListener)
  },
  created() {
    this.updateNavBar({
      ui_go_back: false,
      bar_photo: null,
      bar_classes: true
    });

    const filter = Object.assign({}, Object.keys(this.selectedFilters).length ? this.selectedFilters : this.filter);
    filter.project = this.$route.params.projectId || null;
    this.filter = filter;
  },
  methods: {
    ...mapMutations({
      setNoMore: 'photos/SET_NO_MORE',
      setPage: 'photos/SET_PAGE',
      setFilter: 'project/SET_FILTER',
      showForm: 'form/SHOW_FORM',
      clearLoadedPhotos: 'photos/CLEAR_LOADED_PHOTOS'
    }),
    ...mapActions({
      confirm: 'dialogs/confirmation/confirm',
      updateNavBar: 'navigator/update',
      clearNavBar: 'navigator/clear',
      getPhotos: 'photos/getPhotos',
      setSelectedFilters: 'photos/setSelectedFilters',
      getProfileLicense: 'auth/getProfileLicense'
    }),

    showUploader() {
      this.uploading = true
    },

    hideUploader() {
      this.uploading = false
    },

    applyUploader() {
      this.loadPhotos()
    },

    getPhotoLink(photo) {
      const projectId = this.project.id;
      if (!projectId) return {};

      return {
        name: this.photoRoute,
        params: {
          projectId,
          photoId: photo.id
        }
      };
    },
    async filterChangeListener() {
      await this.setSelectedFilters({
        photoType: this.currentPhotoType,
        selectedFilters: {
          ...this.filter,
          filter: {...this.projectFilter}
        }
      });
      if (!this.loading) {
        this.loadPhotos();
      }
    },
    handlePhotoUpload() {
      this.showForm({
        formName: 'photo-upload-form',
        payload: {point_selected: this.filter.points},
        callback: () => {
          this.getProfileLicense();
          this.clearLoadedPhotos();
          this.loadPhotos();
        }
      });
    },
    handlePhotoEdit(image) {
      this.showForm({
        formName: 'photo-upload-form',
        payload: {photo_id: image.id}
      });
    },
    loadPhotos() {
      if (this.photosState.noMore) {
        return;
      }

      if (this.photosState.photos.length) {
        this.setPage({
          photoType: this.currentPhotoType,
          number: this.currentPage + 1
        })
      }

      this.loading = true;
      this.getPhotos({
        photoType: this.currentPhotoType,
        payload: {params: this.createRequestParams()}
      })
        .finally(() => {
          this.loading = false;
        });
    },
    createRequestParams() {
      let filter = this.createFilterParams();
      let append = ['unread_changes_exists', 'project_point_position'];

      const hasTaskFilter = !!this.taskFilter;
      if (!hasTaskFilter) {
        append = [...append, 'recognition_history_exists', 'user_marks_exists']
      }

      return {
        ...filter,
        filter: {...this.projectFilter},
        append,
        include: [
          'image',
          'image.source_info'
        ],
        fields: {
          'defining_point_images': [
            'id',
            'defining_point_id'
          ],
          'image': [
            'id',
            'name',
            'imageable_type',
            'imageable_id',
            'storage',
            'storage_thumb_url', 'shot_at', 'created_at'
          ],
          'image.source_info': [
            'id',
            'image_id',
            'name'
          ]
        },
        sort: '-image.shot_at',
        page: this.photosState.page
      }
    },
    createFilterParams() {
      let filter = {'filter[belongsToProject]': []};

      Object.keys(filter)
        .forEach(key => {
          if (filter[key].length > 0) {
            filter[key] = filter[key].join(',');
          } else {
            delete filter[key];
          }
        });

      if (this.defectImagesFilter) {
        filter[`filter[${this.defectImagesFilter}]`] = true;
      }

      if (this.filter.project) {
        filter['filter[belongsToProject]'] = this.filter.project;
      } else {
        delete (filter['filter[belongsToProject]']);
      }

      return filter;
    }
  }
};
</script>

<style lang="scss">
.total-counter {
  position: absolute;
  border-radius: 4px;
  right: 60px;
  bottom: 16px;
  height: auto;
  z-index: 99;
  background-color: rgba(0, 0, 0, .8);
}

.photos-container {
  display: grid !important;
  grid-template-rows: min-content;
  grid-gap: 1rem;

  overflow-y: scroll;

  /* M */
  @media only screen and (min-width: 40.063em) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  /* L */
  @media only screen and (min-width: 64.063em) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  /* XL */
  @media only screen and (min-width: 90.063em) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}
</style>
