<template>
    <div v-if="value"
         class="abs f-v-center t-0.5 r-0.5 bg-rock depth-8 rounded space-x-0.5 _px-1 h-2">
        <!-- Loader -->
        <loader v-if="loading" />

        <!-- Label -->
        <our-label :value="value"
                   dark />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Loader from '@/elements/Loader'

const labels = {
  aboutDefectPoint: 'После указания позиции на плане вы перейдете к созданию задачи',
  aboutAcceptanceLoading: 'Загружаются принимаемые работы',
  aboutPointsLoading: 'Загружаются точки съемки',
  aboutTileLoading: 'Загружается план этажа'
}

const last = all => all[all.length - 1]

export default {
  components: {
    Loader
  },
  props: {
    tileLoading: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('viewer/plan', [
      'isDefect'
    ]),
    ...mapGetters('floorPlans', ['planPointsLoading', 'polygonsLoading']),
    ...mapGetters('tasks', ['taskLoading']),

    value() {
      return last([
        this.isDefect && labels.aboutDefectPoint,
        this.planPointsLoading && labels.aboutPointsLoading,
        (this.taskLoading || this.polygonsLoading) && labels.aboutAcceptanceLoading,
        this.tileLoading && labels.aboutTileLoading
      ].filter(x => x))
    },

    loading() {
      return this.planPointsLoading || this.taskLoading || this.polygonsLoading || this.tileLoading
    }
  }
}
</script>
