import ProjectHome from '@/views/projects/ProjectHome'
import ProjectStatistics from '@/views/projects/Statistics';
import HeatMap from '@/views/projects/HeatMap';
import ProjectGantt from '@/views/projects/Gantt';
import ProjectPhotos from '@/views/projects/Photos';
import ProjectPhotoEditor from '@/views/projects/PhotoEditor';
import ProjectPlanEditor from '@/views/projects/PlanEditor';
import TasksChildren from '@/router/router.tasks';
import Tasks from '@/views/tasks/Tasks';
import TaskChildren from '@/router/router.task';
import ApprovalChildren from '@/router/router.approval';
import Task from '@/views/tasks/Task';
import TasksCalendar from '@/views/tasks-calendar/TasksCalendar';
import HeatMapV2 from '@/views/projects/HeatMapV2';
import GprEditor from '@/views/projects/GprEditor';
import WorkSchedule from '@/views/projects/WorkSchedule';
import FacadeEditor from '@/views/projects/FacadeEditor';
import FacadePhotoViewer from '@/views/projects/FacadePhotoViewer';
import ProjectDashboardOld from '@/views/projects/DashboardOld';
import ProjectDashboard from '@/views/projects/Dashboard';
import Documents from '@/views/projects/Documents';
import JobsProgressMock from '@/views/projects/JobsProgressMock';
import Settings from '@/views/projects/Settings';
import SettingsChildren from '@/router/router.settings';
import ApprovalRoot from '@/views/projects/ApprovalRoot.vue';

import NotificationLCR from '@/views/projects/NotificationLCR'
import NotificationDPW from '@/views/projects/NotificationDPW'

import PhotoComment from '@/views/projects/PhotoComment'

const GprImport = () => import('@/components/dialogs/GprImport')
const PremisesForm = () => import('@/components/forms/PremisesForm')
const PremisesInfoDrawer = () => import('@/components/map/PremisesInfoDrawer')

import { LICENCE_ABILITIES } from '@/utils/licences'
import { event } from '@/utils/immutable';

import projectAbout from '@/router/project.about'

const tutorialShowEvent = event()

const routes = [
  {
    name: 'project.home',
    path: '',
    component: ProjectHome,
    meta: {
      title: 'Главная',
      leftSideMenu: {
        on: true,
        name: 'project'
      },
      tutorial: {
        show: () => tutorialShowEvent.emit(),
        onShow: f => tutorialShowEvent.onWithClear(f)
      }
    },
    children: [
      {
        name: 'project.notifications.lcr',
        path: 'notifications/lcr',
        component: NotificationLCR,
        meta: {
          title: 'Нет строительной готовности',
          leftSideMenu: {
            on: true,
            name: 'project'
          }
        }
      },
      {
        name: 'project.notifications.dpw',
        path: 'notifications/dpw',
        component: NotificationDPW,
        meta: {
          leftSideMenu: {
            on: true,
            name: 'project'
          }
        }
      }
    ]
  },
  {
    name: 'project.statistics',
    path: 'statistics',
    component: ProjectStatistics,
    meta: {
      title: 'Статистика',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.heatmap',
    path: 'heatmap',
    component: HeatMap,
    meta: {
      title: 'Heat Map',
      access: { licenceAbility: LICENCE_ABILITIES['show_recognized_objects_statistic_by_licence'] },
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.gantt',
    path: 'gantt',
    component: ProjectGantt,
    meta: {
      title: 'План/Факт',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.photos',
    path: 'photos',
    component: ProjectPhotos,
    meta: {
      title: 'Фотографии',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.photo',
    path: 'photo/:photoId',
    component: ProjectPhotoEditor,
    meta: {
      title: 'Редактор фотографии',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    },
    children: [
      {
        name: 'project.photo.comment',
        path: 'comment',
        component: PhotoComment,
        props: true,
        meta: {
          title: 'Редактор фотографии',
          leftSideMenu: {
            on: true,
            name: 'project'
          }
        }
      }
    ]
  },
  {
    name: 'project.plan',
    path: 'plan/:planId',
    component: ProjectPlanEditor,
    props: true,
    meta: {
      title: 'Редактор плана',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    },
    children: [
      {
        name: 'project.plan.premises.create',
        path: 'premises/create',
        component: PremisesForm,
        meta: {
          title: 'Редактор плана',
          leftSideMenu: {
            on: true,
            name: 'project'
          }
        }
      },
      {
        name: 'project.plan.premises.info',
        path: 'premises/info',
        component: PremisesInfoDrawer,
        meta: {
          title: 'Редактор плана',
          leftSideMenu: {
            on: true,
            name: 'project'
          }
        }
      }
    ]
  },
  {
    name: 'project.work-plan',
    path: 'work-plan/:planId',
    component: ProjectPlanEditor,
    props: true,
    meta: {
      title: 'Редактор плана',
      planType: 'work',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.plan.rooms',
    path: 'plan/:planId/rooms/:roomId',
    component: ProjectPlanEditor,
    props: true,
    meta: {
      title: 'Редактор плана',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.tasks',
    path: 'tasks',
    component: Tasks,
    redirect: { name: 'project.tasks.defects' },
    children: TasksChildren,
    props: true,
    meta: {
      title: 'Список задач',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.task',
    path: 'task/:taskId',
    component: Task,
    redirect: { name: 'project.task.info' },
    children: TaskChildren,
    props: true,
    meta: {
      title: 'Задача',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.calendar',
    path: 'calendar',
    component: TasksCalendar,
    meta: {
      title: 'Календарь задач',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.heatmap.v2',
    path: 'heatmap-v2',
    component: HeatMapV2,
    meta: {
      title: 'HeatMapV2',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.gpr',
    path: 'gpr',
    component: WorkSchedule,
    meta: {
      title: 'ГПР',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    },
    children: [
      {
        name: 'project.gpr.compare',
        path: 'compare',
        component: GprImport,
        props: true,
        meta: {
          title: 'Импорт ГПР',
          leftSideMenu: {
            on: true,
            name: 'project'
          }
        }
      }
    ]
  },
  {
    name: 'project.gpr-editor',
    path: 'gpr-editor',
    component: GprEditor,
    props: true,
    meta: {
      title: 'Создание ГПР',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    },
    children: [
      {
        name: 'project.gpr-editor.import',
        path: 'import',
        component: GprImport,
        props: true,
        meta: {
          title: 'Импорт ГПР',
          leftSideMenu: {
            on: true,
            name: 'project'
          }
        }
      }
    ]
  },
  {
    name: 'project.point',
    path: 'point/:pointId',
    component: FacadePhotoViewer,
    props: true,
    meta: {
      title: 'Просмотр Фасада',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.point.photo',
    path: 'point/:pointId/photo/:photoId',
    component: FacadeEditor,
    meta: {
      title: 'Редактор Фасада',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.dashboard-old',
    path: 'dashboard-old',
    component: ProjectDashboardOld,
    meta: {
      title: 'Дашборд',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.about',
    path: 'about',
    component: ProjectDashboard,
    redirect: { name: 'project.about.common' },
    meta: {
      title: 'Дашборд',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    },
    children: projectAbout
  },
  {
    name: 'project.documents',
    path: 'documents',
    component: Documents,
    meta: {
      title: 'Документация',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.settings',
    path: 'settings',
    component: Settings,
    children: SettingsChildren,
    redirect: { name: 'settings.common' },
    meta: {
      title: 'Настройки',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.jobs.progress',
    path: 'jobs-progress',
    component: JobsProgressMock,
    meta: {
      title: 'Ход СМР',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.approval',
    path: 'approval',
    component: ApprovalRoot,
    redirect: { name: 'project.approval.list' },
    children: ApprovalChildren,
    props: true,
    meta: {
      title: 'Согласование',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  }
];
export default routes;
