<template>
    <div>
        <el-dropdown v-loading="loading"
                     split-button
                     :class="[showMark ? 'is-active' : '']"
                     @click="openModel()">
            BIM
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item of bims"
                                  :key="item.id"
                                  :disabled="!item.bim_model.processed"
                                  @click.native="showBimViewer(item)">
                    {{ item.bim_model.name }}
                </el-dropdown-item>
                <el-dropdown-item v-if="!bims.length"
                                  @click.native="addModel">
                    Привязать модель
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <span v-if="false"
              style="padding-left: 8px; padding-right: 8px;">
            <el-tooltip content="Включить режим приемки работ"
                        placement="bottom">
                <el-button v-if="showMark"
                           type="text"
                           @click="acceptedWorks">{{ acceptedWorksMode ? 'Сохранить' : 'Приемка работ' }} </el-button>
            </el-tooltip>
            <el-tooltip content="Включить режим приемки работ"
                        placement="bottom">
                <el-button v-if="showMark && acceptedWorksMode"
                           type="text"
                           @click="acceptedWorks">Отмена</el-button>
            </el-tooltip>
        </span>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
  name: 'PilotCloudViewerButton',
  computed: {
    ...mapGetters({
      getFloorPlan: 'floorPlans/floor',
      structureTree: 'project/structureTree',
      acceptedWorksMode: 'pilotCloudViewer/acceptedWorksMode'
    }),
    bimURN () {
      return 0
    }
  },
  props: {
    showMark: {
      type: Boolean
    }
  },
  data () {
    return {
      viewer: null,
      revertStructure: [],
      loading: false,
      bims: []
    }
  },
  watch: {
    getFloorPlan (elem) {
      this.loadBims()
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      setModeAcceptedWorks: 'pilotCloudViewer/SET_MODE_ACCEPTED_WORKS',
      setSelectedBim: 'pilotCloudViewer/SET_SELECTED_BIM'
    }),
    ...mapMutations({
      showForm: 'form/SHOW_FORM'
    }),
    openModel () {
      if(!this.bims.length) return this.addModel()
      if(this.showMark) {
        this.closeBim();
        this.$nextTick(() => window.dispatchEvent(new Event('resize')));

      } else this.showBimViewer(this.bims[0])
    },
    acceptedWorks () {
      if(this.acceptedWorksMode) {
        const callback = () => {
          // this.handleGetProjectGprJobs(this.lastGprVersion.id);
        };
        this.showForm({
          formName: 'accepted-works',
          formTitle: 'Приемка работ',
          action: callback,
          callback: callback
        });
      } else {
        this.$notify.info({
          title: 'Приемка работ',
          message: `Поочередно выберите объекты которые следует принять, они выделяются желтым цветом, после чего
          нажмите кнопку сохранить. Для отмены выбора нажмите на объект еще раз, для выхода без сохранения
          нажмите кнопку Отмена.`,
          duration: 9000
        });
      }
      this.setModeAcceptedWorks()
    },
    isSetControlPoints (model) {
      return model?.control_points[0]?.def_point_id
    },
    // Выбор модели в документации
    addModel () {
      this.$router.push({
        name: 'project.documents'
      })
    },

    async showBimViewer(model) {
      if(this.showMark) this.closeBim()
      if(model.bim_model.status !== 'processed') return this.$message({
        type: 'error',
        message: 'Ссылка на BIM  не найдена, возможно документ все еще обрабатывается'
      });

      this.$nextTick(() => {
        this.setSelectedBim(model)
        this.$emit('open')
      })
    },
    closeBim () {
      this.$emit('open')
    },
    findStructureItem (arr, itemId, nestingKey = 'children', previous) {
      return arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === itemId) return {...item, previous }
        if (item[nestingKey]) return this.findStructureItem(item[nestingKey], itemId, nestingKey, item)
      }, null)
    },
    getRevertStructure (structureTree, structureItem) {
      let result = this.findStructureItem(structureTree, structureItem)
      this.revertStructure.push(result)
      if(result.previous) this.getRevertStructure(structureTree, result.previous?.id)
    },
    async loadBims() {
      this.revertStructure = []
      this.getRevertStructure(this.structureTree, this.getFloorPlan?.id)

      this.loading = true
      await Promise.all(this.revertStructure.map(item => {
        if(item.type === 'object' || item.type === 'territory') item.type = 'house'
        if(item.type === 'plan') item.type = 'floor_plan'

        return this.$api.bim.getBimModelsList({
          projectId: this.$route.params.projectId,
          structureType: item.type,
          structureId: item.id
        });
      })).then(responses => {
        let bims = []
        responses.forEach(item => {
          if(item.data?.data.length) {
            bims = bims.concat(item.data?.data)
          }
        })

        this.bims = bims.sort(function(a, b) {
          return new Date(a.bim_model.created_at) < new Date(b.bim_model.created_at);
        })

      }).catch(response => {
        console.log('catch', response)
      }).finally(() => this.loading = false)

    }

  }
}
</script>

<style lang="scss" scoped>
.viewer {
  position: relative;
  width: 100%;
  height: 93vh;
  margin: auto;
}

.photo-joystick {
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
  z-index: 99;
}
.bar {
  position: absolute;
  z-index: 999;
  background: #ffffffa1
}

::v-deep {
  #toolbar-orbitTools, #toolbar-panTool, #toolbar-zoomTool {
    display: block !important;
  }
  #modelTools {
    display: none;
  }
  #settingsTools {
    display: none;
  }

  .el-dropdown .el-button {
    background-color: #313131;
    border-color: #313131;
    color: white;
  }

  .el-dropdown.is-active {
    .el-button:first-child {
      background: #409eff;
    }
  }

  .custom-adsk-button {
    width: 28px!important;
    line-height: 1.4!important;
    height: 31px!important;
  }
}
</style>
