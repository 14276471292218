import * as datetimeUtils from '@/utils/datetime';

import { labelAt } from '@/utils/date'

const toRu = d => datetimeUtils.dateToLocal(d) || 'Неизвестно'

export function imagePathFilter(photoData) {
  if (!photoData || !photoData.project_point_position) {
    return ''
  }

  return [
    `Строение № ${photoData.project_point_position.house.number}`,
    `Этаж ${photoData.project_point_position.floor.number}`,
    `План от ${toRu(photoData.project_point_position.floorPlan.created_at)}`,
    `Реперная точка ${photoData.project_point_position.definingPoint.name}`
  ].join(' / ')
}

export function imagePathShortFilter(photoData) {
  if (!photoData || !photoData.project_point_position) {
    return ''
  }

  return [
    `Строение ${photoData.project_point_position.house.number}`,
    `Этаж ${photoData.project_point_position.floor.number}`,
    `План от ${toRu(photoData.project_point_position.floorPlan.created_at)}`,
    `Точка ${photoData.project_point_position.definingPoint.name}`
  ].join(' / ')
}

export function imagePathWithDatesFilter(photoData) {
  if (!photoData || !photoData.project_point_position) {
    return ''
  }

  return [
    `Строение № ${photoData.project_point_position.house.number}`,
    `Этаж ${photoData.project_point_position.floor.number}`,
    `План от ${toRu(photoData.project_point_position.floorPlan.created_at)}`,
    `Реперная точка ${photoData.project_point_position.definingPoint.name}`,
    `Дата загрузки: ${toRu(photoData.created_at)}`,
    `Дата съемки: ${toRu(photoData.image.shot_at)}`
  ].join(' / ')
}

export function imagePathShortWithDatesFilter(photoData) {
  if (!photoData || !photoData.project_point_position) {
    return ''
  }

  return [
    `Строение ${photoData.project_point_position.house.number || `на улице ${photoData.project_point_position.house.street}`}`,
    `Этаж ${photoData.project_point_position.floor.number}`,
    `План от ${toRu(photoData.project_point_position.floorPlan.created_at)}`,
    `Точка ${photoData.project_point_position.definingPoint.name}`,
    `Дата загрузки: ${labelAt(photoData.created_at, { withCapital: true })}`,
    photoData.image?.shot_at && `Дата съемки: ${labelAt(photoData.image?.shot_at, { zoned: false, withCapital: true })}`
  ].filter(x => x).join(' / ')
}
