import { toggleCapital } from '../utils/common';
import { parse, format, labelAt } from '../utils/date'
import { key } from '../utils/immutable'

import { TASK_TYPES, TASK_STATUSES, DEFECT_KINDS, TASK_ACTIONS, TASK_MEMBER_SUB_ROLES } from '../utils/tasks'

import { formatPosition } from './projects';

// TODO@deprecated
export const types = TASK_TYPES

export const type = TASK_TYPES

// TODO@deprecated
export const statuses = TASK_STATUSES

export const status = TASK_STATUSES

// TODO@deprecated
export const actions = TASK_ACTIONS

export const action = TASK_ACTIONS

// TODO@deprecated
export const memberSubRoles = TASK_MEMBER_SUB_ROLES

export const memberSubRole = TASK_MEMBER_SUB_ROLES

export const subRoles = TASK_MEMBER_SUB_ROLES

// TODO@deprecated
export const kinds = DEFECT_KINDS

export const kind = DEFECT_KINDS

export const state = {
  DRAFT: 'draft',

  WAIT_WORKER: 'wait_worker',
  WAIT_INSPECTOR: 'wait_inspector',
  WAIT_CONFIRMATION: 'wait_confirmation',

  REFUSED: 'refused',
  CANCELED: 'canceled',

  IN_WORK: 'in_work',
  IN_WORK_SECOND_STAGE: 'in_work_second_stage',

  REVIEW: 'review',

  FINISHED: 'finished',
  FINISHED_NOT_ACCEPTED: 'finished_not_accepted',

  WAIT_WORKER_SECOND_STAGE: 'wait_worker_second_stage',
  WAIT_CHECK_PROJECT_WORK_PRODUCTION: 'wait_check_project_work_production',

  ARCHIVED: 'archived'
}

export const states = state

export const roles = {
  INITIATOR: 'initiator',
  WORKER: 'worker',
  INSPECTOR: 'inspector',
  MANAGER: 'manager'
}

export const memberTypes = {
  CONTRACTOR: 'contractor',
  ENGINEER: 'engineer'
}

export const labels = {
  commentEngineer: 'Комментарий инженера СК Г/П:',
  commentGeneralEngineer: 'Комментарий инженера СКЗ:',
  commentContractor: 'Комментарий подрядчика:'
}

export const getName = task => task?.name
export const getCreatedAtLabel = (task, { prefix, withCapital } = {}) => labelAt(task?.created_at, { prefix, withCapital })
export const getDefectTypes = task => task?.data?.defect_types || []

export const getInitiatorUser = task => (task?.members || []).find(({ role }) => role === roles.INITIATOR)?.user

export const getWorkers = task => (task?.members || []).filter(({ role }) => role === roles.WORKER)

export const getWorkerUser = task => (task?.members || []).find(({ role }) => role === roles.WORKER)?.user
export const getInspectorUser = task => (task?.members || []).find(({ role }) => role === roles.INSPECTOR)?.user
export const getInspectorUsers = task => (task?.inspectors || (task?.members || []).filter(({ role }) => role === roles.INSPECTOR)).map(({ user }) => user)
export const getManagerUser = task => (task?.members || []).find(({ role }) => role === roles.MANAGER)?.user
export const getWorkerOrAnotherUser = task => getWorkerUser(task) || (task?.members || [])[0]?.user

export const getContractorUser = task => (task?.members || []).find(({ sub_role }) => [
  subRoles.DEFECT_CONTRACTOR,
  subRoles.DEFECT_CONTRACTOR_SECOND_STAGE
].includes(sub_role))?.user

export const getEngineerUser = task => (task?.members || []).find(({ sub_role }) => [
  subRoles.DEFECT_ENGINEER,
  subRoles.DEFECT_ENGINEER_SECOND_STAGE,
  subRoles.WORKER_ACCEPTANCE_TASK_CONTROL_ENGINEER_FIRST_STAGE
].includes(sub_role))?.user

export const getGeneralEngineerUser = task => (task?.members || []).find(({ sub_role }) => [
  subRoles.WORKER_ACCEPTANCE_TASK_CONTROL_ENGINEER_SECOND_STAGE
].includes(sub_role))?.user

export const getApprovalWorkerUser = task => [
  (task?.members || []).find(({ sub_role }) => sub_role === subRoles.WORKER_APPROVAL_PROCESS_TASK_ACTUAL_ITERATION), 
  (task?.members || []).find(({ sub_role }) => sub_role === subRoles.INITIATOR_APPROVAL_PROCESS_TASK_CHIEF_PROJECT_ENGINEER),
  (task?.members || []).find(({ sub_role }) => sub_role === subRoles.INITIATOR_APPROVAL_PROCESS_TASK_CREATOR)
][0]?.user

export const getWorkerComment = task => task?.data.comment_worker

export const getPlanId = task =>
  task.data.project_point_position && task.data.project_point_position.floorPlan
    ? task.data.project_point_position.floorPlan.id
    : '';

export const getPointId = task => task?.data?.defining_point_id

export const getProjectId = task => task?.project_id

export const getPhotoId = task => task?.data?.defining_point_image_id

export const getPointPath = task => formatPosition(task?.data?.project_point_position) 

export const getEntityId = object => object?.object_id

export const getEntities = task => (task?.data?.objects || []).map(object => ({
  key: key(),
  id: object.object_id,
  type: object.object_type,
  label: formatPosition(object.project_object_structure)
}))

export const getRelatedObjects = (task, { withLabels, withPrefixes }) => 
  (task?.data?.linked_project_objects || [])
    .map(({ id, type, object_id, object_type, created_at, data: { image_url, image_name, project_point_position } = {} }) => ({
      id,
      type,
      object_id,
      object_type,
      image: image_url,
      thumb: image_url,
      name: image_name,
      created_at: parse(created_at, { iso: true }),
      position: project_point_position,

      ...withLabels && { 
        labels: {
          created_at: labelAt(created_at, { prefix: withPrefixes && 'Обнаружено ', iso: true, zoned: false }),
          position: formatPosition(project_point_position)
        } 
      }
    }))

export const getCaptures = (task, { withLabels, withPrefixes }) => 
  getRelatedObjects(task, { withLabels, withPrefixes })
    .filter(({ type, object_type, image }) => image && type === 'defect_project_object' && ['task_image', 'defining_point', 'image_defect'].includes(object_type))

export const getThumb = task => task['data']['image_defect_url']
        || task['data']['image_defining_point_thumb_url']
        || task['data']['acceptance_work_defect_image'] && task['data']['acceptance_work_defect_image']['storage_thumb_url']

export const getPhoto = task => task['data']['image_defect_url']
        || task['data']['image_defining_point_url']
        || task['data']['image_defining_point_thumb_url']
        || task['data']['acceptance_work_defect_image'] && task['data']['acceptance_work_defect_image']['storage_url']

export const hasBookedSchedule = task => {
  return task?.data?.booked_schedules && task.data.booked_schedules.length
}

export const getBookedSchedule = task => hasBookedSchedule(task) 
  ? task.data.booked_schedules.map(({ start_at, end_at }) => [
    parse(start_at, { iso: true }),
    parse(end_at, { iso: true })
  ]) 
  : []

export const getBookedScheduleLabel = (task, { prefix } = {}) => {
  const slots = getBookedSchedule(task)

  const into = at => format(at, { withDate: false, withSeconds: false })

  return [
    prefix,
    slots.map(([from, to]) => `${into(from)} - ${into(to)}`).join(', ')
  ].filter(x => x).join(' ')
}

export const getMembersForSelection = task => {
  const members = task.members || []

  return [
    ...[members.find(({ sub_role }) => [subRoles.DEFECT_CONTRACTOR, subRoles.DEFECT_CONTRACTOR_SECOND_STAGE].includes(sub_role))].filter(is).map(({ user }) => ({
      id: key(),
      value: memberTypes.CONTRACTOR,
      label: 'Подрядчик',
      user: {
        ...user,
        _isUser: true
      }
    })),
    ...[members.find(({ sub_role }) => [subRoles.DEFECT_ENGINEER, subRoles.DEFECT_ENGINEER_SECOND_STAGE].includes(sub_role))].filter(is).map(({ user }) => ({
      id: key(),
      value: memberTypes.ENGINEER,
      label: 'Инженер СК/ГП',
      user: {
        ...user,
        _isUser: true
      }
    }))
  ]
}

export const isInWork = task => task.status === TASK_STATUSES.IN_WORK
export const isWaitInformation = task => task.status === TASK_STATUSES.WAIT_INFORMATION

export const isDefectCommon = task => task?.type === types.DEFECTS_AND_VIOLATIONS && !isDefectByPoint(task)
export const isDefectByPoint = task => task?.type === types.DEFECTS_AND_VIOLATIONS && !!getPointId(task)

export const hasCriticalDefect = task => task?.data?.kind_defect === DEFECT_KINDS.CRITICAL
export const hasSignificantDefect = task => task?.data?.kind_defect === DEFECT_KINDS.SIGNIFICANT
export const hasDefaultDefect = task => !task?.data?.kind_defect || task?.data?.kind_defect === DEFECT_KINDS.REMOVABLE

export const translateType = (type, { plural = false, capital = false } = {}) => ({
  [TASK_TYPES.DEFECTS_AND_VIOLATIONS]: toggleCapital(plural ? 'дефекты' : 'дефект', capital),
  [TASK_TYPES.BUILDING_ORDER]: toggleCapital(plural ? 'предписания' : 'предписание', capital),
  [TASK_TYPES.ACCEPTANCE_OF_WORK]: toggleCapital(plural ? 'приемка работ' : 'приемка работ', capital),
  [TASK_TYPES.PHOTO_ORDER]: toggleCapital(plural ? 'заказы снимков' : 'заказ снимков', capital),
  [TASK_TYPES.APPROVAL_PROCESS]: toggleCapital(plural ? 'процессы согласования' : 'процесс согласования', capital)
})[type] || 'Неизвестно'

export const translateStatus = status => ({
  [TASK_STATUSES.NEW]: 'Новые',
  [TASK_STATUSES.WAIT_INFORMATION]: 'Требуется информация',
  [TASK_STATUSES.IN_WORK]: 'В работе',
  [TASK_STATUSES.COMPLETED]: 'Выполненные',
  [TASK_STATUSES.FINISHED]: 'Завершенные',
  [TASK_STATUSES.FINISHED_NOT_ACCEPTED]: 'Не принята работа',
  [TASK_STATUSES.REFUSED]: 'Отказ'
})[status] || 'Неизвестно'

export const translateKind = (x, { many } = {}) => ({
  [DEFECT_KINDS.REMOVABLE]: many ? 'Устранимые' : 'Устранимый',
  [DEFECT_KINDS.SIGNIFICANT]: many ? 'Значительные' : 'Значительный',
  [DEFECT_KINDS.CRITICAL]: many ? 'Критические' : 'Критический'
})[x] || 'Неизвестно'

export const translateFilter = (filter = {}) => {
  const { type, name, created_from, created_to, expired_from, expired_to, started_from, started_to, status } = filter

  const creator = filter['creator.name']
  const contractor = filter['memberWorkers.user.name']
  const inspector = filter['defectGeneralContractor.name']
  const buildingOrder = filter['objectsBuildingOrderConnection.object.name']

  const to = (x, y) => ({ id: key(), name: x, value: y })

  return [
    type && to('Выбраны', translateType(type, { plural: true })),

    status && to('Со статусом', translateStatus(status).toLowerCase()),

    name && to('Наименование', name),

    created_from && to('Созданные с', labelAt(created_from, { withTime: false, withAlias: false })),
    created_to && to('По', labelAt(created_to, { withTime: false, withAlias: false })),
    expired_from && to('Истекающие с', labelAt(expired_from, { withTime: false, withAlias: false })),
    expired_to && to('По', labelAt(expired_to, { withTime: false, withAlias: false })),
    started_from && to('Приемка с', labelAt(started_from, { withTime: false, withAlias: false })),
    started_to && to('Приемка с', labelAt(started_to, { withTime: false, withAlias: false })),

    creator && to('Создатель', creator),
    inspector && to('Контроль', inspector),
    contractor && to('Подрядчик', contractor),

    buildingOrder && to('Предписание', buildingOrder)
  ].filter(x => x)
}

export const getNumberWithPrefix = task => task && `${{
  [types.ACCEPTANCE_OF_WORK]: 'ПР',
  [types.BUILDING_ORDER]: 'П',
  [types.DEFECTS_AND_VIOLATIONS]: 'Д',
  [types.PHOTO_ORDER]: 'О',
  [types.PROTOCOL]: 'ПРТ',
  [types.CHECKING_ACT]: 'А',
  [types.APPROVAL_PROCESS]: 'С'
}[task.type]}-${task.number_in_project}`

export const getTypeWithPostfix = (task, { capital } = {}) => task && `${translateType(task.type, { capital })} №${task.number_in_project}`

export const filterByNewAndWaitDefects = ({ type, status } = {}) => 
  type === TASK_TYPES.DEFECTS_AND_VIOLATIONS 
    && (status === TASK_STATUSES.NEW || status === TASK_STATUSES.WAIT_INFORMATION)

export const filterByDefects = ({ type } = {}) => type === TASK_TYPES.DEFECTS_AND_VIOLATIONS
export const filterByNewDefects = ({ type, status } = {}) => type === TASK_TYPES.DEFECTS_AND_VIOLATIONS && status === TASK_STATUSES.NEW
export const filterByClosedDefects = ({ type, status } = {}) => type === TASK_TYPES.DEFECTS_AND_VIOLATIONS && status === TASK_STATUSES.FINISHED

export const filterByBuildingOrder = ({ type } = {}) => type === TASK_TYPES.BUILDING_ORDER
export const filterByNewBuildingOrder = ({ type, status } = {}) => type === TASK_TYPES.BUILDING_ORDER && status === TASK_STATUSES.NEW
export const filterByClosedBuildingOrder = ({ type, status } = {}) => type === TASK_TYPES.BUILDING_ORDER && status === TASK_STATUSES.FINISHED

export const filterByPhotoOrder = ({ type } = {}) => type === TASK_TYPES.PHOTO_ORDER 
export const filterByNewPhotosOrder = ({ type, status } = {}) => type === TASK_TYPES.PHOTO_ORDER && status === TASK_STATUSES.NEW

export const filterByAcceptance = ({ type } = {}) => type === TASK_TYPES.ACCEPTANCE_OF_WORK
export const filterByNewAcceptance = ({ type, status } = {}) => type === TASK_TYPES.ACCEPTANCE_OF_WORK && status === TASK_STATUSES.NEW

export const reduceByInitiatorOrganizaion = (tasks = []) => tasks.reduce((r, x) => {
  const organization = x?.initiator?.user?.organization

  return { 
    ...r,
    ...organization && {
      [organization.id]: { 
        organization, 
        tasks: [ ...r[organization.id]?.tasks || [], x ]
      }
    }
  }
}, {})

export const reduceByWorkerOrganization = (tasks = []) => tasks.reduce((r, x) => {
  const organization = x?.data?.contractor?.organization || x?.workers?.[0]?.user?.organization

  return { 
    ...r,
    ...organization && {
      [organization.id]: { 
        organization, 
        tasks: [ ...r[organization.id]?.tasks || [], x ]
      }
    }
  }
}, {})

export const formatPhotoOrdersPlaces = (tasks = []) => 
  tasks
    .reduce((r, x) => [...r, `${x.data.places.reduce((r, x) => [...r, [x.h_street, x.h_number, x.f_number].filter(x => x).join(', ')], []).join('; ')}`], []).join('; ')

export const toPolygon = ({ id, type, planId, vertices }) => {
  const polygon = {
    id,
    floor_plan_id: planId,
    type,
    marks: vertices
  } 

  return polygon
}

export const getPolygons = task => (task?.data?.polygons || []).map(({ type, polygon, project_object_structure }) => ({
  id: polygon.id,
  type,
  createdAt: polygon.created_at,
  vertices: polygon.marks,
  position: project_object_structure
}))

export const fromPolygonsWithTasks = (polygons = []) => {
  return polygons.map(({ id, type, marks, tasks, created_at }) => ({
    id,
    type: type || tasks?.reduce?.((r, x) => [ ...r, ...x?.data?.polygons || [] ], [])?.find?.(({ polygon_id }) => id === polygon_id)?.['type'] || 'undefined',
    createdAt: created_at,
    vertices: marks,
    task: tasks?.[0],
    tasks
  }))
}

export const getPolygonsByFloors = task => {
  const polygons = getPolygons(task)

  const result = polygons
    .reduce((r, x) => {
      const floorId = x?.position?.floor.id

      return floorId ? {
        ...r,
        [floorId]: [
          ...r[floorId] || [],
          x
        ]
      } : r
    }, {})

  return result
}

export const filterFinishedPolygons = polygon => polygon.type === 'finish'
export const filterIntermediatePolygons = polygon => polygon.type === 'temp'

export const allowedFilesUploading = task => task?.allowed_upload_files
export const allowedFilesDeleting = task => task?.allowed_delete_files

export const getDocuments = task => [
  task?.data?.project_work_production
].filter(x => x)

export const getDocumentsByType = (task, type) => getDocuments(task).filter(({ type: x }) => x === type)

export const selectForCount = ({ type, by }) => selectors.forCount
  .filter(item => item.type === type && item[by] !== undefined)

export const getComments = task => [
  { if: task.type === types.BUILDING_ORDER, key: 'comment_at_send_for_revision', value: task?.data?.comment_at_send_for_revision, label: labels.commentEngineer },
  { if: task.type === types.BUILDING_ORDER, key: 'comment_at_send_to_review', value: task?.data?.comment_at_send_to_review, label: labels.commentGeneralEngineer },
  { if: task.type === types.BUILDING_ORDER, key: 'comment_at_complete', value: task?.data?.comment_at_complete, label: labels.commentContractor }
].filter(x => x.if && x.value)

export const toWorkerCreation = ({ type, members = [], by = [] }) => {
  const member = members.find(({ value }) => by.includes(value))

  return {
    ...type === types.DEFECTS_AND_VIOLATIONS && member?.user ? {
      worker_type: member.value,
      ...member.user._isUser && { worker_id: member.user.id },
      ...member.user._isOrganization && { worker_organization_id: member.user.id }
    } : {
      worker_type: null,
      worker_id: null,
      worker_organization_id: null
    }
  }
}

export const toWorkerUpdation = (task, { members, by }) => {
  const { type } = task || {}

  return toWorkerCreation({ type, members, by })
}

export const toImageUpdation = (task, { updatable, creatable }) => ({
  ...isDefectCommon(task) && { task_image_ids: [...updatable, ...creatable] },
  ...isDefectByPoint(task) && creatable.length && { image_defining_point_id: creatable[0] }
})

import { colors } from '@/utils/colors'

export const selectors = {
  forCount: [
    {
      type: types.DEFECTS_AND_VIOLATIONS,
      status: null,
      icon: 'archive',
      label: 'Всего',

      color: 'black-pavel-1',
      fill: colors['black-pavel-1']
    },
    {
      type: types.DEFECTS_AND_VIOLATIONS,
      status: [statuses.NEW, statuses.WAIT_INFORMATION, statuses.IN_WORK, statuses.COMPLETED],
      icon: 'activity',
      label: 'В работе',

      color: 'blue-pavel-2',
      fill: colors['blue-pavel-2']
    },
    {
      type: types.DEFECTS_AND_VIOLATIONS,
      status: [statuses.FINISHED, statuses.REFUSED],
      icon: 'check',
      label: 'Устранено',

      color: 'green-pavel-1',
      fill: colors['green-pavel-1']
    },
    {
      type: types.DEFECTS_AND_VIOLATIONS,
      kind: kinds.CRITICAL,
      icon: 'flash',
      label: 'Критические',

      color: 'red-pavel-1',
      fill: colors['red-pavel-1']
    },
    {
      type: types.DEFECTS_AND_VIOLATIONS,
      kind: kinds.SIGNIFICANT,
      icon: 'warning',
      label: 'Значительные',

      color: 'orange-pavel-1',
      fill: colors['orange-pavel-1']
    },
    {
      type: types.DEFECTS_AND_VIOLATIONS,
      kind: kinds.REMOVABLE,
      icon: 'gear',
      label: 'Устранимые',

      color: 'green-pavel-1',
      fill: colors['green-pavel-1']
    },
    {
      type: types.ACCEPTANCE_OF_WORK,
      state: null,
      icon: 'archive',
      label: 'Всего',

      color: 'black-pavel-1',
      fill: colors['black-pavel-1']
    },
    {
      type: types.ACCEPTANCE_OF_WORK,
      state: [state.WAIT_WORKER, state.IN_WORK, state.WAIT_CHECK_PROJECT_WORK_PRODUCTION, state.WAIT_WORKER_SECOND_STAGE, state.IN_WORK_SECOND_STAGE],
      icon: 'activity',
      label: 'В работе',

      color: 'blue-pavel-2',
      fill: colors['blue-pavel-2']
    },
    {
      type: types.ACCEPTANCE_OF_WORK,
      state: state.FINISHED,
      icon: 'check',
      label: 'Работы приняты',

      color: 'green-pavel-1',
      fill: colors['green-pavel-1']
    },
    {
      type: types.ACCEPTANCE_OF_WORK,
      state: state.FINISHED_NOT_ACCEPTED,
      icon: 'cross',
      label: 'Работы не приняты',

      color: 'red-pavel-1',
      fill: colors['red-pavel-1']
    },
    {
      type: types.ACCEPTANCE_OF_WORK,
      state: [state.REFUSED, state.CANCELED],
      icon: 'cross',
      label: 'Отмененные',

      color: 'green-pavel-2',
      fill: colors['green-pavel-2']
    },
    {
      type: types.BUILDING_ORDER,
      state: null,
      icon: 'archive',
      label: 'Всего',

      color: 'black-pavel-1',
      fill: colors['black-pavel-1']
    },
    {
      type: types.BUILDING_ORDER,
      state: [state.DRAFT, state.WAIT_WORKER, state.IN_WORK, state.REVIEW],
      icon: 'activity',
      label: 'В работе',

      color: 'blue-pavel-2',
      fill: colors['blue-pavel-2']
    },    
    {
      type: types.BUILDING_ORDER,
      state: state.FINISHED,
      icon: 'check',
      label: 'Завершенные',

      color: 'green-pavel-1',
      fill: colors['green-pavel-1']
    },
    {
      type: types.BUILDING_ORDER,
      state: state.CANCELED,
      icon: 'cross',
      label: 'Отмененные',

      color: 'red-pavel-1',
      fill: colors['red-pavel-1']
    }
  ]
} 
