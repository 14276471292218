import { types } from '@/models/work-schedule'
import { fromRequestError, fromError } from '../../utils/common';
import { resourceable, pagenable, actionable } from '../mixins';

const labels = {
  ungrouped: 'Без группы'
}

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'tags',
      from: ({ api, getters }) => 
        api.dirsV2.getTagsByProject({ project: getters['project/project'] })
          .then(response => response?.data?.data?.data || [])
    }),

    resourceable({
      name: 'jobs',
      from: ({ api, getters }, { project, withDeleted }) => 
        api.dirsV2.getJobsByProject({ project: project || getters['project/project'], withDeleted })
          .then(response => response?.data?.data?.data || [])
    }),

    resourceable({
      name: 'recognitionTypeTree',
      initial: {},
      from: ({ api }, { withCount }) => api.dirs.groupsTree({
        params: {
          append: [
            withCount && 'count_for_training'
          ].filter(x => x)
        }
      }).then(r => r.data)
    }),

    resourceable({
      name: 'notificationKinds',
      from: ({ api }) => api.dirsV2.getNotificationKinds()
    }),

    resourceable({
      name: 'workTypes',
      from: ({ api, getters }, { withoutCode }) => api.dirs.getWorkTypes({
        params: {
          filter: {
            project_id: getters['project/project'].id,
            ...withoutCode && { without_code: 1 }
          },
          page: {
            size: 0
          }
        }
      }).then(r => r.data.data || [])
    }),

    resourceable({
      name: 'workTypesAsTree',
      from: async ({ api, getters }) => {
        const workTypesWithChildren = await api.dirs.getWorkTypesAsTree({
          project: getters['project/project'],
          withChildren: true
        }).then(r => r.data.data || [])

        const workTypesWithoutOriginalCode = await api.dirs.getWorkTypes({
          params: {
            filter: {
              project_id: getters['project/project'].id,
              without_original_code: 1
            },
            page: {
              size: 0
            }
          }
        }).then(r => r.data.data || []) 

        return [
          ...workTypesWithChildren,
          ...workTypesWithoutOriginalCode.length ? [{
            name: labels.ungrouped,
            children: workTypesWithoutOriginalCode
          }] : []
        ]
      }
    }),

    resourceable({
      name: 'logActionTypes',
      from: ({ api }) => api.dirsV2.getLogActionTypes()
    }),

    actionable({
      name: 'addWorkTypesByFile',
      loadable: true,
      at: ({ api, getters }, { file, clearable }) => 
        api.dirs.addWorkTypesByFile({ project: getters['project/project'], file, clearable })
          .catch(fromRequestError)
    }),

    resourceable({
      name: 'jobTypesAsTree',
      loadable: true,
      from: ({ api, getters }, { gprType = types.DEFAULT }) => {
        const params = {
          project: getters['project/project']
        }

        let r

        r ||= gprType === types.DEFAULT && api.dirsV2.getJobTypesAsTree(params).then(x => x.data.data || [])
        r ||= gprType === types.SCALED && api.dirsV2.getJobTypesAsTreeForScaled(params).then(x => x.data.data || [])

        return r || Promise.resolve([])
      }
    }),

    pagenable({
      name: 'objects',
      loadable: true,
      from: ({ api, page, size }, { filter }) => api.dirsV2.getObjects({ filter, page, size }).then(x => x.data.data || {})
    }),

    resourceable({
      name: 'objectsForAccess',
      loadable: true,
      from: ({ api }, { filter }) => api.dirsV2.getObjectsForAccess({ filter })
        .then(x => x.data.data || [])
    }),

    actionable({
      name: 'updateObject',
      loadable: true,
      at: ({ api }, x) => api.dirsV2.updateObject(x).catch(fromError)
    }),

    actionable({
      name: 'createObject',
      loadable: true,
      at: ({ api }, x) => api.dirsV2.createObject(x).catch(fromError)
    }),

    actionable({
      name: 'removeObject',
      loadable: true,
      at: ({ api }, x) => api.dirsV2.removeObject(x)
    }),

    actionable({
      name: 'getJobType',
      loadable: true,
      at: ({ api }, { gprType = types.DEFAULT, id }) => {
        let r

        r ||= gprType === types.DEFAULT && api.dirsV2.getJobType(id).then(x => x.data.data || {})
        r ||= gprType === types.SCALED && api.dirsV2.getJobTypeForScaled(id).then(x => x.data.data || {})

        return r || Promise.resolve({})
      }
    }),

    actionable({
      name: 'storeJobType',
      loadable: true,
      at: ({ api }, { gprType = types.DEFAULT, item }) => {
        let r

        r ||= gprType === types.DEFAULT && api.dirsV2.storeJobType(item).then(x => x.data.data || {})
        r ||= gprType === types.SCALED && api.dirsV2.storeJobTypeForScaled(item).then(x => x.data.data || {})

        return r || Promise.resolve({})
      }
    }),

    actionable({
      name: 'updateJobType',
      loadable: true,
      at: ({ api }, { gprType = types.DEFAULT, item }) => {
        let r

        r ||= gprType === types.DEFAULT && api.dirsV2.editJobType(item.dirJobTypeId, item.payload).then(x => x.data.data || {})
        r ||= gprType === types.SCALED && api.dirsV2.editJobTypeForScaled(item.dirJobTypeId, item.payload).then(x => x.data.data || {})

        return r || Promise.resolve({})
      }
    }),

    actionable({
      name: 'deleteJobType',
      loadable: true,
      at: ({ api }, { gprType = types.DEFAULT, id }) => {
        let r

        r ||= gprType === types.DEFAULT && api.dirsV2.deleteJobType(id).then(x => x.data.data || {})
        r ||= gprType === types.SCALED && api.dirsV2.deleteJobTypeForScaled(id).then(x => x.data.data || {})

        return r || Promise.resolve({})
      }
    })
  ]
}
