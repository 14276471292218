<template>
    <div :style="styles.root">
        <!-- Image -->
        <div v-if="isDefectsType"
             class="_pr-0.9">
            <el-image v-if="image"
                      style="width: 100px; height: 100px;"
                      :src="image"
                      :preview-src-list="[image]"
                      fit="cover" />
            <el-skeleton v-else>
                <template slot="template">
                    <el-skeleton-item style="width: 100px; height: 100px;"
                                      variant="image" />
                </template>
            </el-skeleton>
        </div>

        <!-- Common -->
        <div class="f-col f-x-start space-y-0.25">
            <div>
                <!-- Number -->
                <el-tag :type="status.mood"
                        class="_mr-0.25 fs-0.7"
                        size="mini">
                    №{{ task.number_in_project }}
                </el-tag>

                <!-- Status -->
                <task-status :task="task" />

                <!-- Defect kind -->
                <el-tag v-if="isDefectsType && defect"
                        :type="defect.tagType"
                        class="fs-0.7"
                        size="mini">
                    {{ defect.kindName }}
                </el-tag>
            </div>

            <!-- Name -->
            <our-label :value="title"
                       :class="{
                           'hover:underline cursor-pointer': true,
                           'cross': task.is_deleted
                       }"
                       :dark="dark"
                       @click="goToTask(task)" />

            <div v-if="hasRelations || (isDefectsType && path)"
                 class="f-v-center f-wrap space-y-0.25">
                <!-- Relations (borrowed) -->
                <div v-for="(keys, index) in relations"
                     :key="index">
                    <div v-if="expandable && task[keys.data] && task[keys.data].length"
                         @click="goToRelatedTask(task, keys)">
                        <tag :class="[lastRelatedTask(task[keys.data])[keys.task].is_deleted ? 'text-decoration--line-through' : '']">
                            Связана: {{ lastRelatedTask(task[keys.data])[keys.task].name }}
                            от {{ lastRelatedTask(task[keys.data])[keys.task].created_at
                                | dateFormat(timeZone,'DD.MM.YYYY') }}
                        </tag>
                    </div>
                </div>

                <!-- Path to point -->
                <template v-if="isDefectsType && path">
                    <div v-if="photoId && projectId"
                         @click="$router.push({
                             name: 'project.photo',
                             params: {
                                 projectId: projectId,
                                 photoId: photoId
                             }
                         })">
                        <tag>
                            {{ path }}
                        </tag>
                    </div>
                    <div v-if="planId && pointId"
                         @click="$router.push({
                             name: 'project.plan',
                             params: {
                                 planId: planId,
                                 selection: [pointId]
                             }
                         })">
                        <tag>
                            {{ path }}
                        </tag>
                    </div>
                </template>
            </div>

            <div class="f-col space-y-0.25">
                <div v-if="task.created_at"
                     class="f space-x-0.25">
                    <our-label value="Создана:"
                               :dark="dark" />
                    <our-label :value="dateDistanceToNow(task.created_at)"
                               :dark="dark"
                               tertiary />
                </div>
                <div v-if="task.expired_at && !isAcceptanceType"
                     class="f space-x-0.25">
                    <our-label value="Истекает:"
                               :dark="dark" />
                    <our-label :value="task.expired_at | dateFormat(timeZone,'DD.MM.YYYY')"
                               :dark="dark"
                               tertiary />
                </div>
            </div>
        </div>

        <!-- Details -->
        <div v-if="withDetails"
             class="f-col f-center space-y-0.25">
            <div v-if="comment"
                 class="fs-0.8 break-normal">
                {{ comment }}
            </div>
            <div v-for="(position, index) in positions"
                 :key="index"
                 class="_m-0 fs-0.9 lh-0.9 font-light text-gray-400">
                {{ position }}
            </div>
        </div>

        <!-- Members -->
        <div v-if="withMembers"
             class="f-h-end space-x-0.5">
            <!-- Initiator -->
            <div v-if="initiator"
                 class="w-14">
                <user-thumb :user="initiator"
                            :topfix="labels.initiator"
                            with-avatar
                            with-organization />
            </div>

            <!-- Engineer or general engineer -->
            <div v-if="contractor || engineer || generalEngineer"
                 class="space-y-0.5">
                <!-- Contractor -->
                <div v-if="contractor"
                     class="w-14">
                    <user-thumb :user="contractor"
                                :topfix="labels.contractor"
                                with-avatar
                                with-organization />
                </div>

                <!-- Engineer -->
                <div v-if="engineer"
                     class="w-14">
                    <user-thumb :user="engineer"
                                :topfix="labels.engineer"
                                with-avatar
                                with-organization />
                </div>

                <!-- General engineer -->
                <div v-if="generalEngineer"
                     class="w-14">
                    <user-thumb :user="generalEngineer"
                                :topfix="labels.generalEngineer"
                                with-avatar
                                with-organization />
                </div>
            </div>

            <!-- Inspector -->
            <div v-if="inspector"
                 class="w-14">
                <user-thumb :user="inspector"
                            :topfix="labels.inspector"
                            with-avatar
                            with-organization />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { formatLabelWithDistance } from '@/utils/date'
import { TASK_TYPES, DEFECT_KINDS } from '@/utils/tasks'
import { VIEW_MODES } from '@/utils/plan'

import { 
  types, 
  getProjectId, getPointId, getPlanId, getPhotoId, getPointPath, 
  getContractorUser, getEngineerUser, getGeneralEngineerUser,
  hasBookedSchedule, getBookedScheduleLabel 
} from '@/models/tasks'

import Tag from '@/components/elements/Tag'
import UserThumb from '@/components/users/UserThumb'
import TaskStatus from '@/components/tasks/TaskStatus' 

const labels = {
  engineer: 'Инженер СК Г/П',
  generalEngineer: 'Инженер СКЗ',
  contractor: 'Подрядчик',
  initiator: 'Инициатор',
  floor: 'Этаж',
  inspector: 'Контроль'
}

export default {
  components: {
    Tag,
    UserThumb,
    TaskStatus
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    relations: {
      type: Array,
      default: () => []
    },
    expandable: {
      type: Boolean,
      default: false
    },
    withDetails: {
      type: Boolean,
      default: false
    },
    withMembers: {
      type: Boolean,
      default: false
    },

    // Style
    dark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    styles() {
      return {
        root: [
          'display: grid',
          'gap: 1rem',
          `grid-template-columns: ${[
            this.isDefectsType && 'min-content',
            'minmax(0, 1fr)',
            this.withDetails && '1fr',
            this.withMembers && '1fr'
          ].filter(x => x).join(' ')}`
        ].join(';')
      }
    },

    projectId() { return getProjectId(this.task) },
    pointId() { return getPointId(this.task) },
    photoId() { return getPhotoId(this.task) },
    planId() { return getPlanId(this.task) },

    isDefectsType() { return this.task['type'] === types.DEFECTS_AND_VIOLATIONS },
    isAcceptanceType() { return this.task['type'] === types.ACCEPTANCE_OF_WORK },
    isBuildingOrder() { return this.task['type'] === types.BUILDING_ORDER },

    title() {
      return [
        this.task.name,
        hasBookedSchedule(this.task) && getBookedScheduleLabel(this.task, { prefix: 'в' })
      ].filter(x => x).join(' ')
    },

    status() {
      const { translated_result } = this.task

      let { value, mood } = translated_result

      mood === 'sad' && (mood = 'info')

      return { value, mood }
    },

    defect() {
      const to = (kindName, tagType) => ({ kindName, tagType })

      return {
        [DEFECT_KINDS.SIGNIFICANT]: to('Значительный', 'warning'),
        [DEFECT_KINDS.CRITICAL]: to('Критический', 'danger'),
        [DEFECT_KINDS.REMOVABLE]: to('Устранимый')
      }[this.task['data']['kind_defect']]
    },

    image() {
      return this.task['data']['image_defect_url']
        || this.task['data']['image_defining_point_thumb_url']
        || this.task['data']['acceptance_work_defect_image'] && this.task['data']['acceptance_work_defect_image']['storage_thumb_url']
    },

    path() { return getPointPath(this.task) },

    initiator() {
      return this.task.initiator.user
    },

    inspector() {
      return this.task.data.general_contractor
    },

    contractor() {
      return {
        [this.isDefectsType || this.isBuildingOrder]: getContractorUser(this.task),
        [this.isAcceptanceType]: this.task.data.contractor 
      }[true]
    },

    engineer() {
      return getEngineerUser(this.task)
    },

    generalEngineer() {
      return getGeneralEngineerUser(this.task)
    },

    dateDistanceToNow: () => date => {
      return formatLabelWithDistance(date)
    },

    hasRelations() {
      return this.relations.some(({ data }) => this.task[data]?.length)
    },

    comment() {
      return this.task.data.comment_initiator
    },

    positions() {
      return (this.task.data.objects || []).map(({ project_object_structure: { house, floor } }) => [
        [
          house?.street,
          house?.number
        ].filter(x => x).join(' '),
        floor?.number && `${labels.floor} ${floor.number}`
      ].filter(x => x).join(' ‣ ')) 
    }
  },
  methods: {
    ...mapActions('viewer/plan', ['addViewMode']),

    lastRelatedTask: data => {
      return data[data.length - 1]
    },
    goToRelatedTask (task, keys) {
      if(this.lastRelatedTask(task[keys.data])[keys.task].is_deleted) return
      this.$router.push({
        name:'project.task',
        params: {
          taskId: this.lastRelatedTask(task[keys.data])[keys.taskId]
        }
      })
    },
    goToTask() {
      if (this.task.type === TASK_TYPES.PROTOCOL) {
        this.addViewMode(VIEW_MODES.PROTOCOL)
        this.$router.push({
          name: 'project.plan',
          params: { planId: this.task.data.floor_plan_id },
          query: { protocolId: this.task.id }
        })
      } else {
        this.$router.push({
          name: 'project.task',
          params: { taskId: this.task.id }
        });
      }
    }
  }
}
</script>
