<template>
    <!-- Toolbar -->
    <div class="f f-v-center space-x-0.5">
        <div ref="filterWrapper"
             class="f f-v-center space-x-0.5">
            <!-- By active -->
            <el-radio-group :value="activity"
                            size="mini"
                            @input="applyActivity">
                <el-radio-button label="null">
                    {{ labels.all }}
                </el-radio-button>
                <el-radio-button label="true">
                    {{ labels.active }}
                </el-radio-button>
                <el-radio-button label="false">
                    {{ labels.inactive }}
                </el-radio-button>
            </el-radio-group>

            <!-- Filter -->
            <task-filter ref="filter"
                         :value="filter"
                         :type="type"
                         deprecated
                         @change="patchFilter"
                         @confirm="confirmFilter"
                         @clear="clearFilter" />
        </div>

        <!-- Export tasks -->
        <access v-if="isDefectsType"
                permissions="project_edit"
                hidable>
            <export-context :filter="filterForServer"
                            :type="type" />
        </access>

        <!-- Create defect -->
        <access v-if="isDefectsType"
                permissions="project_tasks_defects_create">
            <el-button ref="createDefect"
                       type="primary"
                       size="mini"
                       @click="create('defects_and_violations')">
                Создать дефект
            </el-button>
        </access>

        <!-- Create building order -->
        <access v-if="isBuildingOrderType"
                :allowed="isCreatableBuildingOrder"
                hidable>
            <el-button ref="createBuildingOrder"
                       type="primary"
                       size="mini"
                       @click="create('building_order')">
                Создать предписание
            </el-button>
        </access>

        <!-- Create photo order -->
        <access v-if="isPhotoOrderType"
                permissions="project_tasks_photo_orders_create">
            <el-button ref="createPhotoOrder"
                       type="primary"
                       size="mini"
                       @click="create('photo_order')">
                Создать заказ снимков
            </el-button>
        </access>

        <!-- Create acceptance -->
        <div v-if="isAcceptanceType"
             ref="createAcceptance">
            <!-- Create acceptance by contractor -->
            <access :permissions="['project_tasks_acceptance_works_create', 'project_tasks_be_becomes_constructor']"
                    hidable>
                <el-button type="primary"
                           size="mini"
                           @click="createAcceptanceByContractor">
                    Вызвать стройконтроль
                </el-button>
            </access>

            <!-- Create acceptance by engineer -->
            <access :allowed="notGeneralEngineer"
                    :permissions="['project_tasks_acceptance_works_create', 'project_tasks_be_construction_control_engineer']"
                    hidable>
                <el-button type="primary"
                           size="mini"
                           @click="createAcceptanceByEngineer">
                    Создать приемку работ
                </el-button>
            </access>

            <!-- Create acceptance by general engineer -->
            <access :permissions="['project_tasks_acceptance_works_create', 'project_tasks_be_general_control_engineer']"
                    hidable>
                <el-button type="primary"
                           size="mini"
                           @click="createAcceptanceByGeneralEngineer">
                    Создать приемку работ
                </el-button>
            </access>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { types } from '@/models/tasks'

import { resourceable } from '@/store/connectors'

import TaskFilter from '@/components/tasks/TaskFilter'
import ExportContext from '@/components/services/ExportContext'

export const labels = {
  all: 'Все',
  active: 'Активные',
  inactive: 'Неактивные'
}

export default {
  components: {
    TaskFilter,
    ExportContext
  },
  mixins: [
    resourceable({ on: 'account', name: 'creatableTasks', mounted: true })
  ],
  props: {
    type: { type: String, default: null },
    filter: { type: Object, default: () => ({}) },
    filterForServer: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    ...mapGetters('auth', ['licence', 'profileId']),
    ...mapGetters('account', ['hasAccess']),

    isDefectsType() { return this.type === types.DEFECTS_AND_VIOLATIONS },
    isBuildingOrderType() { return this.type === types.BUILDING_ORDER },
    isPhotoOrderType() { return this.type === types.PHOTO_ORDER },
    isAcceptanceType() { return this.type === types.ACCEPTANCE_OF_WORK },
    isProtocol() { return this.type === types.PROTOCOL },

    isCreatableBuildingOrder() {
      return !!this.creatableTasks?.building_order?.creatable
    },

    activity() {
      return String(this.filter.in_processing || null)
    },

    notGeneralEngineer() {
      return !this.hasAccess({ permissions: ['project_tasks_be_general_control_engineer'] })
    }
  },
  methods: {
    ...mapActions('tasks', [
      'storeTask'
    ]),

    ...mapMutations({ showForm: 'form/SHOW_FORM' }),

    create(type) {
      this.$router.push({
        name: 'project.tasks.create',
        params: { type }
      });
    },

    createAcceptanceByContractor() {
      this.showForm({
        formName: 'task-acceptance-of-work-form',
        formTitle: 'Вызвать стройконтроль',
        action: this.storeTask,
        payload: {
          contractor_id: this.profileId
        }
      })
    },

    createAcceptanceByEngineer() {
      this.showForm({
        formName: 'task-acceptance-of-work-form',
        formTitle: 'Создать приемку работ',
        action: this.storeTask,
        payload: {
          worker_id: this.profileId
        }
      })
    },

    createAcceptanceByGeneralEngineer() {
      this.showForm({
        formName: 'task-acceptance-of-work-form',
        formTitle: 'Создать приемку работ',
        action: this.storeTask
      })
    },

    applyActivity(in_processing) {
      const x = { in_processing: in_processing === 'null' ? null : in_processing }

      this.$emit('filter-confirm', [{ ...this.filter, ...x }, { ...this.filterForServer, ...x }])
    },

    confirmFilter([filter, filterForServer]) {
      this.$emit('filter-confirm', [filter, filterForServer])
    },

    clearFilter() {
      this.$emit('filter-clear')
    },

    patchFilter(x) {
      this.$emit('filter-patch', x)
    }
  }
}
</script>
