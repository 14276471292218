<template>
    <div v-if="no">
        <slot />
    </div>

    <el-form-item v-else
                  :prop="name"
                  class="w-full"
                  :required="required"
                  :error="message">
        <!-- Label -->
        <template #label>
            <slot name="label">
                {{ label }}
            </slot>
        </template>

        <slot />
    </el-form-item>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    name: { type: String, default: null },
    required: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    no: { type: Boolean, default: false }
  },
  computed: {
    message() {
      return this.errors.join('. ')
    }
  }
}
</script>
