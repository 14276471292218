<template>
    <div v-if="!isTransformation"
         class="abs l-4 t-1 f space-x-0.5 h-2">
        <template v-if="!isProtocolMode && !isPremisesMode && !isAcceptanceMode">
            <!-- Hz -->
            <el-button-group v-if="(planTypeCommon || planTypeTech) && selectedFeature && selectedFeature.getProperties().id && !comparable">
                <access :permissions="permissionList['project.structure.management']">
                    <el-tooltip content="Список планируемых объектов"
                                placement="bottom">
                        <el-button icon="el-icon-s-operation"
                                   size="medium"
                                   type="info"
                                   @click="handleOptionsMarker" />
                    </el-tooltip>
                </access>
            </el-button-group>

            <el-button-group v-if="(planTypeCommon || planTypeTech || planTypeWork) && !comparable">
                <el-tooltip content="Установить калибровку для линейки"
                            placement="bottom">
                    <el-button :class="{ 'is-active': isCalibration }"
                               icon="el-icon-view"
                               size="medium"
                               type="info"
                               @click="onCalibration" />
                </el-tooltip>
                <el-tooltip content="Рассчитать расстояние"
                            placement="bottom">
                    <el-button :class="{ 'is-active': isLength }"
                               icon="el-icon-aim"
                               size="medium"
                               type="info"
                               @click="onLength" />
                </el-tooltip>
                <el-tooltip content="Рассчитать площадь"
                            placement="bottom">
                    <el-button :class="{ 'is-active': isArea }"
                               icon="el-icon-crop"
                               size="medium"
                               type="info"
                               @click="onArea" />
                </el-tooltip>
                <el-tooltip v-if="planTypeCommon || planTypeTech"
                            content="Указать север"
                            placement="bottom">
                    <el-button :class="{ 'is-active': isOrientation }"
                               size="medium"
                               type="info"
                               @click="onOrientation">
                        <compass-icon width="14"
                                      height="14"
                                      color="#ffffff" />
                    </el-button>
                </el-tooltip>
                <el-tooltip content="Удалить размеченные области"
                            placement="bottom">
                    <el-button size="medium"
                               type="info"
                               @click="onClear">
                        <rubber-icon :width="14"
                                     :height="14" />
                    </el-button>
                </el-tooltip>
            </el-button-group>

            <!-- Comments -->
            <el-button-group v-if="(planTypeCommon || planTypeTech) && !comparable">
                <access :permissions="permissionList['comments.management']">
                    <el-tooltip content="Оставить комментарий"
                                placement="bottom">
                        <el-button type="info"
                                   icon="el-icon-chat-round"
                                   @click="onComment" />
                    </el-tooltip>
                </access>
                <access v-if="hasComments"
                        :permissions="permissionList['comments.access']">
                    <el-tooltip content="Показать комментарии"
                                placement="bottom">
                        <el-button icon="el-icon-notebook-2"
                                   size="medium"
                                   type="info"
                                   :class="{'is-active': isComments }"
                                   @click="onComments" />
                    </el-tooltip>
                </access>
            </el-button-group>

            <!-- Points -->
            <div v-if="(planTypeCommon || planTypeTech) && isPointMode && !comparable"
                 class="f space-x-0.25">
                <!-- Point | Camera 360 -->
                <plan-button icon="camera"
                             tooltip="Добавить реперную точку (Камера 360)"
                             :active="isCamera"
                             permissions="project_structure_edit"
                             @click="onCamera" />

                <!-- Point | Quadcopter -->
                <plan-button icon="qopter"
                             tooltip="Добавить реперную точку (Квадрокоптер)"
                             :active="isQopter"
                             permissions="project_structure_edit"
                             @click="onQopter" />

                <!-- Point | Facade -->
                <plan-button icon="facade"
                             tooltip="Добавить реперную точку (Фасад)"
                             :active="isFacade"
                             settings="ml_processing_building_facade_enable"
                             permissions="project_structure_edit"
                             @click="onFacade" />

                <!-- Point | Defect -->
                <plan-button icon="warning"
                             tooltip="Добавить реперную точку (Дефект)"
                             :active="isDefect"
                             permissions="project_structure_edit"
                             @click="onDefect" />
            </div>

            <!-- Rooms -->
            <el-button-group v-if="hasTechnicalPlan && isRoomMode && !comparable">
                <access :permissions="permissionList['project.structure.management']">
                    <el-tooltip content="Добавить техническое помещение"
                                placement="bottom">
                        <el-button :class="{ 'is-active': isRoom }"
                                   icon="el-icon-edit-outline"
                                   size="medium"
                                   type="info"
                                   @click="onRoom" />
                    </el-tooltip>
                </access>
            </el-button-group>

            <el-button-group>
                <!-- Save -->
                <access v-if="shouldSave"
                        :permissions="permissions.save">
                    <is-button dark
                               label="Сохранить изменения"
                               :active="true"
                               @click="onSave" />
                </access>

                <el-tooltip content="Перейти к фотографии"
                            placement="bottom">
                    <el-button v-if="hasSelectedPhoto"
                               icon="el-icon-picture"
                               size="medium"
                               type="info"
                               @click="onPhoto" />
                </el-tooltip>
            </el-button-group>

            <access v-if="planTypeCommon && !isRoom && !hasTechnicalPlan && !comparable"
                    :permissions="permissionList['project.structure.management']">
                <el-button-group>
                    <el-tooltip content="Протоколы"
                                placement="bottom">
                        <el-button size="medium"
                                   type="info"
                                   @click="showProtocols">
                            <defect-panel-icon :width="12"
                                               :height="12" />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </access>

            <!--        <access v-if="!isRoom && !hasTechnicalPlan"-->
            <!--                :permissions="permissionList['project.structure.management']">-->
            <!--            <el-button-group>-->
            <!--                <el-tooltip content="Помещения"-->
            <!--                            placement="bottom">-->
            <!--                    <el-button size="medium"-->
            <!--                               type="info"-->
            <!--                               @click="onShowPremises">-->
            <!--                        <icon name="room"-->
            <!--                              class="wh-1 stroke-white" />-->
            <!--                    </el-button>-->
            <!--                </el-tooltip>-->
            <!--            </el-button-group>-->
            <!--        </access>-->

            <access v-if="false && !isRoom && !hasTechnicalPlan"
                    :permissions="permissionList['project.structure.management']">
                <el-button-group>
                    <el-tooltip content="Приемка работ"
                                placement="bottom">
                        <el-button size="medium"
                                   type="info"
                                   :class="{ 'is-active': isAcceptanceMode }"
                                   @click="onToggleAcceptance">
                            <icon name="home-filled"
                                  class="wh-1" />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </access>
        </template>

        <div v-if="isProtocolMode"
             class="f space-x-0.5">
            <el-tooltip content="Назад"
                        placement="bottom">
                <el-button icon="el-icon-back"
                           size="medium"
                           type="info"
                           @click="hideProtocols" />
            </el-tooltip>
            <el-tooltip content="Классы дефектов"
                        placement="bottom">
                <el-button size="medium"
                           :class="{ 'is-active': defectClasses, 'm-0': true }"
                           type="info"
                           @click="showDefectClasses">
                    <defect-class-icon :height="12"
                                       :width="12" />
                </el-button>
            </el-tooltip>
            <el-tooltip content="Показать реперные точки"
                        placement="bottom">
                <el-button icon="el-icon-location"
                           size="medium"
                           :class="{ 'is-active': isPointMode, 'm-0': true }"
                           type="info"
                           @click="onShowPoints" />
            </el-tooltip>
            <el-tooltip placement="bottom">
                <el-row slot="content">
                    <el-col class="text-size--supplementary">
                        Разметка дефекта
                    </el-col>
                    <el-col class="text-size--supplementary">
                        Разметьте область дефекта на плане с помощью ЛКМ
                    </el-col>
                </el-row>
                <el-button icon="el-icon-edit"
                           size="medium"
                           :class="{ 'is-active': isProtocol, 'm-0': true }"
                           type="info"
                           @click="drawProtocol" />
            </el-tooltip>
            <el-tooltip content="Удалить последнюю точку полигона (Esc)"
                        placement="bottom">
                <el-button size="medium"
                           type="info"
                           class="m-0"
                           @click="clearVector">
                    <rubber-icon :width="12"
                                 :height="12" />
                </el-button>
            </el-tooltip>
            <el-button type="info"
                       class="m-0"
                       @click="cancelAll">
                Отмена
            </el-button>
        </div>

        <div v-if="isPremisesMode"
             class="f space-x-0.5">
            <el-tooltip content="Назад"
                        placement="bottom">
                <el-button icon="el-icon-back"
                           size="medium"
                           type="info"
                           @click="onHidePremises" />
            </el-tooltip>

            <el-button-group>
                <el-tooltip content="Классы помещений"
                            placement="bottom">
                    <el-button icon="el-icon-notebook-1"
                               size="medium"
                               :class="{ 'is-active': premisesClassesVisibility }"
                               type="info"
                               @click="onSetPremisesClassesVisibility" />
                </el-tooltip>
                <el-tooltip content="Показать реперные точки"
                            placement="bottom">
                    <el-button icon="el-icon-map-location"
                               size="medium"
                               :class="{ 'is-active': isPointMode, 'm-0': true }"
                               type="info"
                               @click="onShowPoints" />
                </el-tooltip>
                <el-tooltip content="Разметка помещения"
                            placement="bottom">
                    <el-button size="medium"
                               type="info"
                               :class="{ 'is-active': isPremises, 'm-0': true }"
                               @click="onDrawPremises">
                        <icon name="circle-dashed"
                              class="_mt-0.15 wh-1 stroke-white" />
                    </el-button>
                </el-tooltip>
            </el-button-group>

            <el-button type="info"
                       class="m-0"
                       @click="cancelAll">
                Отмена
            </el-button>
        </div>

        <div v-if="isAcceptanceMode">
            <template v-if="editable">
                <el-button size="medium"
                           :class="{ 'is-active': isFinalAcceptance }"
                           @click="onDrawFinalAcceptance">
                    Окончательная приемка
                </el-button>
                <el-button size="medium"
                           :class="{ 'is-active': isIntermediateAcceptance }"
                           @click="onDrawIntermediateAcceptance">
                    Промежуточная приемка
                </el-button>
                <el-button icon="el-icon-check"
                           size="medium"
                           @click="confirmAcceptance">
                    Сохранить
                </el-button>
            </template>
        </div>

        <!-- Work plan -->
        <div v-if="planTypeWork && editable"
             class="f space-x-0.5">
            <!-- Draw work polygon -->
            <plan-button tooltip="Добавить разметку"
                         permissions="project_floor_work_plan_be_administrator"
                         icon="rect-outlined"
                         :active="isWorkPolygon"
                         @click="$emit('work-polygon-draw')" />

            <!-- Compare with common plan -->
            <el-dropdown v-if="floorPlans.length"
                         trigger="click"
                         @command="$emit('work-plan-compare', $event)">
                <!-- Trigger -->
                <plan-button label="Фото 360" 
                             icon="compare" />

                <!-- List -->
                <el-dropdown-menu slot="dropdown"
                                  class="max-h-1/4 overflow-auto with-custom-scrollbar">
                    <el-dropdown-item v-for="plan in floorPlans"
                                      :key="plan.id"
                                      :command="plan">
                        {{ getPlanLabel(plan) }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { planTypes, getPlanLabel } from '@/models/plans'

import RubberIcon from '@/components/icons/RubberIcon'
import CompassIcon from '@/components/icons/CompassIcon'
import DefectPanelIcon from '@/components/icons/DefectPanelIcon'
import DefectClassIcon from '@/components/icons/DefectClassIcon'

import PlanButton from '@/components/map/PlanButton'

export default {
  components: {
    RubberIcon,
    CompassIcon,
    DefectPanelIcon,
    DefectClassIcon,
    PlanButton
  },

  props: {
    planType: { type: String, default: 'common' },
    floorPlans: { type: Array, default: () => ([]) },

    editable: Boolean,
    defectClasses: Boolean,

    addedPoints: { type: Array, default: () => [] },
    updatedPoints: { type: Array, default: () => [] },
    deletedPoints: { type: Array, default: () => [] },

    unsaved: { type: Boolean, default: false },
    comparable: { type: Boolean, default: false }
  },

  data() {
    return {
      getPlanLabel
    }
  },

  computed: {
    ...mapGetters('viewer/plan', [
      'selectedFeature',

      'isPointMode',
      'isRoomMode',
      'isProtocolMode',
      'isPremisesMode',
      'isAcceptanceMode',

      'isRoom',
      'isCalibration',
      'isLength',
      'isArea',
      'isOrientation',

      'isCamera',
      'isQopter',
      'isFacade',
      'isDefect',

      'isProtocol',
      'isPremises',
      'isIntermediateAcceptance',
      'isFinalAcceptance',

      'isWorkPolygon',

      'isComments',
      'isTransformation',

      'hasSingleSelectedPoint',
      'hasSelectedPhoto',
      'hasSelectedDefect',

      'premisesClassesVisibility'
    ]),
    ...mapGetters('floorPlans', [
      'hasComments'
    ]),
    ...mapGetters('project', [
      'hasTechnicalPlan'
    ]),

    permissions() {
      return {
        save: {
          [this.planTypeCommon]: ['project_structure_edit'],
          [this.planTypeTech]: ['project_structure_edit'],
          [this.planTypeWork]: ['project_floor_work_plan_be_administrator']
        }[true]
      }
    },

    shouldSave() {
      return this.addedPoints.length 
        || this.updatedPoints.length 
        || this.deletedPoints.length

        || this.unsaved
    },

    planTypeCommon() {
      return this.planType === planTypes.Common
    },

    planTypeTech() {
      return this.planType === planTypes.Tech
    },

    planTypeWork() {
      return this.planType === planTypes.Work
    }
  },

  methods: {
    handleOptionsMarker() {
      this.$emit('handleOptionsMarker')
    },

    onCalibration() {
      this.$emit('calibration')
    },

    onLength() {
      this.$emit('length')
    },

    onArea() {
      this.$emit('area')
    },

    onOrientation() {
      this.$emit('orientation')
    },


    onClear() {
      this.$emit('clear')
    },


    onComment() {
      this.$emit('comment')
    },

    onComments() {
      this.$emit('comments')
    },


    onCamera() {
      this.$emit('camera')
    },

    onQopter() {
      this.$emit('qopter')
    },

    onFacade() {
      this.$emit('facade')
    },

    onDefect() {
      this.$emit('defect')
    },

    onDelete() {
      this.$emit('delete')
    },


    onRoom() {
      this.$emit('room')
    },


    onSave() {
      this.$emit('save')
    },

    onPhoto() {
      this.$emit('photo')
    },


    showProtocols() {
      this.$emit('showProtocols')
    },

    hideProtocols() {
      this.$emit('hideProtocols')
    },

    drawProtocol() {
      this.$emit('drawProtocol')
    },

    showDefectClasses() {
      this.$emit('showDefectClasses')
    },

    onShowPoints() {
      this.$emit('showPoints')
    },

    clearVector() {
      this.$emit('clearVector')
    },

    cancelAll() {
      this.$emit('cancelAll')
    },

    onShowPremises() {
      this.$emit('show-premises')
    },

    onHidePremises() {
      this.$emit('hide-premises')
    },

    onSetPremisesClassesVisibility() {
      this.$emit('set-premises-classes-visibility')
    },

    onDrawPremises() {
      this.$emit('draw-premises')
    },

    onDrawFinalAcceptance() {
      this.$emit('draw-final-acceptance')
    },

    onDrawIntermediateAcceptance() {
      this.$emit('draw-intermediate-acceptance')
    },

    onToggleAcceptance() {
      this.$emit('toggle-acceptance')
    },

    onGoToAcceptance() {
      const { query: { acceptance } } = this.$route
      acceptance && this.$router.push({ name: 'project.task', params: { taskId: acceptance }})
    },

    confirmAcceptance() {
      this.$emit('confirm-acceptance')
    }
  }
}
</script>
